import styled from "styled-components/macro";
import {
  ButtonWrapper,
  CloseButton,
  ErrorMessage,
  FooterWrapperNoLogDetails,
  InfoText,
  Input,
  Label,
  Li,
  NoEditInputField,
  P,
  SaveButton,
} from "../Form.styles";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import {
  CustomerSearchResult,
  Language,
  NewProduct,
  ProductTypes,
} from "../../../types";
import { useState } from "react";
import CustomerSearch from "../../search/customerSearch/customerSearch";
import { api } from "../../../api/ApiSlice";
import TextFieldToSearch from "../../search/textFieldToSearch/textFieldToSearch";

type Props = {
  productTypes: ProductTypes[];
  languages: Language[];
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};
enum ProductProperties {
  NAME = "name",
  DESCRIPTION = "description",
  ISACTIVE = "isActive",
  LANGUAGE_ID = "languageId",
  PRODUCT_CATEGORY_ID = "productCategoryId",
  PRODUCT_TYPE_ID = "productTypeId",
  CUSTOMER_ID = "customerId",
}
const AddProductForm = ({
  productTypes,
  languages,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: Props) => {
  const [trigger, searchResults] = api.useLazySearchCustomerQuery();
  const [addProduct, isError] = api.useAddProductMutation();

  const [errors, setErrors] = useState({}) as any;

  const [newProduct, setNewProduct] = useState<NewProduct>({
    [ProductProperties.NAME]: "",
    [ProductProperties.DESCRIPTION]: "",
    [ProductProperties.ISACTIVE]: true,
    [ProductProperties.LANGUAGE_ID]: null,
    [ProductProperties.PRODUCT_CATEGORY_ID]: 0,
    [ProductProperties.PRODUCT_TYPE_ID]: "",
    [ProductProperties.CUSTOMER_ID]: null,
  });

  const [productTypeSelected, setProductTypeSelected] = useState<string>("");
  console.log("produktType", productTypeSelected);

  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerSearchResult>();

  const [searchCustomerClicked, setSearchCustomerClicked] =
    useState<boolean>(false);

  const handleSelectedProductType = (id: string, name?: string) => {
    if (name) setProductTypeSelected(name);

    const item = productTypes.find((i) => i.id === id);

    if (item) {
      setNewProduct({
        ...newProduct,
        productTypeId: id,
        productCategoryId: item.productCategoryId,
      });
    } else {
      setNewProduct({ ...newProduct, productTypeId: id });
    }
  };

  const handleSelectedLanguage = (id: string, name?: string) => {
    setNewProduct({ ...newProduct, languageId: id });
  };

  const handleInputChange = (value: string | boolean, type: string) => {
    setNewProduct({ ...newProduct, [type]: value });
    setChanged(true);
  };

  const handleSelectedCustomer = (customer: CustomerSearchResult) => {
    setSelectedCustomer(customer);
    setNewProduct({ ...newProduct, customerId: customer.id });
  };
  const handleClickSearchCustomer = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Clicked edit customer");
    setSearchCustomerClicked(!searchCustomerClicked);
  };

  const validationErrors = {} as any;

  const validateForm = (product: NewProduct) => {
    if (!product.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (!product.productTypeId) {
      validationErrors.productTypeId = "Du må velge en produkt type";
    }
    if (productTypeSelected === "Internmodul") {
      if (!product.customerId) {
        validationErrors.customerId =
          "Du må velge en kunde for å opprette internmodul!";
      }
    }
    setErrors(validationErrors);
  };

  const handleSubmitForm = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(newProduct);

    if (Object.keys(validationErrors).length === 0) {
      addProduct({ newProduct, onSuccess });
    }
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Velg produkt type</Label>
            <DropDownSingleSelect
              options={productTypes}
              handleSelected={handleSelectedProductType}
            ></DropDownSingleSelect>
            {errors.productTypeId && (
              <ErrorMessage>{errors.productTypeId}</ErrorMessage>
            )}
          </Li>

          <Li>
            <Label>Navn</Label>
            <Input
              value={newProduct.name}
              onChange={(e) =>
                handleInputChange(e.target.value, ProductProperties.NAME)
              }
            ></Input>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>

          <Li>
            <Label>Beskrivelse</Label>
            <InfoText
              value={newProduct.description}
              onChange={(e) =>
                handleInputChange(e.target.value, ProductProperties.DESCRIPTION)
              }
            ></InfoText>
          </Li>
        </Ul>
        <Ul>
          <Li>
            <Label>Språk</Label>
            <DropDownSingleSelect
              options={languages}
              handleSelected={handleSelectedLanguage}
              placeholderText="Velg språk"
            ></DropDownSingleSelect>
          </Li>{" "}
          {productTypeSelected === "Internmodul" && (
            <>
              <P>For Internmoduler</P>
              <Li>
                <Label>Kunde/Eier </Label>
                <TextFieldToSearch
                  trigger={trigger}
                  searchResults={searchResults}
                  setSelectedCustomer={handleSelectedCustomer}
                  defaultValueInput="Søk på kunder"
                  handleClickEditCancel={handleClickSearchCustomer}
                  editIsClicked={searchCustomerClicked}
                  value={selectedCustomer?.name ? selectedCustomer.name : ""}
                />
                {errors.customerId && (
                  <ErrorMessage>{errors.customerId}</ErrorMessage>
                )}
              </Li>
            </>
          )}
        </Ul>
      </ListWrapper>
      <FooterWrapperNoLogDetails>
        {" "}
        <ButtonWrapper>
          <CloseButton onClick={handleCloseModal} type="button">
            Lukk
          </CloseButton>
          <SaveButton type="submit">Lagre</SaveButton>
        </ButtonWrapper>
      </FooterWrapperNoLogDetails>
    </Form>
  );
};

export default AddProductForm;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
`;

export const ListWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-direction: row;
  }

  margin-top: 1rem;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 2;
  padding: 0 2rem 0;
  max-width: 500px;
`;
