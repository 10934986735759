import styled from "styled-components/macro";
import Table from "../Table";
import { api } from "../../../api/ApiSlice";
import Loader from "../../misc/Loader";
import { useEffect, useState } from "react";
import { AccountPlan, columnConfig } from "../../../types";
import { ModalTypes, useModal } from "../../../app/modalProvider/ModalProvider";
import FilterSystemSettings from "../../filter/FilterSystemSettings";

const columnConfiguration = [
  { name: "Navn", id: "name" },
  { name: "Beskrivelse", id: "description" },
  { name: "Språk", id: "language" },
  { name: "Sist endret", id: "lastChange" },
  { name: "Sist endret av", id: "lastChangeBy" },
  { name: "Aktiv", id: "isActive" },
] as columnConfig[];

enum SortOptions {
  NAME = "name",
  LAST_CHANGE = "lastChange",
  LAST_CHANGE_BY = "lastChangeBy",
  LANGUAGE = "language",
  TYPE = "type",
  ISACTIVE = "isActive",
}

const AccountPlanSettings = () => {
  /* API */
  const accountPlansData = api.useGetAccountPlanListQuery();

  /* Settings the accountplans in state */
  const [accountPlan, setAccountPlans] = useState<AccountPlan[] | undefined>();

  useEffect(() => {
    if (accountPlansData.isSuccess) {
      setAccountPlans(accountPlansData.data);
    }
  }, [accountPlansData.data, accountPlansData.isSuccess]);

  /* Column Sorting on table  */
  const [columnSorted, setColumnSorted] = useState<string>("");
  const [isAscending, setIsAscending] = useState<boolean>();

  const [isAscendingName, setIsAscendingName] = useState<boolean>();
  const [isAscendingLastChange, setIsAscendingLastChange] = useState<boolean>();
  const [isAscendingLastChangeBy, setIsAscendingLastChangeBy] =
    useState<boolean>();
  const [isAscendingLanguage, setIsAscendingLanguage] = useState<boolean>();
  const [isAscendingType, setIsAscendingType] = useState<boolean>();
  const [isAscendingIsActive, setIsAscendingIsActive] = useState<boolean>();

  /*  Handlers && Filtering and searches  */

  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterSearch = (query: string, reset: boolean) => {
    if (reset) {
      setAccountPlans(accountPlansData.data);
      console.log(accountPlan);
    } else {
      const filteredData = accountPlansData?.data?.filter(
        (ap) =>
          ap.name.toLowerCase().includes(query.toLowerCase()) ||
          ap.languageName?.toLowerCase().includes(query.toLowerCase()) ||
          ap.lastChangeBy?.toLowerCase().includes(query.toLowerCase())
      );

      setAccountPlans(filteredData);
      setSearchQuery(query);
    }
  };

  const handleSortColumn = (columnId: string) => {
    if (!accountPlan) return;

    switch (columnId) {
      case SortOptions.NAME: {
        const data = [...accountPlan] as AccountPlan[];

        const sorted = data.sort((a, b) => {
          return isAscendingName
            ? a.name > b.name
              ? 1
              : -1
            : b.name > a.name
            ? 1
            : -1;
        });
        setAccountPlans(sorted);
        setIsAscendingName(!isAscendingName);
        setIsAscending(!isAscendingName);
        setColumnSorted(SortOptions.NAME);

        break;
      }
      // case SortOptions.LAST_CHANGE: {
      //   const data = [...accountPlan] as AccountPlan[];

      //   const sorted = data.sort((a, b) => {
      //     var date_a = a.lastChange?.split("T")[0];
      //     var date_b = b.lastChange?.split("T")[0];

      //       return isAscendingLastChange
      //         ? Date.parse(date_a) - Date.parse(date_b)
      //         : Date.parse(date_b) - Date.parse(date_a);
      //   });

      //   setAccountPlans(sorted);
      //   setIsAscendingLastChange(!isAscendingLastChange);
      //   setIsAscending(!isAscendingLastChange);
      //   setColumnSorted(SortOptions.LAST_CHANGE);

      //   break;
      // }
      // case SortOptions.LAST_CHANGE_BY: {
      //   const data = [...accountPlan] as AccountPlan[];

      //   const sorted = data.sort((a, b) => {
      //     return isAscendingLastChangeBy
      //       ? a.lastChangeBy > b.lastChangeBy
      //         ? 1
      //         : -1
      //       : b.lastChangeBy > a.lastChangeBy
      //       ? 1
      //       : -1;
      //   });

      //   setAccountPlans(sorted);
      //   setIsAscendingLastChangeBy(!isAscendingLastChangeBy);
      //   setIsAscending(!isAscendingLastChangeBy);
      //   setColumnSorted(SortOptions.LAST_CHANGE_BY);

      //   break;
      // }
      // case SortOptions.LANGUAGE: {
      //   const data = [...accountPlan] as AccountPlan[];

      //   const sorted = data.sort((a, b) => {
      //     return isAscendingLanguage
      //       ? a.sortOrderLanguage - b.sortOrderLanguage
      //       : b.sortOrderLanguage - a.sortOrderLanguage;
      //   });

      //   setAccountPlans(sorted);
      //   setIsAscendingLanguage(!isAscendingLanguage);
      //   setIsAscending(!isAscendingLanguage);
      //   setColumnSorted(SortOptions.LANGUAGE);

      //   break;
      // }
      // case SortOptions.TYPE: {
      //   const data = [...accountPlan] as Product[];

      //   const sorted = data.sort((a, b) => {
      //     return isAscendingType
      //       ? a.productTypeName > b.productTypeName
      //         ? 1
      //         : -1
      //       : b.productTypeName > a.productTypeName
      //       ? 1
      //       : -1;
      //   });

      //   setAccountPlans(sorted);
      //   setIsAscendingType(!isAscendingType);
      //   setIsAscending(!isAscendingType);
      //   setColumnSorted(SortOptions.TYPE);

      //   break;
      // }
      // case SortOptions.ISACTIVE: {
      //   const data = [...accountPlan] as AccountPlan[];

      //   const sorted = data.sort((a, b) => {
      //     return isAscendingType
      //       ? a.isActive > b.isActive
      //         ? 1
      //         : -1
      //       : b.isActive > a.isActive
      //       ? 1
      //       : -1;
      //   });

      //   setAccountPlans(sorted);
      //   setIsAscendingIsActive(!isAscendingIsActive);
      //   setIsAscending(!isAscendingIsActive);
      //   setColumnSorted(SortOptions.ISACTIVE);

      //   break;
      // }
      default:
        console.log("no match");
        break;
    }
  };

  /* Modal methods */

  const { openModal, closeModal } = useModal();

  const handleEditAccountPlan = (id: string) => {
    openModal(ModalTypes.EDIT_ACCOUNTPLAN_MODAL, {
      id: id,
      closeModal,
    });
  };

  const handleAddNewAccountPlan = () => {
    openModal(ModalTypes.ADD_ACCOUNTPLAN_MODAL, {
      closeModal,
    });
  };

  return (
    <AccountPlanWrapper>
      <TableActionWrapper>
        <Button onClick={handleAddNewAccountPlan}>Produktpakke</Button>
      </TableActionWrapper>
      {(accountPlansData.isFetching || accountPlansData.isLoading) && (
        <Loader />
      )}

      {accountPlansData.data && accountPlan && (
        <AccountPlanTableWrapper>
          <FilterWrapper>
            {" "}
            <FilterSystemSettings
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleSearch={handleFilterSearch}
            />
          </FilterWrapper>

          <Table
            columnConfig={columnConfiguration}
            tableContent={accountPlan}
            handleSortColumn={handleSortColumn}
            columnSorted={columnSorted}
            isAscending={isAscending}
            handleEdit={handleEditAccountPlan}
          />
        </AccountPlanTableWrapper>
      )}
    </AccountPlanWrapper>
  );
};

export default AccountPlanSettings;

const AccountPlanWrapper = styled.div``;

const AccountPlanTableWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing[4]};
`;

const TableActionWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacingSystem[3]};
  margin-top: ${(props) => props.theme.spacingSystem[7]};
`;

const Button = styled.button`
  margin-right: ${(props) => props.theme.spacingSystem[5]};

  border: 0;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  box-shadow: ${(props) => props.theme.shadowSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: hsl(0, 0%, 20%);

  padding: ${(props) => props.theme.spacingSystem[5]};
  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  &:before {
    content: url("svg/add-2.svg");
    position: relative;
    top: 2px;
    bottom: 0;
    right: ${(props) => props.theme.spacingSystem[2]};
  }

  &:hover {
    background-color: ${(props) => props.theme.greyColorsSystem[3]};
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props) => props.theme.spacingSystem[5]};
`;
