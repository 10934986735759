import { useContext, useState } from "react";
import styled from "styled-components/macro";
import { Customer } from "../../types";
import { CustomerContext } from "../../app/context/Context";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { splitStringByThreeLetters } from "../misc/HelperFunctions";
import { ModalTypes, useModal } from "../../app/modalProvider/ModalProvider";
import { Link } from "react-router-dom";

enum TOGGLE_TYPES {
  ACTIVE = "active",
  ALL = "all",
}

type Props = {
  toggleAccounts: boolean;
  setToggleAccounts: (toggle: boolean) => void;
};

const IconStyle = {
  fontSize: "large",
  marginRight: "0.5rem",
  marginBottom: "0.2rem",
};

const LinkStyle = {
  color: "#2B5E84",
  textDecoration: "none",
  fontSize: "0.875rem",
  //fontSize: "1rem",
};

const CustomerDetailSection2 = ({
  toggleAccounts,
  setToggleAccounts,
}: Props) => {
  const [type, setType] = useState<string>(TOGGLE_TYPES.ALL);

  const customer = useContext(CustomerContext) as Customer;

  /* Ensures the organizational number is split by three */
  var customerCompanyIdTransformed = splitStringByThreeLetters(
    customer.companyId
  );

  /* Handlers for opening modals */

  const { openModal, closeModal } = useModal();

  const handleClickOnEditCustomer = (id: string) => {
    openModal(ModalTypes.CUSTOMER_EDIT_MODAL, {
      customer: customer,
      closeModal,
    });
  };

  const handleClickOnAddAccount = () => {
    openModal(ModalTypes.ACCOUNT_ADD_MODAL, {
      customer: customer,
      closeModal,
    });
  };

  const handleClickOnAddUser = () => {
    openModal(ModalTypes.USER_ADD_MODAL, { customer: customer, closeModal });
  };

  const handleClickImport = () => {
    openModal(ModalTypes.IMPORT_USERS_MODAL, {
      closeModal,
      customerId: customer.id,
      accounts: customer.accounts,
    });
  };

  return (
    <SectionWrapper>
      <ActionsWrapper>
        <ActionButton onClick={handleClickOnAddAccount}>
          <AddIcon style={IconStyle} />
          Ny Konto
        </ActionButton>
        <ActionButton onClick={handleClickOnAddUser}>
          <PersonAddAlt1Icon style={IconStyle} />
          Ny Bruker
        </ActionButton>
        <ActionButton onClick={() => handleClickOnEditCustomer(customer.id)}>
          <EditIcon style={IconStyle} />
          Rediger
        </ActionButton>
        <ActionButton onClick={handleClickImport}>
          <AddIcon style={IconStyle} />
          Importer
        </ActionButton>
        <ActionButton>
          {" "}
          <Link to="/logs" style={LinkStyle}>
            Endringslogg
          </Link>
        </ActionButton>
      </ActionsWrapper>
      <HeaderWrapper>
        <Title>{customer.name}</Title>
      </HeaderWrapper>
      <DetailsWrapper>
        <Details>
          {customer.isActive ? "Aktiv" : "Inaktiv"}
          <LineSeperation>|</LineSeperation>
        </Details>

        <Details>
          {customerCompanyIdTransformed}
          <LineSeperation>|</LineSeperation>
        </Details>

        {customer.customerId && (
          <Details>
            {customer.customerId} <LineSeperation>|</LineSeperation>
          </Details>
        )}
        <Details>{customer.companyTypeName}</Details>
      </DetailsWrapper>
    </SectionWrapper>
  );
};

export default CustomerDetailSection2;

const SectionWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacingSystem[5]};
`;

const ActionsWrapper = styled.div`
  display: flex;
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};
  padding-top: ${(props) => props.theme.spacingSystem[0]};
`;

const DetailsWrapper = styled.div`
  padding-left: ${(props) => props.theme.spacingSystem[1]};
  margin-top: ${(props) => props.theme.spacingSystem[0]};
`;

const HeaderWrapper = styled.div`
  display: flex;
  padding-left: ${(props) => props.theme.spacingSystem[1]};
  margin-top: ${(props) => props.theme.spacingSystem[2]};
`;

const Title = styled.h4`
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
  color: ${(props) => props.theme.primaryColorsSystem[1]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};
  margin-top: ${(props) => props.theme.spacingSystem[1]};
  margin: 0;
`;

const ActionButton = styled.button`
  padding-left: 0;
  padding-right: 0;

  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[0]};
  margin-right: ${(props) => props.theme.spacingSystem[6]};

  border: none;
  border-bottom: 2px solid transparent;

  background: none;

  color: ${(props) => props.theme.primaryColorsSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  position: relative;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.primaryColorsSystem[2]};
  }
`;

const Details = styled.span`
  padding-right: ${(props) => props.theme.spacingSystem[2]};
  color: ${(props) => props.theme.greyColorsSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
`;

const LineSeperation = styled.span`
  padding-left: ${(props) => props.theme.spacingSystem[2]};
`;
