import React, { useState } from "react";
import styled from "styled-components";

type ToggleProps = {
  onToggle: () => void;
  toggled: boolean;
  text?: string;
  id: string;
  width?: string;
  heigth?: string;
  color?: string;
};

const Toggle = (props: ToggleProps) => {
  const {
    id,
    toggled,
    text,
    onToggle,
    width = "56px",
    heigth = "28px",
    color = "#3778A9",
  } = props;

  return (
    <>
      <Switch color={color} id={id} onToggle={onToggle} toggled={toggled} />
      <StyledLabel
        height={heigth}
        width={width}
        title={text || ""}
        htmlFor={id}
      >
        {/* {text || ""} */}
      </StyledLabel>
    </>
  );
};

export default Toggle;

type SwitchProps = {
  id: string;
  onToggle: () => void;
  toggled: boolean;
  color?: string;
};

const Switch = (props: SwitchProps) => {
  const { id, onToggle, toggled, color = "#3778A9" } = props;
  return (
    <StyledCheckbox
      color={color}
      type="checkbox"
      id={id}
      onChange={onToggle}
      checked={toggled}
    />
  );
};

const StyledCheckbox = styled.input<{ color: string }>`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label {
    //background: #8ac3a3;
    background: ${(props) => props.color};
  }
  &:checked + label::after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
`;

const StyledLabel = styled.label<{ height: string; width: string }>`
  cursor: pointer;
  text-indent: -9999px;
  width: ${(props) => props.width};
  /* width: 45px; */
  height: ${(props) => props.height};
  /* height: 20px; */
  background: grey;
  display: block;
  border-radius: 14px;
  position: relative;
  color: black;

  ::after {
    content: "AA";

    position: absolute;
    top: 3px;
    left: 3px;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 22px;
    transition: 0.3s;
  }

  :active::after {
    width: 30px;
  }
`;
