import styled from "styled-components/macro";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type PagingProps = {
  currentPage: number;
  totalPages: number;
  handleClickOnNextPage: () => void;
  handleClickOnPrevPage: () => void;
};
const PagingFooter = ({
  currentPage,
  totalPages,
  handleClickOnNextPage,
  handleClickOnPrevPage,
}: PagingProps) => {
  return (
    <PagerWrapper>
      <PageNumbers>
        {currentPage >= 2 && (
          <PagerButtons>
            <ArrowBackIosIcon
              style={IconStyle}
              onClick={handleClickOnPrevPage}
            />
          </PagerButtons>
        )}
        {currentPage} av {totalPages}
        {currentPage <= totalPages - 1 && (
          <PagerButtons>
            <ArrowForwardIosIcon
              style={IconStyle}
              onClick={handleClickOnNextPage}
            />
          </PagerButtons>
        )}
      </PageNumbers>
    </PagerWrapper>
  );
};

export default PagingFooter;
export const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PagerButtons = styled.button`
  margin: 0.5rem;
  border: none;
  background: none;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
export const PageNumbers = styled.p`
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  color: ${(props) => props.theme.greyColorsSystem[1]};
  margin-bottom: 0.5rem;
`;
const IconStyle = {
  fontSize: "0.8125rem",
  margin: "0.2rem",
  color: "#808080",
};
