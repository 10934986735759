import styled from "styled-components/macro";
import {
  ButtonWrapper,
  CloseButton,
  ErrorMessage,
  FooterWrapperNoLogDetails,
  InfoText,
  Input,
  Label,
  Li,
  SaveButton,
} from "../Form.styles";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import Checkbox from "../../misc/checkbox/Checkbox";
import { api } from "../../../api/ApiSlice";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";
import { useEffect, useState } from "react";
import { Language, NewAccountPlan, Product } from "../../../types";

const fakeData = [
  { id: "1", name: "Test1" },
  { id: "2", name: "Test2" },
  { id: "3", name: "Test3" },
  { id: "4", name: "Test4" },
];

enum AccountPlanProperties {
  NAME = "name",
  DESCRIPTION = "description",
  ISACTIVE = "isActive",
  LANGUAGE_ID = "languageId",
  PRODUCT_IDS = "productIds",
}

type Props = {
  products: Product[];
  languages: Language[];
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};
const AddAccountPlanForm = ({
  products,
  languages,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: Props) => {
  /* API */
  const [addAccountPlan] = api.useAddAccountPlanMutation();

  /* For storing validation errors */
  const [errors, setErrors] = useState({}) as any;

  /*  Creating variables for selectors - All products divided into categories */
  var FUNCTIONALITY_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Funksjonalitet"
  );

  var SUBJECT_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Fagområde"
  );

  var INTERNAL_MODULE_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Internmodul"
  );

  /* New AccountPlan Object */
  const [newAccountPlan, setNewAccountPlan] = useState<NewAccountPlan>({
    [AccountPlanProperties.NAME]: "",
    [AccountPlanProperties.DESCRIPTION]: "",
    [AccountPlanProperties.ISACTIVE]: true,
    [AccountPlanProperties.LANGUAGE_ID]: null,
    [AccountPlanProperties.PRODUCT_IDS]: [],
  });

  /* handling state for productids per category type */

  const [selectedFuncProducts, setSelectedFuncProducts] = useState<string[]>(
    []
  );

  const [selectedSubjProducts, setSelectedSubjProducts] = useState<string[]>(
    []
  );

  /* Probably a bad way to solve updating productIds, but it works :shrug:
   * Updating the newAccountPlan Object with the productIds on change in any of the category types
   */
  useEffect(() => {
    var productIds = [...selectedFuncProducts, ...selectedSubjProducts];

    setNewAccountPlan({
      ...newAccountPlan,
      [AccountPlanProperties.PRODUCT_IDS]: productIds,
    });
  }, [selectedFuncProducts, selectedSubjProducts]);

  /* Handlers */
  const handleLanguageSelection = (id: string, name?: string) => {
    setNewAccountPlan({ ...newAccountPlan, languageId: id });
    setChanged(true);
  };

  const handleInputChange = (value: string | boolean, type: string) => {
    setNewAccountPlan({ ...newAccountPlan, [type]: value });
    setChanged(true);
  };

  const handleFunctionalityProductSelection = (
    id: string[],
    name?: string[]
  ) => {
    setSelectedFuncProducts(id);
    setChanged(true);
  };

  const handleSubjectProductSelection = (id: string[], name?: string[]) => {
    setSelectedSubjProducts(id);
    setChanged(true);
  };

  /* Validation */

  const validationErrors = {} as any;

  const validateForm = (accountPlan: NewAccountPlan) => {
    if (!accountPlan.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (accountPlan.productIds.length <= 0) {
      validationErrors.productIds = "Du må legge til produkter";
    }

    setErrors(validationErrors);
  };

  /* Submit form handling */
  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateForm(newAccountPlan);

    if (Object.keys(validationErrors).length === 0) {
      addAccountPlan({ newAccountPlan, onSuccess });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Navn</Label>
            <Input
              value={newAccountPlan.name}
              onChange={(e) =>
                handleInputChange(e.target.value, AccountPlanProperties.NAME)
              }
            ></Input>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>
          <Li>
            <Label>Språk</Label>
            <DropDownSingleSelect
              options={languages}
              handleSelected={handleLanguageSelection}
              placeholderText="Velg språk"
            ></DropDownSingleSelect>
          </Li>
        </Ul>
        <Ul>
          <Li>
            <Label>Beskrivelse</Label>
            <InfoText
              value={newAccountPlan.description}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  AccountPlanProperties.DESCRIPTION
                )
              }
            ></InfoText>
          </Li>
        </Ul>
      </ListWrapper>
      <OptionsListWrapper>
        <OptionsListUl>
          <>
            {errors.productIds && (
              <ErrorMessage>{errors.productIds}</ErrorMessage>
            )}
            <OptionsWrapper>
              <Label>Fagområder</Label>
              <DropDownMultiSelect
                options={SUBJECT_PRODUCTS}
                handleSelected={handleSubjectProductSelection}
                placeholder="Velg fagområder"
              ></DropDownMultiSelect>
            </OptionsWrapper>
            <OptionsWrapper>
              <Label>Funksjonalitet</Label>
              <DropDownMultiSelect
                options={FUNCTIONALITY_PRODUCTS}
                handleSelected={handleFunctionalityProductSelection}
                placeholder="Velg funksjonalitet"
              ></DropDownMultiSelect>
            </OptionsWrapper>
          </>
        </OptionsListUl>
      </OptionsListWrapper>

      <FooterWrapperNoLogDetails>
        {" "}
        <ButtonWrapper>
          <CloseButton onClick={handleCloseModal} type="button">
            Lukk
          </CloseButton>
          <SaveButton type="submit">Lagre</SaveButton>
        </ButtonWrapper>
      </FooterWrapperNoLogDetails>
    </Form>
  );
};

export default AddAccountPlanForm;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  flex: 1;
  overflow: auto;
  margin: 0.75rem;
`;

export const ListWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-flow: row wrap;
  }

  margin-top: 1rem;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 2;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 500px;
`;

export const OptionsListWrapper = styled.div`
  /* display: flex;
  flex-flow: column; */

  @media (min-width: 300px) {
    display: flex;
    flex-direction: row;
  }
`;

export const OptionsListUl = styled.ul`
  list-style-type: none;
  list-style-type: none;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  //max-width: 900px;
`;
export const OptionsWrapper = styled.li`
  /* width: 900px; */
  display: flex;
  flex-direction: column;
  min-height: 90px;
  margin-bottom: ${(props) => props.theme.spacingSystem[1]};
`;
