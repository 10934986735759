import {configureStore} from '@reduxjs/toolkit';
import { api } from '../api/ApiSlice';
import { FilterParamsSlice } from './reducers/FilterParameterReducer';
import { UsersSlice } from './reducers/UserReducer';


// ConfigureStore() automatically combine different reducers, no need to call "combinereducers()"
// A wrapper around the createstore() functions

/*  Configurestore automatically adds:  
 *    - The redux dev tools
 *    - Adds thunk middelware: A thunk is a middleware that lets us call a function to do something, and which results in dispatching Redux actions to update the store.
 *    - Development checks that catch common mistakes such as accidental mutations
 */
export const store = configureStore({
    reducer: {
        //customer: customerSlice,
        [api.reducerPath]: api.reducer,
        filterParams: FilterParamsSlice.reducer,
        users: UsersSlice.reducer,
    },
    //  manages cache lifetimes and expiration, as well as adding additional defualt middelware
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware)
})

// Exporting some types based on our store: 
export type RootState = ReturnType<typeof store.getState>;
// Equals the type of store.dispatch().
// Dispatch "triggers the event" and sends actions to the reducer
export type AppDispatch = typeof store.dispatch;
