import { useState } from "react";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import Modal from "react-modal";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from "./Modal.Styles";
import { Account, User } from "../../types";

import ImportUsersForm from "../forms/import/ImportUsersForm";

type Props = {
  closeModal: (type: ModalTypes) => void;
  customerId: string;
  accounts: Account[];
  handleUpdateSelection: (usersChecked: string[]) => void;
};

const ImportUsersModal = ({
  closeModal,
  customerId,
  accounts,
  handleUpdateSelection,
}: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.IMPORT_USERS_MODAL);
    }
  };

  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.IMPORT_USERS_MODAL);
  };

  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.IMPORT_USERS_MODAL);
  };

  return (
    <Modal
      isOpen
      style={modalStyles}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      <ModalContentWrapper>
        <HeaderWrapper>
          <Title>Importer brukere</Title>
          <CloseButton onClick={handleCloseModal}></CloseButton>
        </HeaderWrapper>

        <ImportUsersForm
          accounts={accounts}
          customerId={customerId}
          handleCloseModal={handleCloseModal}
          handleCloseModalOnSuccess={handleCloseModalOnSuccess}
          setChanged={setChanged}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "650px",
    height: "460px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};
export default ImportUsersModal;
