import Modal from "react-modal";
import { useState } from "react";
import { ModalTypes, useModal } from "../../../app/modalProvider/ModalProvider";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from "../Modal.Styles";
import ModalChangesAlertDialog from "../../dialogs/ModalChangesAlert";
import { api } from "../../../api/ApiSlice";
import EditAccountPlanForm from "../../forms/systemSettings/AccountPlanEditForm";

//Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "770px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

interface Props {
  id: string;
}

const AccountPlanEditModal = ({ id }: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  /* API */
  const accountPlanData = api.useGetAccountPlanByIdQuery(id);

  const languagesData = api.useGetLanguagesQuery();

  const allProducts = api.useGetAllProductsQuery();

  /* Modal methods */
  const { closeModal } = useModal();

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.EDIT_ACCOUNTPLAN_MODAL);
    }
  };
  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.EDIT_ACCOUNTPLAN_MODAL);
  };
  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.EDIT_ACCOUNTPLAN_MODAL);
  };
  return (
    <>
      <Modal
        isOpen
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <Title>Rediger kontoplan </Title>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>
          {accountPlanData.isSuccess &&
            languagesData.isSuccess &&
            allProducts.isSuccess && (
              <EditAccountPlanForm
                accountPlan={accountPlanData.data}
                languages={languagesData.data}
                products={allProducts.data}
                setChanged={setChanged}
                handleCloseModal={handleCloseModal}
                handleCloseModalOnSuccess={handleCloseModalOnSuccess}
              />
            )}
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default AccountPlanEditModal;
