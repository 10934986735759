import { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { api } from "../../api/ApiSlice";
import CloseIcon from "@mui/icons-material/Close";
import UserEditForm from "../forms/user/UserEditForm";
import ModalChangesAlertDialog from "../dialogs/ModalChangesAlert";
import Loader from "../misc/Loader";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import { Customer } from "../../types";
import {
  CloseButton,
  HeaderWrapper,
  InactiveSymbol,
  InactiveText,
  ModalContentWrapper,
  Title,
  WrapperTitle,
  modalDefaultStyle,
} from "./Modal.Styles";

type Accounts = {
  name: string;
  id: string;
  isnumLicensesReached: boolean;
};

type UserEditProps = {
  userId: string | undefined;
  closeModal: (type: ModalTypes) => void;
  customer: Customer;
  accounts: Accounts[];
};
const UserEditModal = ({ userId, closeModal, customer }: UserEditProps) => {
  const {
    data: user,
    isSuccess,
    isLoading,
    isError,
  } = api.useGetUserByIdQuery(userId);

  const [changed, setChanged] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  let accounts: any = [];

  customer.accounts.forEach((a) => {
    if (a.numUsers === a.numLicenses && a.useNumLicensesLimit) {
      let acc = {
        name: a.name,
        id: a.id,
        isnumLicensesReached: true,
        isActive: a.isActive,
      };
      accounts.push(acc);
    } else {
      let acc = {
        name: a.name,
        id: a.id,
        isnumLicensesReached: false,
        isActive: a.isActive,
      };
      accounts.push(acc);
    }
  });

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.USER_EDIT_MODAL);
    }
  };
  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.USER_EDIT_MODAL);
  };
  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.USER_EDIT_MODAL);
  };

  return (
    <>
      <Modal
        isOpen
        style={modalDefaultStyle}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <WrapperTitle>
              <Title>Rediger bruker</Title>
              {isSuccess && !user.isActive && (
                <InactiveSymbol>
                  <InactiveText>Inaktiv</InactiveText>
                </InactiveSymbol>
              )}
            </WrapperTitle>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>

          {isError && "Failed to load data"}
          {isLoading && <Loader />}
          {isSuccess && (
            <>
              <UserEditForm
                user={user}
                setChanged={setChanged}
                handleCloseModal={handleCloseModal}
                customer={customer}
                handleCloseModalOnSuccess={handleCloseModalOnSuccess}
                accounts={accounts}
              />
            </>
          )}
        </ModalContentWrapper>
      </Modal>

      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default UserEditModal;
