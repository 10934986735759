import styled from "styled-components/macro";

export const EditUserForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const AddUserForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const EditAccountForm = styled.form`
     display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const AddAccountForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const AddustomerForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const EditCustomerForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacingSystem[3]};
    margin-top: ${(props) => props.theme.spacingSystem[2]};
    flex: 1;
    overflow: auto;
`;

export const ListWrapper = styled.div`
  @media (min-width:700px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 1;
`;

export const UlInfo = styled(Ul)`
  padding-right: ${(props) => props.theme.spacingSystem[6]};
`;

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  min-height: 90px;
  margin-bottom: ${(props) => props.theme.spacingSystem[1]};
`;

export const CheckboxLi = styled.li`
  margin-top:  ${(props) => props.theme.spacingSystem[4]};
  display: flex;
  justify-content: space-between;
`; 


export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  color: ${(props) => props.theme.greyColorsSystem[0]};
  margin-bottom: ${(props) => props.theme.spacingSystem[1]};
`;

export const Input = styled.input`
  min-width: 100px;
  height: 3rem;
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: ${(props) => props.theme.primaryColorsSystem[6]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};

  outline: none;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding:  ${(props) => props.theme.spacingSystem[4]};

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
    //background:  ${(props) => props.theme.greyColorsSystem[3]};
    background: rgba(230, 230,230, 0.5);
  }
`;

export const InfoText = styled.textarea`
  min-width: 100px;
  height: 130px;
  max-height: 200px;

  outline: none;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding: ${(props) => props.theme.spacingSystem[2]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  margin-bottom: ${(props) => props.theme.spacingSystem[4]};

`;

export const NoEditInputField = styled.div`
  display: flex;
  min-width: 100px;
  height: 3rem;

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: ${(props) => props.theme.primaryColorsSystem[6]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};

  //background:  ${(props) => props.theme.greyColorsSystem[3]};
  background: rgba(230, 230,230, 0.5);

  //border: ${(props) => props.theme.borderSizeSystem[0]};
  border: none;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding: ${(props) => props.theme.spacingSystem[2]};




  &:hover {
    cursor: not-allowed;
  }
`;

export const DateInput = styled.input.attrs(props => ({
  type: props.type,
}))`
  width: 15rem;
  height: 3rem;

  outline: none;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding: ${(props) => props.theme.spacingSystem[2]};
`; 

export const PhoneInput = styled.input`
  flex: 1;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    background: #e6e6e6;
  }
`;

export const PhoneFieldWrapper = styled.div`
  display: flex;
  min-width: 100px;
 
  height: 40px;
  margin-bottom: 0.5rem;

  outline: none;
  border: 1px solid #e6e6e6;
  border-radius: 0.5rem;
`;

export const PhoneAreaCodeBox = styled.div`
  min-width: 55px;
  height: 29px;
  background: none;
  border-right: 1px solid #e6e6e6;
  border-radius: 0.2rem;
  padding: 0.3rem;
`;

export const AccountBox = styled.div`
  display: flex;
`;

export const AccountName = styled.div`
  min-width: 100px;
  flex: 1;
  height: 40px;

  border: none;
  border-radius: 0.5rem;
  background: #e6e6e6;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    cursor: not-allowed;
  }
`;

export const P = styled.p`
  padding:  ${(props) => props.theme.spacingSystem[1]};
  margin: 0;
`;

export const ConfirmationStatus = styled.p<{ isConfirmed: boolean }>`
  margin: 0;
  font-size: small;
  color: ${(props) => (props.isConfirmed ? "green" : "red")};
`;

export const Select = styled.select`
  min-width: 100px;
  height: 40px;
  border-radius: 0.5rem;
`;


export const IconStyles = {
  color: "red",
  fontSize: "medium",
  flex: "0",
  
};

export const ErrorMessage = styled.span`
  margin: 0.2rem;
  font-size: small;
  color: red;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  margin: ${(props) => props.theme.spacingSystem[6]};

  @media (min-width: 300px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const FooterWrapperNoLogDetails = styled(FooterWrapper)`
  display: flex;
  justify-content: end;
  margin: ${(props) => props.theme.spacingSystem[5]};
`;

export const LogDetailsWrapper = styled.div`
  display: flex;

`; 

export const LogLabel = styled.label`
  padding: 0;
  font-size: ${(props) => props.theme.fontSizeSystem[1]};

  color: ${(props) => props.theme.greyColorsSystem[0]};
  margin: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[0]};
`; 

export const LogDetails = styled.p`
  padding: 0;
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};
  color: ${(props) => props.theme.greyColorsSystem[0]};
  margin: ${(props) => props.theme.spacingSystem[3]};
  margin-left: ${(props) => props.theme.spacingSystem[1]};

`;


export const SaveButton = styled.button`
  height: 2rem;
  width: 6.5rem;
  border: ${(props) => props.theme.borderSizeSystem[2]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.primaryColorsSystem[4]};
  color: ${(props) => props.theme.greyColorsSystem[4]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  margin: ${(props) => props.theme.spacingSystem[3]};


  &:hover {
    background: ${(props) => props.theme.primaryColorsSystem[5]};
  }

`;

export const CloseButton = styled.button`
  height: 2rem;
  width: 6.5rem;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.greyColorsSystem[4]};
  color: ${(props) => props.theme.primaryColorsSystem[5]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  margin: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[1]};

   &:hover {
    background: ${(props) => props.theme.greyColorsSystem[3]};
  } 
  
  `;

export const DeactivateButton = styled.button<{ isActive: boolean }>`
  height: 2rem;
  width: 6.5rem;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.greyColorsSystem[4]};

  color: ${(props) => props.theme.primaryColorsSystem[5]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  margin: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[1]};

  &:hover {
    background: ${(props) => props.theme.greyColorsSystem[3]};
  } 
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  @media (min-width: 300px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const ButtonWrapperEdit = styled.div`
  margin: 6rem 2rem 1rem;
  display: flex;
  flex: row;
  justify-content: end;
`;

export const RemoveAccountButton = styled.button`
  height: 40px;
  width: 40px;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    border: 0.5px solid #e6e6e6;
  }
`;