import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { Customer } from "../../types";
import { useModal, ModalTypes } from "../../app/modalProvider/ModalProvider";
import AccountAddForm from "../forms/account/AccountAddForm";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from "./Modal.Styles";
import { useState } from "react";
import ModalChangesAlertDialog from "../dialogs/ModalChangesAlert";

//Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "750px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

interface Props {
  customer: Customer;
}

const AccountAddModal = ({ customer }: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const { closeModal } = useModal();

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.ACCOUNT_ADD_MODAL);
    }
  };
  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.ACCOUNT_ADD_MODAL);
  };
  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.ACCOUNT_ADD_MODAL);
  };
  return (
    <>
      <Modal
        isOpen
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <Title>Ny Konto </Title>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>

          <AccountAddForm
            handleCloseModal={handleCloseModal}
            customer={customer}
            handleCloseModalOnSuccess={handleCloseModalOnSuccess}
            setChanged={setChanged}
          />
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default AccountAddModal;
