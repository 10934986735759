import { useEffect, useState } from "react";
import { api } from "../../../api/ApiSlice";
import { Customer, EditCustomer } from "../../../types";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import Loader from "../../misc/Loader";
import Toggle from "../../misc/toggles/Toggle";

import {
  ButtonWrapper,
  CheckboxLi,
  CloseButton,
  EditCustomerForm,
  ErrorMessage,
  InfoText,
  Input,
  Label,
  Li,
  ListWrapper,
  SaveButton,
  UlInfo,
  Ul,
  DeactivateButton,
  FooterWrapper,
  LogDetailsWrapper,
  LogLabel,
  LogDetails,
} from "../Form.styles";

enum CustomerProperties {
  ID = "id",
  NAME = "name",
  COMPANY_ID = "companyId",
  CUSTOMER_ID = "customerId",
  COMPANY_TYPE_NAME = "companyTypeName",
  COMPANY_TYPE_ID = "companyTypeId",
  INFO = "info",
  ISACTIVE = "isActive",
  EMAIL_DOMAINS = "emailDomains",
}
interface CustomerFormProps {
  customer: Customer;
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
}

const CustomerEditForm = ({
  customer,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: CustomerFormProps) => {
  // APIS
  const companyTypesData = api.useGetAllCompanyTypesQuery();

  const [updateCustomer, { isLoading, isSuccess, isError }] =
    api.useEditCustomerMutation();

  // Initial state of customer object
  const initialState = {
    [CustomerProperties.ID]: customer.id,
    [CustomerProperties.NAME]: customer.name,
    [CustomerProperties.COMPANY_ID]: customer.companyId,
    [CustomerProperties.CUSTOMER_ID]: customer.customerId,
    [CustomerProperties.COMPANY_TYPE_NAME]: customer.companyTypeName,
    [CustomerProperties.INFO]: customer.info,
    [CustomerProperties.ISACTIVE]: customer.isActive,
    [CustomerProperties.EMAIL_DOMAINS]: customer.emailDomains,
    enableSSO: customer.enableSSO,
    rowVersion: customer.rowVersion,
    isDeleted: customer.isDeleted,
    lastChange: customer.lastChange,
    lastChangeBy: customer.lastChangeBy,
    companyTypeId: customer.companyTypeId,
  };

  // States
  const [customerEdit, setCustomerEdit] = useState<EditCustomer>(initialState);
  const [activeStatusChanged, setActiveStatusChanged] =
    useState<boolean>(false);
  const [errors, setErrors] = useState({}) as any;

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const validationErrors = {} as any;

  const validateForm = (customer: EditCustomer) => {
    if (!customer.name) {
      validationErrors.name = "Mangler navn!";
    } else if (customer.name.length < 3) {
      validationErrors.name = "Navn er for kort!";
    }

    if (!customer.companyId) {
      validationErrors.companyId = "Mangler organisasjonsnummer";
    }

    if (!customer.companyTypeId) {
      validationErrors.companyTypeId = "Du må velge en kundegruppe";
    }
    setErrors(validationErrors);
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(customerEdit);
    if (Object.keys(validationErrors).length === 0) {
      updateCustomer({ customerEdit, onSuccess });
    }
  };

  const handleInputChange = (value: string | boolean, type: string) => {
    setCustomerEdit({ ...customerEdit, [type]: value });
    setChanged(true);
  };

  const handleToggleSSO = () => {
    if (customerEdit.enableSSO) {
      setCustomerEdit({ ...customerEdit, enableSSO: false });
    } else {
      setCustomerEdit({ ...customerEdit, enableSSO: true });
    }
  };

  const handleChangeSelectCompanyType = (id: string) => {
    setCustomerEdit({
      ...customerEdit,
      companyTypeId: parseInt(id),
    });

    setChanged(true);
  };

  const handleClickChangeActiveStatus = (e: React.MouseEvent) => {
    e.preventDefault();
    if (customerEdit.isActive) {
      setCustomerEdit({
        ...customerEdit,
        [CustomerProperties.ISACTIVE]: false,
      });
    } else {
      setCustomerEdit({
        ...customerEdit,
        [CustomerProperties.ISACTIVE]: true,
      });
    }
    setActiveStatusChanged(true);
  };

  // Updating status change on customer (Active / Inactive )
  useEffect(() => {
    const saveStatusChange = () => {
      updateCustomer({ customerEdit, onSuccess });
    };

    if (activeStatusChanged) {
      saveStatusChange();
    }
  }, [activeStatusChanged, customerEdit, updateCustomer]);

  return (
    <>
      {companyTypesData.isLoading && <Loader />}
      {companyTypesData.isSuccess && (
        <EditCustomerForm onSubmit={handleSubmit}>
          <ListWrapper>
            <Ul>
              <Li>
                <Label>Navn</Label>
                <Input
                  value={customerEdit.name}
                  onChange={(e) =>
                    handleInputChange(e.target.value, CustomerProperties.NAME)
                  }
                ></Input>
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Li>
              <Li>
                <Label>Organisasjonsnummer</Label>
                <Input
                  value={customerEdit.companyId}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.COMPANY_ID
                    )
                  }
                ></Input>
                {errors.companyId && (
                  <ErrorMessage>{errors.companyId}</ErrorMessage>
                )}
              </Li>
              <Li>
                <Label>Kunde Id (Power Office)</Label>
                <Input
                  value={customerEdit.customerId ? customerEdit.customerId : ""}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.CUSTOMER_ID
                    )
                  }
                ></Input>
              </Li>
              <Li>
                <Label>Kommentar</Label>
                <InfoText
                  value={customerEdit.info ? customerEdit.info : ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, CustomerProperties.INFO)
                  }
                ></InfoText>
              </Li>
            </Ul>
            <UlInfo>
              <Li>
                <Label>Kundetype</Label>

                <DropDownSingleSelect
                  options={companyTypesData.data}
                  defaultOptions={customerEdit.companyTypeId}
                  placeholderText={"- Velg en kundegruppe -"}
                  handleSelected={handleChangeSelectCompanyType}
                />
              </Li>

              <CheckboxLi>
                <Label>Aktiver SSO Pålogging?</Label>
                <Toggle
                  id={"1"}
                  onToggle={handleToggleSSO}
                  toggled={customerEdit.enableSSO}
                  text={customerEdit.enableSSO ? "Deaktiver" : "Aktiver"}
                  color={"#3E88C1"}
                ></Toggle>
              </CheckboxLi>
              <Li>
                <Label>E-post domene</Label>
                <InfoText
                  value={
                    customerEdit.emailDomains ? customerEdit.emailDomains : ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.EMAIL_DOMAINS
                    )
                  }
                ></InfoText>
              </Li>
            </UlInfo>
          </ListWrapper>

          <FooterWrapper>
            <LogDetailsWrapper>
              <LogLabel>Sist endret av </LogLabel>
              <LogDetails>{customer.lastChangeBy}</LogDetails>
              <LogLabel>Dato endret</LogLabel>
              <LogDetails>
                {customer.lastChange
                  ? new Date(customer.lastChange).toLocaleString()
                  : ""}
              </LogDetails>
            </LogDetailsWrapper>
            <ButtonWrapper>
              <DeactivateButton
                onClick={handleClickChangeActiveStatus}
                isActive={customer.isActive}
              >
                {" "}
                {customer.isActive ? "Deaktiver" : "Aktiver"}
              </DeactivateButton>
              <SaveButton type="submit">Lagre</SaveButton>
              <CloseButton type="button" onClick={handleCloseModal}>
                Lukk
              </CloseButton>
            </ButtonWrapper>
          </FooterWrapper>
          {isError && "en feil har oppstått"}
        </EditCustomerForm>
      )}
    </>
  );
};

export default CustomerEditForm;
