import styled from "styled-components/macro";

export const ModalContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.h4`
  margin-left: ${(props) => props.theme.spacingSystem[3]};
  color: ${(props) => props.theme.primaryColorsSystem[5]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  justify-content: space-between;
  margin: ${(props) => props.theme.spacingSystem[6]};
  margin-bottom: ${(props) => props.theme.spacingSystem[4]};
`;

export const CloseButton = styled.button`
  background: 0;
  border: 0;

  &:before {
   
    content: url("svg/close-grey.svg")
  }

  &:hover {
    cursor: pointer;

    &:before {
      content: url("svg/close.svg")
    }
  
  }
`;

export const InactiveText = styled.p`
  color: ${(props) => props.theme.greyColorsSystem[4]};
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  padding: ${(props) => props.theme.spacingSystem[1]};
`;

export const WrapperTitle = styled.div`
  display: flex;

`;

export const InactiveSymbol = styled.div`
  display: flex;
  justify-content: center;
  width: 80px;
  height: 2rem;
  border: 2px solid #f4a4a4;
  border-radius: 1rem;
  background: #f4a4a4;
  margin-left: 1rem;
`;


export const modalDefaultStyle = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "650px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

export const statusWrapper = styled.div`
  display: flex;
`; 