import { useState } from "react";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import Modal from "react-modal";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from "./Modal.Styles";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../types";
import TransferUsersForm from "../forms/user/TransferUsersForm";

type Props = {
  users: User[];
  closeModal: (type: ModalTypes) => void;
  currentCustomerId: string;
  currentCustomerName: string;
  currentAccountName: string;
  handleUpdateSelection: (usersChecked: string[]) => void;
};

const TransferUsersModal = ({
  users,
  closeModal,
  currentCustomerId,
  currentCustomerName,
  handleUpdateSelection,
}: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.TRANSFER_USERS_MODAL);
    }
  };

  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.TRANSFER_USERS_MODAL);
  };

  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.TRANSFER_USERS_MODAL);
  };

  return (
    <Modal
      isOpen
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <ModalContentWrapper>
        <HeaderWrapper>
          <Title>Flytt brukere</Title>

          <CloseButton onClick={handleCloseModal}></CloseButton>
        </HeaderWrapper>
        <TransferUsersForm
          users={users}
          handleCloseModal={handleCloseModal}
          handleCloseModalOnSuccess={handleCloseModalOnSuccess}
          currentCustomerId={currentCustomerId}
          currentCustomerName={currentCustomerName}
          handleUpdateSelection={handleUpdateSelection}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "650px",
    height: "750px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};
export default TransferUsersModal;
