import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { api } from "../../api/ApiSlice";
import {
  Customer,
  UserSearchResult,
  UsersListInputParameters,
} from "../../types";
import AccountView from "../accounts/AccountView";
import UsersView from "../users/UsersView";
import { ModalTypes, useModal } from "../../app/modalProvider/ModalProvider";
import { CustomerContext } from "../../app/context/Context";
import CustomerDetailSection from "./CustomerDetailSection";

const ContentSectionWrapper = styled.div``;

interface Props {
  userFromSearch: UserSearchResult;
}

const CustomerContentSection = ({ userFromSearch }: Props) => {
  const [toggleAccounts, setToggleAccounts] = useState<boolean>(true);

  const [selectedActiveFilter, setSelectedActiveFilter] =
    useState<string>("isActive");

  const [searchFilter, setSearchFilter] = useState<string>("");

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const { customerId } = useParams();

  const customer = useContext(CustomerContext) as Customer;

  const { openModal, closeModal } = useModal();

  /* Ensures modal is opened only once --> on component mount when a user is clicked from global search */
  useEffect(() => {
    if (userFromSearch) {
      openModal(ModalTypes.USER_EDIT_MODAL, {
        userId: userFromSearch.id,
        closeModal,
        customer: customer,
      });

      // To ensure that the modal is not re-opened on page reload, delete the state stored in History API
      // for user (saved by useNavigation in search.tsx, retrieved in maincontentview.tsx)
      window.history.replaceState({}, document.title);
    }
  }, []);

  /* Initial state for filtering users to table */
  const [filterParameters, setFilterParameters] =
    useState<UsersListInputParameters>({
      isAscending: false,
      isActive: true,
      orderBy: "lastChange",
      filter: "",
      PageNumber: 0,
      pageSize: 15,
      accountId: [],
    });

  /* API: Request for user data and filter changes */
  const usersData = api.useGetUsersByCustomerIdQuery(
    {
      customerId,
      params: filterParameters,
    },
    { refetchOnMountOrArgChange: true }
  );

  /* Handler function for changes to filter parameters */
  const handleFilterChange = (
    filterParam: Partial<UsersListInputParameters>
  ) => {
    setFilterParameters({ ...filterParameters, ...filterParam });
  };

  /* Handling update of filters when filtering on accounts */
  const handleClickAccountsForFilter = (id: string) => {
    // If Id exist, remove it from state, if it does not exist add to state
    if (!filterParameters.accountId?.includes(id)) {
      setFilterParameters({
        ...filterParameters,
        accountId: [...(filterParameters.accountId || []), id],
        PageNumber: 0,
        isActive: null,
      });
      setSelectedActiveFilter("null");
    } else {
      setFilterParameters({
        ...filterParameters,
        accountId: (filterParameters.accountId || []).filter((a) => a !== id),
        PageNumber: 0,
        isActive: null,
      });
    }
  };

  /* Handle all users selection for user table */
  const handleAllSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userIds = usersData.data?.users.map((user) => user.id) as string[];
    const checked = e.target.checked;

    if (checked) {
      setSelectedUsers(Array.from(new Set([...selectedUsers, ...userIds])));
    } else {
      setSelectedUsers((selected) =>
        selected.filter((id) => !userIds.includes(id))
      );
    }
  };

  /* Handle selection of single users for user table */
  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const checked = e.target.checked;
    if (checked) {
      if (!selectedUsers.includes(id)) {
        setSelectedUsers([...selectedUsers, id]);
      }
    } else {
      setSelectedUsers((prev) => {
        return prev.filter((ids) => ids !== id);
      });
    }
  };

  /* Handling updating the selection of users when/if users are transferred
     to display correct state on current customer */
  const handleUpdateSelection = (usersChecked: string[]) => {
    if (usersChecked) {
      setSelectedUsers((prev) => {
        return prev.filter((ids) => !usersChecked.includes(ids));
      });
    }
  };

  return (
    <ContentSectionWrapper>
      <CustomerDetailSection
        toggleAccounts={toggleAccounts}
        setToggleAccounts={setToggleAccounts}
      />

      <AccountView
        handleClickAccountsForFilter={handleClickAccountsForFilter}
        selectedAccounts={filterParameters.accountId}
        toggle={toggleAccounts}
        setToggleAccounts={setToggleAccounts}
      />

      {usersData.isSuccess && (
        <UsersView
          key={usersData.data.users.length}
          users={usersData.data.users}
          pager={usersData.data.pager}
          filterParams={filterParameters}
          handleFilterChange={handleFilterChange}
          setSelectedFilter={setSelectedActiveFilter}
          selectedFilter={selectedActiveFilter}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          handleSelection={handleSelection}
          handleAllSelection={handleAllSelection}
          handleUpdateSelection={handleUpdateSelection}
          selectedUserIds={selectedUsers}
        />
      )}
    </ContentSectionWrapper>
  );
};

export default CustomerContentSection;
