import { useContext, useEffect, useState } from "react";
import { api } from "../../../api/ApiSlice";
import { Account, Customer, EditUser, User } from "../../../types";
import Loader from "../../misc/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { CustomerContext } from "../../../app/context/Context";
import {
  ButtonWrapper,
  CloseButton,
  NoEditInputField,
  DeactivateButton,
  EditUserForm,
  ErrorMessage,
  InfoText,
  Input,
  Label,
  Li,
  ListWrapper,
  P,
  PhoneAreaCodeBox,
  PhoneFieldWrapper,
  PhoneInput,
  SaveButton,
  UlInfo,
  Ul,
  LogDetailsWrapper,
  LogDetails,
  FooterWrapper,
  LogLabel,
} from "../Form.styles";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";

type Accounts = {
  name: string;
  id: string;
  isnumLicensesReached: boolean;
};

interface UserFormProps {
  user: User;
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  customer: Customer;
  handleCloseModalOnSuccess: () => void;
  accounts: any[];
}

enum USER_STATUS {
  activate = "ACTIVATE",
  deactivate = "DEACTIVATE",
}

enum UserProperties {
  ID = "id",
  NAME = "name",
  EMAIL = "email",
  PHONENUMBER = "phoneNumber",
  PHONEAREACODE = "phoneAreaCode",
  INFO = "info",
  ISACTIVE = "isActive",
}
const UserEditForm = ({
  user,
  setChanged,
  handleCloseModal,
  customer,
  accounts,
  handleCloseModalOnSuccess,
}: UserFormProps) => {
  // Fetching the filterparams from redux state
  // const filterParameters = useAppSelector((state) => state.filterParams);
  const [errors, setErrors] = useState({}) as any;

  const [updateUser, { isLoading, isError }] = api.useEditUserMutation();

  const [updateUserStatus] = api.useUpdateUserStatusMutation();

  const initialAccountIds = user.accounts.map(
    (a) => a.id
  ) as unknown as string[];

  const [activeStatusChanged, setActiveStatusChanged] =
    useState<boolean>(false);

  //The initialState of the userObject before any edits
  const initialState = {
    id: user.id,
    [UserProperties.NAME]: user.name,
    [UserProperties.EMAIL]: user.email,
    emailConfirmed: user.emailConfirmed,
    [UserProperties.PHONENUMBER]: user.phoneNumber,
    [UserProperties.PHONEAREACODE]: user.phoneAreaCode,
    phoneNumberConfirmed: user.phoneNumberConfirmed,
    [UserProperties.INFO]: user.info,
    [UserProperties.ISACTIVE]: user.isActive,
    memberships: initialAccountIds,
    customerId: user.customerId,
    timestamp: user.timestamp,
  };

  // User object state for edits
  const [userEdit, setUserEdit] = useState<EditUser>(initialState);

  const handleInputChange = (value: string | boolean, type: string) => {
    setUserEdit({ ...userEdit, [type]: value });
    setChanged(true);
  };

  const validationErrors = {} as any;

  const validateForm = (user: EditUser) => {
    if (!user.name) {
      validationErrors.name = "Mangler navn!";
    } else if (user.name.length < 3) {
      validationErrors.name = "Navn er for kort!";
    }
    if (!user.email) {
      validationErrors.email = "Mangler E-post!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      validationErrors.email = "E-post er ikke gyldig!";
    }
    if (!user.memberships.length) {
      validationErrors.memberships = "Du må legge til en konto!";
    }

    if (user.phoneNumber) {
      if (user.phoneNumber.length > 8) {
        validationErrors.phoneNumber = "Telefonnummer er for langt";
      } else if (user.phoneNumber.length < 8) {
        validationErrors.phoneNumber = "Telefonnummer er for kort";
      }
    }
    // if (/^[0-9]+$/.test(user.phoneNumber)) {
    //   validationErrors.phoneNumber = "Telefonnummer kan kun inneholde tall!";
    // }
    setErrors(validationErrors);
  };

  const onSuccess = () => {
    console.log("Happen?");
    handleCloseModalOnSuccess();
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(userEdit);

    if (Object.keys(validationErrors).length === 0) {
      updateUser({ userEdit, onSuccess });
    }
  };

  const handleClickChangeActiveStatus = (e: React.MouseEvent) => {
    e.preventDefault();

    const newIsActive = !userEdit.isActive;

    // Update the local state
    setUserEdit({
      ...userEdit,
      [UserProperties.ISACTIVE]: newIsActive,
    });

    const statusToSet = newIsActive
      ? USER_STATUS.activate
      : USER_STATUS.deactivate;

    updateUserStatus({
      id: userEdit.id,
      value: statusToSet,
      onSuccess,
    });
  };

  const handleAccountSelectChanges = (id: string[]) => {
    setUserEdit({ ...userEdit, memberships: id });
    setChanged(true);
  };

  return (
    <EditUserForm onSubmit={handleSubmit}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Kunde</Label>
            <NoEditInputField>
              <P>{customer.name}</P>
            </NoEditInputField>
          </Li>
          <Li>
            <Label>Navn</Label>
            <Input
              type="text"
              value={userEdit.name}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.NAME)
              }
            ></Input>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>
          <Li>
            <Label>E-post:</Label>
            <Input
              value={userEdit.email}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.EMAIL)
              }
            ></Input>
          </Li>
          {/* <Li>
            <Label>Telefon:</Label>
            <PhoneFieldWrapper>
              <PhoneAreaCodeBox>+47</PhoneAreaCodeBox>
              <PhoneInput
                value={userEdit.phoneNumber ? userEdit.phoneNumber : ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, UserProperties.PHONENUMBER)
                }
              ></PhoneInput>
            </PhoneFieldWrapper>
            {errors.phoneNumber && (
              <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
            )}
          </Li> */}
        </Ul>
        <UlInfo>
          <Li>
            <Label>Konto</Label>
            <DropDownMultiSelect
              options={accounts}
              defaultOptions={initialState.memberships}
              handleSelected={handleAccountSelectChanges}
              placeholder={"- Legg til konto -"}
            />
          </Li>
          <Li>
            <Label>Kommentar</Label>
            <InfoText
              value={userEdit.info ? userEdit.info : ""}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.INFO)
              }
            ></InfoText>
          </Li>
        </UlInfo>
      </ListWrapper>
      <FooterWrapper>
        <LogDetailsWrapper>
          <LogLabel>Sist endret av </LogLabel>
          <LogDetails>{user.lastChangeBy}</LogDetails>
          <LogLabel>Dato endret</LogLabel>
          <LogDetails>
            {user.lastChange ? new Date(user.lastChange).toLocaleString() : ""}
          </LogDetails>
        </LogDetailsWrapper>
        <ButtonWrapper>
          <DeactivateButton
            isActive={userEdit.isActive}
            onClick={(e) => handleClickChangeActiveStatus(e)}
          >
            {userEdit.isActive ? "Deaktiver" : "Aktiver"}
          </DeactivateButton>

          <CloseButton type="button" onClick={handleCloseModal}>
            Lukk
          </CloseButton>

          <SaveButton type="submit">Lagre</SaveButton>
        </ButtonWrapper>
      </FooterWrapper>

      {isError && "en feil har oppstått"}
    </EditUserForm>
  );
};

export default UserEditForm;
