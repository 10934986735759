import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import MenuIcon from "@mui/icons-material/Menu";
import Search from "../search/globalSearch/Search";
import { useState } from "react";
import { useModal, ModalTypes } from "../../app/modalProvider/ModalProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

const NavBar = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const handleClickOnMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleClickOnLogout = () => {
    const logoutRequest = {
      account: msalInstance.getAllAccounts()[0],
      postLogoutRedirectUri: "/",
    };
    msalInstance.logoutPopup(logoutRequest);
  };

  const handleClickPageTitle = () => {
    navigate("/");
  };

  const handleClickSystemSettings = () => {
    navigate("/settings");
  };

  const handleClickAddCustomer = () => {
    openModal(ModalTypes.CUSTOMER_ADD_MODAL, { closeModal });
  };

  return (
    <>
      <NavBarContainer>
        <ContentWrapper>
          <PageTitle onClick={handleClickPageTitle}>Kundeadmin</PageTitle>
          <SearchWrapper>
            {" "}
            <Search />
          </SearchWrapper>

          <Wrapper>
            <AddNewCustomerButton onClick={handleClickAddCustomer}>
              Ny Kunde
            </AddNewCustomerButton>
            <AdministrationButton onClick={handleClickSystemSettings}>
              Innstillinger
            </AdministrationButton>
            <LogOutButton onClick={handleClickOnLogout}>Logg ut</LogOutButton>
          </Wrapper>
        </ContentWrapper>
      </NavBarContainer>
    </>
  );
};

export default NavBar;

const NavBarContainer = styled.div`
  position: fixed;
  box-shadow: ${(props) => props.theme.shadowSystem[1]};
  background-color: ${(props) => props.theme.primaryColorsSystem[1]};
  left: 0;
  right: 0;
  z-index: 1000;
`;

const ContentWrapper = styled.div`
  max-width: 2500px;
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
`;

const SearchWrapper = styled.div`
  flex: 2;
`;

const PageTitle = styled.h3`
  margin: 0;
  margin-left: ${(props) => props.theme.spacingSystem[5]};
  margin-right: ${(props) => props.theme.spacingSystem[3]};
  color: ${(props) => props.theme.greyColorsSystem[4]};
  font-weight: ${(props) => props.theme.fontWeightSystem[3]};
  flex: 1;

  &:hover {
    cursor: pointer;
  }
`;

const MenuWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacingSystem[5]};
`;

const MenuButtonWrapper = styled.div`
  position: relative;
  margin-right: ${(props) => props.theme.spacing[1]};
  margin-left: ${(props) => props.theme.spacing[4]};
  padding: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.primaryColors[2]};

    border-radius: ${(props) => props.theme.borderRadius[4]};
    cursor: pointer;
  }
`;
const NavMenuOptions = styled.div`
  position: absolute;
  border: 1px solid black;
  background: ${(props) => props.theme.secondaryColors[2]};
  margin-top: 4rem;
  margin-right: 10rem;
`;

const StyledLink = styled(Link)`
  margin: ${(props) => props.theme.spacing[1]};
`;

const AddNewCustomerButton = styled.button`
  color: #ffffff;
  background: ${(props) => props.theme.primaryColors[1]};
  /* box-shadow: ${(props) => props.theme.shadowSystem[3]}; */
  background: 0;

  /* border: ${(props) => props.theme.borderSizeSystem[1]}; */
  border: 0;

  border: 0.1rem solid transparent;
  border-radius: ${(props) => props.theme.borderRadius[1]};

  padding: ${(props) => props.theme.spacingSystem[3]};
  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  text-align: center;
  font-size: ${(props) => props.theme.fontSizeSystem[3]};

  margin-right: ${(props) => props.theme.spacing[2]};

  &:hover {
    cursor: pointer;
    border: ${(props) => props.theme.borderSizeSystem[1]};
    box-shadow: none;
    box-shadow: ${(props) => props.theme.shadowSystem[3]};
    /* box-shadow: ${(props) => props.theme.shadowSystem[5]}; */
  }
`;

const LogOutButton = styled(AddNewCustomerButton)`
  color: ${(props) => props.theme.greyColorsSystem[4]};
  /* border: 0;
  box-shadow: none;
  border: 0.1rem solid transparent; */
  padding-right: ${(props) => props.theme.spacingSystem[4]};

  &:hover {
    cursor: pointer;
    box-shadow: none;
    border: ${(props) => props.theme.borderSizeSystem[1]};
  }

  &:after {
    content: url("svg/logout-1.svg");
    position: relative;
    top: 4px;
    bottom: 0;
    left: 7px;
  }
`;

const AdministrationButton = styled(AddNewCustomerButton)`
  color: ${(props) => props.theme.greyColorsSystem[4]};
  /* border: 0; */
  /* box-shadow: none; */

  /* border: 0.1rem solid transparent; */
  padding-right: ${(props) => props.theme.spacingSystem[4]};
  &:hover {
    cursor: pointer;
    box-shadow: none;
    border: ${(props) => props.theme.borderSizeSystem[1]};
  }

  &:after {
    content: url("svg/cog-2.svg");
    position: relative;
    top: 4px;
    bottom: 0;
    left: 7px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[1]};
`;
