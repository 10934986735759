import styled, { css } from "styled-components/macro";
import { Account } from "../../types";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  accounts: Account[];
  isToggle: boolean;
  selected: string[];
  handleClickAccount: (id: string) => void;
  handleClickEditAccount: (id: string) => void;
};

const AccountFilterButtons = ({
  accounts,
  isToggle,
  handleClickAccount,
  handleClickEditAccount,
  selected,
}: Props) => {
  // Filters account based on toggle: all accounts or only active accounts
  let accountsToView;
  if (isToggle) {
    accountsToView = accounts;
  } else {
    accountsToView = accounts.filter((a) => a.isActive === true);
  }

  return (
    <>
      {accountsToView.map((acc) => (
        <FilterWrapper
          key={acc.id}
          selected={selected.indexOf(acc.id) !== -1}
          active={acc.isActive}
        >
          <FilterButton
            selected={selected.indexOf(acc.id) !== -1}
            active={acc.isActive}
            onClick={() => handleClickAccount(acc.id)}
          >
            <Span>{acc.name}</Span>
          </FilterButton>
          <EditButton
            selected={selected.indexOf(acc.id) !== -1}
            active={acc.isActive}
            onClick={() => handleClickEditAccount(acc.id)}
          >
            <EditIcon style={IconStyle} />
          </EditButton>
        </FilterWrapper>
      ))}
    </>
  );
};

export default AccountFilterButtons;

const backgroundSetter = (active: boolean, selected: boolean) => {
  if (active && selected) {
    return "rgba(62, 136, 193, 0.1)";
  } else if (!active && selected) {
    return "rgba(251, 35, 46, 0.1)";
  } else {
    return "white";
    //return "rgba(122, 122,122, 0.1)";
  }
};
const FilterWrapper = styled.div<{ selected: boolean; active: boolean }>`
  display: flex;
  align-items: center;

  margin-right: ${(props) => props.theme.spacingSystem[5]};
  margin-bottom: ${(props) => props.theme.spacingSystem[3]};

  //border: 1px solid transparent;
  border: ${(props) => props.theme.borderSizeSystem[1]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  /* background: ${(props) =>
    props.active ? "rgba(62, 136, 193, 0.1)" : "rgba(251, 35, 46, 0.1)"}; */

  background: ${(props) => backgroundSetter(props.active, props.selected)};
  /* box-shadow: ${(props) =>
    props.selected
      ? props.theme.shadowSystem[2]
      : props.theme.shadowSystem[0]}; */

  width: auto;

  ${(props) =>
    props.selected && props.active
      ? css`
          &:before {
            display: inline-block;
            content: url("svg/check-dark-blue.svg");
            position: relative;
            top: -1px;
            left: 13px;
          }
        `
      : css``};

  ${(props) =>
    props.selected && !props.active
      ? css`
          &:before {
            display: inline-block;
            content: url("svg/check-red.svg");
            position: relative;
            top: -1px;
            left: 13px;
          }
        `
      : css``};

  &:hover {
    background: ${(props) =>
      props.active ? "rgba(62, 136, 193, 0.1)" : "rgba(251, 35, 46, 0.1)"};
  }
`;

const colorSetter = (active: boolean, selected: boolean) => {
  if ((active && selected) || (active && !selected)) {
    return "#16425B";
  } else if (!active || (!active && selected)) {
    return "#C22F2F";
  }
};

const FilterButton = styled.button<{ selected: boolean; active: boolean }>`
  background: none;
  border: none;

  color: ${(props) => colorSetter(props.active, props.selected)};

  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
  padding-left: ${(props) => props.theme.spacingSystem[5]};
  padding-right: ${(props) => props.theme.spacingSystem[5]};

  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;

const EditButton = styled.button<{ selected: boolean; active: boolean }>`
  background: none;
  border: none;

  color: ${(props) => colorSetter(props.active, props.selected)};

  font-size: ${(props) => props.theme.fontSizeSystem[0]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  border-left: 1px solid #d6d6d6;

  padding-left: ${(props) => props.theme.spacingSystem[3]};
  padding-right: ${(props) => props.theme.spacingSystem[3]};
`;

const Span = styled.span``;

const IconStyle = {
  fontSize: "medium",
  marginLeft: "3px",
};
