import { useContext, useState } from "react";
import styled from "styled-components/macro";
import { CustomerContext } from "../../app/context/Context";
import { Customer } from "../../types";
import { ModalTypes, useModal } from "../../app/modalProvider/ModalProvider";
import RadioSwitch from "../misc/switches/RadioSwitch";
import AccountFilterButtons from "./AccountFilterButtons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacingSystem[0]};
`;

const ToggleWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacingSystem[2]};
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  max-width: 2000px;
  margin-top: ${(props) => props.theme.spacingSystem[4]};
`;

enum TOGGLE_TYPES {
  ACTIVE = "active",
  ALL = "all",
}

type Props = {
  handleClickAccountsForFilter: (accountId: string) => void;
  selectedAccounts: string[];
  toggle: boolean;
  setToggleAccounts: (toggle: boolean) => void;
};
const AccountView = ({
  handleClickAccountsForFilter,
  selectedAccounts,
  toggle,
  setToggleAccounts,
}: Props) => {
  const [type, setType] = useState<string>(TOGGLE_TYPES.ALL);
  /* Get customer context */
  const customer = useContext(CustomerContext) as Customer;

  let accounts = customer.accounts;

  const { openModal, closeModal } = useModal();

  /* Opens modal for edit form of accounts */
  const handleClickEditAccount = (accountId: string) => {
    openModal(ModalTypes.ACCOUNT_EDIT_MODAL, {
      customerId: customer.id,
      accountId: accountId,
      closeModal,
    });
  };

  const handleChangeOnToggle = (e: any) => {
    const type = e.target.id;

    if (type === TOGGLE_TYPES.ACTIVE) {
      setToggleAccounts(false);
      setType(type);
    } else if (type === TOGGLE_TYPES.ALL) {
      setToggleAccounts(true);
      setType(type);
    }
  };

  return (
    <Wrapper>
      <ToggleWrapper>
        <RadioSwitch type={type} handleChange={handleChangeOnToggle} />
      </ToggleWrapper>

      <FilterButtonWrapper>
        <AccountFilterButtons
          accounts={accounts}
          isToggle={toggle}
          selected={selectedAccounts}
          handleClickAccount={handleClickAccountsForFilter}
          handleClickEditAccount={handleClickEditAccount}
        />
      </FilterButtonWrapper>
    </Wrapper>
  );
};

export default AccountView;
