import { useState } from "react";
import styled from "styled-components/macro";
import { User } from "../../types";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = styled.div`
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
`;

const ExpandNavigation = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.5px solid #cccccc;

  border-radius: 0.5rem;
  width: 550px;
  height: 45px;

  &:after {
    position: relative;
    content: url("svg/arrow-down.svg");
    top: 4px;

    left: 180px;
    transform: scale(0.5);
  }

  &:hover {
    cursor: pointer;
  }
`;

const P = styled.p`
  margin: 0;
  font-size: small;
`;

const DetailWrapper = styled.div`
  position: absolute;

  background: white;
  z-index: 1000;

  border: 0.5px solid #cccccc;
  border-radius: 0.5rem;
  width: 550px;
  min-height: auto;
  margin-top: 0.5rem;
`;

export const ListWrapper = styled.div`
  @media (min-width: 400px) {
    display: flex;
    flex-direction: column;
  }

  max-width: 500px;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 2;
  padding: 0 2rem 1rem;
  max-width: 500px;
`;

export const Li = styled.li`
  display: flex;
  align-items: end;
  justify-content: space-between;
  height: 35px;
`;

export const Label = styled.label`
  font-size: small;
  margin-bottom: 0.5rem;
  color: #7a7a7a;
`;

export const RemoveUserButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: #7a7a7a;

  &:hover {
    color: red;
  }
`;

type Props = {
  handleRemoveUserToTransfer: (
    id: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => void;

  userObjects: User[];
};
const ExpandableDetailsBox = ({
  userObjects,
  handleRemoveUserToTransfer,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickDetails = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  return (
    <Wrapper>
      <ExpandNavigation isOpen={isOpen} onClick={handleClickDetails}>
        <P>Antall brukere valgt {userObjects.length}</P>
      </ExpandNavigation>
      {isOpen && (
        <DetailWrapper>
          <ListWrapper>
            <Ul>
              {userObjects.map((u) => (
                <Li key={u.id}>
                  <P>{u.name}</P>
                  <RemoveUserButton
                    onClick={(e) => handleRemoveUserToTransfer(u.id, e)}
                  >
                    <CloseIcon style={{ fontSize: "small" }} />
                  </RemoveUserButton>
                </Li>
              ))}
            </Ul>
          </ListWrapper>
        </DetailWrapper>
      )}
    </Wrapper>
  );
};

export default ExpandableDetailsBox;
