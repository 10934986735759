import styled from "styled-components/macro";
import Table from "./Table";
import { useState } from "react";
import AccountPlanSettings from "./accountPlans/AccountPlanSettings";
import ProductSettings from "./products/ProductsSettings";
import Templates from "./templates/Templates";
import Suppliers from "./suppliers/Suppliers";

enum options {
  ACCOUNT_PLANS = "ACCOUNT_PLANS",
  PRODUCTS = "PRODUCTS",
  TEMPLATES = "TEMPLATES",
  SUPPLIERS = "SUPPLIERS",
}
const SettingsOverview = () => {
  const [selectedOption, setSelectedOption] = useState<string>(
    options.ACCOUNT_PLANS
  );

  const handleOptionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    let button = e.target as HTMLButtonElement;

    switch (button.id) {
      case options.ACCOUNT_PLANS:
        setSelectedOption(options.ACCOUNT_PLANS);
        break;
      case options.PRODUCTS:
        setSelectedOption(options.PRODUCTS);
        break;
      case options.TEMPLATES:
        setSelectedOption(options.TEMPLATES);
        break;
      case options.SUPPLIERS:
        setSelectedOption(options.SUPPLIERS);
        break;
      default:
        return;
    }
  };

  return (
    <Container>
      <Title>Systemadministrasjon</Title>
      <MenuWrapper>
        <Button
          id={options.ACCOUNT_PLANS}
          onClick={(e) => handleOptionClick(e)}
          isSelected={selectedOption === options.ACCOUNT_PLANS}
        >
          {/* <ArrowDown
            isActive={selectedOption === options.ACCOUNT_PLANS}
          ></ArrowDown> */}
          Produktpakker
        </Button>
        <Button
          id={options.PRODUCTS}
          onClick={(e) => handleOptionClick(e)}
          isSelected={selectedOption === options.PRODUCTS}
        >
          {/* <ArrowDown isActive={selectedOption === options.PRODUCTS}></ArrowDown> */}
          Produkter
        </Button>
        <Button
          id={options.TEMPLATES}
          onClick={(e) => handleOptionClick(e)}
          isSelected={selectedOption === options.TEMPLATES}
        >
          Maler
        </Button>
        <Button
          id={options.SUPPLIERS}
          onClick={(e) => handleOptionClick(e)}
          isSelected={selectedOption === options.SUPPLIERS}
        >
          Leverandører
        </Button>
      </MenuWrapper>
      <ContentWrapper>
        {selectedOption === options.ACCOUNT_PLANS && <AccountPlanSettings />}
        {selectedOption === options.PRODUCTS && <ProductSettings />}
        {selectedOption === options.TEMPLATES && <Templates />}
        {selectedOption === options.SUPPLIERS && <Suppliers />}
      </ContentWrapper>
    </Container>
  );
};

export default SettingsOverview;

const Container = styled.div`
  max-width: 2500px;
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacingSystem[6]};
`;

const Title = styled.h5`
  color: ${(props) => props.theme.primaryColorsSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
`;

const MenuWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacingSystem[2]};
`;

const Button = styled.button<{ isSelected: boolean }>`
  padding-left: 0;
  padding-right: 0;

  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[0]};
  margin-right: ${(props) => props.theme.spacingSystem[5]};

  border: none;
  border-bottom: ${(props) =>
    props.isSelected
      ? `2px solid ${props.theme.primaryColorsSystem[2]}`
      : "2px solid transparent"};
  background: none;

  color: ${(props) => props.theme.primaryColorsSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[3]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  position: relative;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.primaryColorsSystem[2]};
  }
`;

const ArrowDown = styled.div<{ isActive: boolean }>`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: ${(props) =>
    props.isActive
      ? `12px solid ${props.theme.primaryColorsSystem[1]}`
      : "none"};
  position: absolute;
  bottom: -12px;
  left: -1.5px;
`;

const ContentWrapper = styled.div`
  display: flex;
`;
