import { SearchResults, UserSearchResult } from "../../../types";
import PersonIcon from "@mui/icons-material/Person";
import {
  AccountResult,
  CustomerResult,
  IconWrapperNoData,
  ListWrapper,
  NoDataMessage,
  SearchListContainer,
  SearchResultElement,
  UserResult,
  WrapperAccountDetails,
  WrapperCustomer,
  WrapperUsers,
} from "./SearchResult.styles";
import { P } from "../customerSearch/customerSearchResult";

const IconStyles = {
  fontSize: "medium",
  marginRight: "0.5rem",
};
type SearchResultProps = {
  results: SearchResults[];
  handleOnEnterPress: (id: string, user?: UserSearchResult) => void;
  handleOnClick: (id: string, user?: UserSearchResult) => void;
};
const SearchResult = ({
  results,
  handleOnEnterPress,
  handleOnClick,
}: SearchResultProps) => {
  const handleEnterPress = (e: React.KeyboardEvent, id: string, user?: any) => {
    if (e.key === "Enter") {
      handleOnEnterPress(id, user);
    }
  };

  const handleClick = (id: string, user?: any) => {
    if (user) {
      handleOnClick(id, user);
    } else handleOnClick(id);
  };

  return (
    <SearchListContainer>
      {!results.length ? (
        <IconWrapperNoData>
          <img
            src="svg/abstract-character-12.svg"
            alt="img"
            height={"100px"}
          ></img>
          <NoDataMessage>Ingen treff - prøv nytt søk</NoDataMessage>
        </IconWrapperNoData>
      ) : (
        <ListWrapper>
          {results.map((result, index) => (
            <SearchResultElement key={result.id} active={result.isActive}>
              <WrapperCustomer
                onClick={() => handleClick(result.id)}
                onKeyDown={(e) => handleEnterPress(e, result.id)}
                tabIndex={0}
              >
                <CustomerResult fontWeight="bold" isActive={result.isActive}>
                  {result.name}
                </CustomerResult>
                <CustomerResult fontWeight="none" isActive={result.isActive}>
                  {result.companyId}
                </CustomerResult>
              </WrapperCustomer>

              {result.accounts.map((account) => (
                <div key={account.id}>
                  {account.users.length !== 0 && (
                    <WrapperAccountDetails key={account.id}>
                      <AccountResult
                        isActive={account.isActive}
                        onClick={() => handleClick(result.id)}
                      >
                        {account.name}
                      </AccountResult>

                      <WrapperUsers>
                        {account.users.map((user) => (
                          <UserResult
                            key={user.id}
                            onClick={() => handleClick(result.id, user)}
                            onKeyDown={(e) =>
                              handleEnterPress(e, result.id, user)
                            }
                            tabIndex={0}
                            isActive={user.isActive}
                          >
                            {" "}
                            <PersonIcon style={IconStyles} />
                            {user.name}
                          </UserResult>
                        ))}
                      </WrapperUsers>
                    </WrapperAccountDetails>
                  )}
                </div>
              ))}
            </SearchResultElement>
          ))}
        </ListWrapper>
      )}
    </SearchListContainer>
  );
};

export default SearchResult;
