import styled, {css} from "styled-components/macro";

export const TableWrapper = styled.div`
    margin-right: ${(props) => props.theme.spacing[1]};
    border: 0.5px solid ${(props) => props.theme.greyScale[1]};
    border-radius: ${(props) => props.theme.spacing[1]};;
    overflow: hidden;

`;

export const Table = styled.table` 
   border-collapse: collapse;
   width: 100%;
   vertical-align: top;
   margin-bottom: ${(props) => props.theme.spacing[1]};

`; 

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
`;

export const Column = styled.th<{width: string, colId: string, isAscending: boolean}>`
    width: width;
    text-align: left;
    font-size:  ${(props) => props.theme.fontSizeSystem[1]};
    font-weight: ${(props) => props.theme.fontWeightSystem[3]};
    height: 30px;
    color: ${(props) => props.theme.greyColorsSystem[6]};


    /* If column ID is equal to option or account, do not display pointer on hover to indicate not clickable */
    ${(props) => props.id === "option"|| props.id === "account" ? css` 
            &:hover {
                cursor: auto;
            }` : css` 
            &:hover {
                cursor: pointer;
            }`
    }

    /* If column ID is equal to option or account, do not display icon for soring */
    ${(props) => props.id === "option" || props.id === "account" ? "none" : css`
        &:after{
            display: inline-block;
            content: url("svg/arrows_swap_vert.svg");
            position: relative;
          
            transform: scale(0.8);
        }`
    }


    /* Adding sort icon on column */
    &:after{
        display: inline-block;
        content: url("svg/arrows_swap_vert.svg");
        position: absolute;
        margin-left: 0.5rem;
        transform: scale(0.8);
    }
    
    /* Handles icon change based on ascending sort order */
    ${(props) =>
        props.colId === props.id && props.isAscending &&
        css`
        &:after {
            display: inline-block;
            content: url("svg/arrow_upward.svg");
            position: absolute;
            margin-left: 0.5rem;
        }
        `} 
    /* Handles icon change based on descending sort order */
    ${(props) =>
    props.colId === props.id && !props.isAscending &&
    css`
      &:after {
        display: inline-block;
        content: url("svg/arrow_downward.svg");
        position: absolute;
        margin-left: 0.5rem;
      }
    `} 

`; 

export const TableDataCell = styled.td<{isClickable?: boolean, width: string}>`   
    width: width;
    border-bottom:  ${(props) => props.theme.borderSizeSystem[0]};
    border-top:  ${(props) => props.theme.borderSizeSystem[0]};
    text-align: left;
    vertical-align: middle;
    font-size: ${(props) => props.theme.fontSizeSystem[2]};
    font-weight: ${(props) => props.theme.fontWeightSystem[0]};

`; 

export const Row = styled.tr<{
    isHeader: boolean;
    isSelected?: boolean;
    isActive?: boolean;
  }>`

    height: 2.5rem;
    background-color: ${(props) => props.isHeader ? "rgba(122, 122,122, 0.1)" : "none"};
    &:hover { 
        background-color: ${(props) => props.isHeader ? "none" : "rgba(122, 122,122, 0.1)"};
        cursor: pointer;
    }

`; 


export const CheckboxCell = styled.td`
    width: 55px;  
    border-bottom: ${(props) =>  props.theme.borderSizeSystem[0]};
    text-align: left;
    vertical-align: middle;
    font-size: ${(props) => props.theme.fontSizeSystem[1]};
`; 

export const ActivateButton = styled.button`
    border: 0;
    color: ${(props) => props.theme.greyColorsSystem[0]};
    background: 0;
    margin: ${(props) => props.theme.spacingSystem[1]};
    &:hover{
        color:  ${(props) => props.theme.primaryColorsSystem[6]};
    }
`; 

export const Button = styled.button`
    border: none;
    background: none;
    margin-right: ${(props) => props.theme.spacing[0]};
    width: 2rem;
    height: 2rem;
    color:  ${(props) => props.theme.greyColorsSystem[0]};
    &:hover{
        color: ${(props) => props.theme.primaryColorsSystem[6]};
    }
`;


export const DeactivateButton = styled(Button)`
    color: ${(props) => props.theme.greyColorsSystem[0]};
    &:hover{
        color:  ${(props) => props.theme.primaryColorsSystem[7]};
    }
`; 

export const P = styled.p<{isActive: boolean }>`
    padding-right: ${(props) => props.theme.spacingSystem[2]};
    padding-top: ${(props) => props.theme.spacingSystem[1]};
    padding-bottom: ${(props) => props.theme.spacingSystem[0]};
    margin: 0;
    color: ${(props) => props.isActive ?  0 : props.theme.primaryColorsSystem[7]};
`;
