import { useState } from "react";
import styled from "styled-components/macro";
import CheckIcon from "@mui/icons-material/Check";

const HiddenCheckBox = styled.input`
  //clip-path: circle(0);
  clip-path: inset(100%);
  position: absolute;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  margin-left: 1rem;
`;

export const Label = styled.label<{ isChecked: boolean }>`
  display: inline-block;
  position: absolute;
  height: 15px;
  width: 15px;
  background: ${(props) => (props.isChecked ? "#2f6690" : "white")};
  border: ${(props) =>
    props.isChecked ? "1px solid #2f6690" : "1px solid grey;"};
  border-radius: 0.2rem;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
  }
  &:active {
    border: 1px solid black;
  }
`;

const CheckIconStyles = {
  fontSize: "small",
  margin: "0px 0px 20px",
  fontWeight: "bolder",
  color: "#ffffff",
};

type CheckBoxProps = {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const Checkbox = ({ checked, handleChange }: CheckBoxProps) => {
  return (
    <Wrapper>
      <Label isChecked={checked}>
        <HiddenCheckBox
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />

        {checked ? <CheckIcon style={CheckIconStyles} /> : ""}
      </Label>
    </Wrapper>
  );
};
export default Checkbox;
