import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import AccountEditForm from "../forms/account/AccountEditForm";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import { api } from "../../api/ApiSlice";
import Loader from "../misc/Loader";
import {
  CloseButton,
  HeaderWrapper,
  InactiveSymbol,
  InactiveText,
  ModalContentWrapper,
  Title,
  WrapperTitle,
} from "./Modal.Styles";
import { useState } from "react";
import ModalChangesAlertDialog from "../dialogs/ModalChangesAlert";

// Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "900px",
    height: "700px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

interface AccountEditModalProps {
  customerId: string;
  accountId: string;
  closeModal: (type: ModalTypes) => void;
}
const AccountEditModal = ({
  customerId,
  accountId,
  closeModal,
}: AccountEditModalProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.ACCOUNT_EDIT_MODAL);
    }
  };

  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.ACCOUNT_EDIT_MODAL);
  };

  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.ACCOUNT_EDIT_MODAL);
  };

  const handleChangeToForm = (value: boolean) => {
    setChanged(value);
  };

  const {
    data: accountData,
    isLoading,
    isSuccess,
  } = api.useGetAccountQuery(accountId);

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <Modal
          isOpen
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleCloseModal}
        >
          <ModalContentWrapper>
            <HeaderWrapper>
              <WrapperTitle>
                <Title>Rediger konto</Title>
                {isSuccess && !accountData.isActive && (
                  <InactiveSymbol>
                    <InactiveText>Inaktiv</InactiveText>
                  </InactiveSymbol>
                )}
              </WrapperTitle>

              <CloseButton onClick={handleCloseModal}></CloseButton>
            </HeaderWrapper>
            {isSuccess && (
              <AccountEditForm
                customerId={customerId}
                account={accountData}
                handleCloseModal={handleCloseModal}
                handleCloseModalOnSuccess={handleCloseModalOnSuccess}
                handleChangeToForm={handleChangeToForm}
              />
            )}
          </ModalContentWrapper>
          {showAlert && (
            <ModalChangesAlertDialog
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
            />
          )}
        </Modal>
      )}
    </>
  );
};
export default AccountEditModal;
