import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {msalConfig, msalScopes} from '../authConfig'
import {PublicClientApplication} from "@azure/msal-browser";
import { 
	Customer, 
	CompanyType, 
	NewCustomer, 
	SearchResults, 
	Account, 
	CustomersListInputParameters,
    PagerInfo, 
    User, 
    UsersListInputParameters,
    EditAccount, 
    EditUser, 
    NewUser,      
    EditCustomer, 
    NewAccount, 
    AccountPlan, 
    AccountModel,
    Product,
    ProductsForAccountEdit,
    CustomerSearchResult,
    AccountList,
    TransferUsers,
    Language,
    NewProduct,
    ProductTypes,
    EditProduct,
    NewAccountPlan,
    EditAccountPlan,
    ProductListInputParameters,
} from '../types'
import { Mutex } from 'async-mutex'
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import Toast, { ToastTimeout } from '../components/utilities/toast-notifications';


const msalInstance = new PublicClientApplication(msalConfig);
const account = msalInstance.getActiveAccount();



function loggedOut() {
    // This action will be dispatched when the user is logged out and can't get a new token
    return { type: 'LOGGED_OUT' }
}

// Define a custom baseQuery function to add authentication/authorization headers
const mutex = new Mutex()
const baseQuery = fetchBaseQuery({ 
    baseUrl: '/api',
    credentials: 'include', // This allows server to set cookies
    prepareHeaders: async (headers) => {
        const account = msalInstance.getAllAccounts()[0];
        // console.log(account);
        if (account) {
            const response = await msalInstance.acquireTokenSilent({
                scopes: msalScopes,
                account,
            });
            // console.log(response);
            const accessToken = response.accessToken;
            headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return headers;
    } 
});


const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                if (account) {
                    const refreshResult = await msalInstance.acquireTokenSilent({ scopes: msalScopes, account })
                    const accessToken = refreshResult.accessToken;
                    result = await baseQuery(args, api, extraOptions)
                    
                } else {
                    api.dispatch(loggedOut())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}

/* Queries fetch data, while mutation update/add data.
 * Tags Define relationships between queries and mutations to enable auto refetching of data. (e.g, adding a new customer, should update the list of customers )
 * If needed it is also possible to add headers to the requests
 */ 
export const api = createApi({
    // Default path 
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Customer", "User", "SingleUser", "UserList", "Account", "ProductList", "Product", "AccountPlanList", "AccountPlan"],
    endpoints: (builder) => ({
       /* 
        * End Points for Customer Operations 
        */ 
        getAllCustomers: builder.query<{customers: Customer[], pager: PagerInfo}, Partial<CustomersListInputParameters> >({
            query: (params) => {
                    
                    let mapParams = Object.keys(params).map((k)=> [k, `${params[k as keyof CustomersListInputParameters]}`]);
                   
                    let url = new URLSearchParams(mapParams)

                    if(params.isActive === null){
                        url.delete("isActive")
                    }
                    
                    if(params.filter?.toString() === ""){
                        url.delete("filter")
                    }
                    
                    if(Number(params.PageNumber) === 0){
                        url.delete("PageNumber")
                    }
                    return `/customers${!url ? '' : `?${url.toString()}`}`           
            },
            transformResponse: (response: any, meta: any)  => {  
               
                const ret = {customers: response, pager: JSON.parse(meta?.response?.headers.get('X-pagination'))};
            
                return ret;
            },
           providesTags: (result,) => result ? [ ...result.customers.map(({id}) => ({type: "Customer" as const, id})), {type: "Customer", id: "LIST"}] : [{type: "Customer", id: "LIST"}],
        
        }),

        getCustomer: builder.query<Customer, string | undefined >({
            query: (id) => `/customers/${id}`,
            providesTags: (result, error, arg) => [{type: "Customer", id: arg}], 
        }),
       
        addCustomer: builder.mutation<Customer, {newCustomer: NewCustomer, onSuccess: any}>({
            query: newCustomer => ({
              url: '/customers',
              method: 'POST',
              // Include the entire post object as the body of the request, automatically json serialized 
              body: newCustomer.newCustomer
            }),
            invalidatesTags: ["Customer"],

             async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   const {data} = await queryFulfilled
                   args.onSuccess(data.id);
                }catch{

                }
            },
        }),

        editCustomer: builder.mutation<Customer, {customerEdit: EditCustomer, onSuccess?: any}>({
            query: editData=> ({
                url: `/customers/${editData.customerEdit.id}`,
                method: "PUT",
                body: editData.customerEdit,
            }),
            invalidatesTags: (result, error, arg) => [{type: "Customer", id: arg.customerEdit.id}],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                }catch{

                }
            },
        }),

        searchCustomer: builder.query<CustomerSearchResult[], string>({
            query: searchQuery => ({
                url: `/customers/search?q=${searchQuery}`,
            })

        }),
     
        /* --- End Points for Company Type Operations --- */ 
        getAllCompanyTypes: builder.query<CompanyType[], void>({
            query: () => '/companyType',
        }),

         /* --- Endpoint for Global Search Operation --- */ 
        getSearchResults: builder.query<SearchResults[], string | null>({
            query: (query) => `/search?q=${query}`

        }),

        /* --- Endpoint for Account Operations --- */ 
        getAccounts: builder.query<Account[], void>({
            query: () => '/accounts',
            providesTags: ["Account"],
        }),
        getAccount: builder.query<Account, string | undefined>({
            query: (id) => `/accounts/${id}`,
            providesTags: ["Account"],
        }),
        getAccountsByCustomerId: builder.query<AccountList[], string>({
            query: (id) => `/accounts/customerAccounts/${id}`,
        }),

        addAccount: builder.mutation<Account, {newAccount: NewAccount, onSuccess?: any}>({
            query: data => ({
                url: '/accounts',
                method: 'POST',
                // Include the entire post object as the body of the request, automatically json serialized 
                body: data.newAccount,
              }),
              invalidatesTags: ["Account"],

              async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   const data = await queryFulfilled
                    dispatch(api.util.updateQueryData('getCustomer', args.newAccount.customerId, (draft) => {
                        draft.accounts.push(data.data)
                    }))

                   args.onSuccess();


                }catch (e){
                    // handle error
                }
            }
        }),

        editAccount: builder.mutation<Account, {editAccount: EditAccount, onSuccess?: any}>({
            query: data => ({
                url: `/accounts/${data.editAccount.id}`,
                method: "PUT",
                body: data.editAccount
            }),
            invalidatesTags: ["Account"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
               const patchResult = dispatch(api.util.updateQueryData('getCustomer', args.editAccount.customerId, (draft) => {
                    let accountToUpdate = draft.accounts.find(account => account.id === args.editAccount.id);
            
                    if(accountToUpdate){
                        Object.assign(accountToUpdate, args.editAccount)
                     
                    }
                   
                }))
                try{
                    await queryFulfilled
              
                    args.onSuccess();
                }
                catch (e){
                    patchResult.undo();
                }
            }

        }),

        updateAccountStatus: builder.mutation<Account, {id: string, value: string, customerId: string, onSuccess?: any}>({
            query: (data) => ({
                url: `/accounts/toggle/${data.id}?value=${data.value}`,
                method: 'PUT',
                body: {id: data.id, value: data.value}
            }),
            invalidatesTags: ["Account"],

            async onQueryStarted(args, {dispatch, queryFulfilled}){

                const patchResult = dispatch(api.util.updateQueryData('getCustomer', args.customerId, (draft) => {
                    
                    let accountToUpdate = draft.accounts.find(account => account.id === args.id);

                    if(!accountToUpdate){
                        return
                    }

                    if(args.value === "ACTIVATE"){
                        accountToUpdate.isActive = true;
                    }
                    if(args.value === "DEACTIVATE"){
                        accountToUpdate.isActive = false;
                    }
                }))
                try{
                    const {data} = await queryFulfilled; 

                    dispatch(api.util.updateQueryData('getAccount', args.id, (draft) => {
                        Object.assign(draft, data)
                    }))

                    args.onSuccess();

                    if(args.value === "ACTIVATE"){
                        new Toast("Konto er aktivert", {type: "success", autoClose: ToastTimeout.short})
                   }
                    else {
                        new Toast("Konto er deaktivert!", {type: "success", autoClose: ToastTimeout.short})
                    }

                }catch (e){
                    patchResult.undo();

                }
            }
        }),

        /* --- Endpoints for User Operations --- */ 
        getUserById: builder.query<User, string | undefined>({
            query: (id) => `/users/${id}/`,
            providesTags: ["User", "SingleUser"]
           
        }),

        getUsersByCustomerId: builder.query<{users: User[], pager: PagerInfo}, {customerId: string | undefined, params: Partial<UsersListInputParameters>}>({
            query: ({customerId, params}) => {
                
                // let url = transformToURL<UsersListInputParameters>(params)
                let mapParams = Object.keys(params).map((k)=> [k, `${params[k as keyof UsersListInputParameters]}`]);
                   
                let url = new URLSearchParams(mapParams)

                if(params.isActive === null){
                    url.delete("isActive")
                }
                if(params.filter?.toString() === ""){
                    url.delete("filter")
                }
                if(params.accountId?.length === 0){
                    url.delete("accountId")
                } 
                
                if(Number(params.PageNumber) === 0){
                    url.delete("PageNumber")
                }
                
                return `/customers/${customerId}/users${!url ? '' : `?${url.toString()}`}`     
            }, 
            transformResponse: (response: any, meta: any)  => {  
               
                const ret = {users: response, pager: JSON.parse(meta?.response?.headers.get('X-pagination'))};
            
                return ret;
            }, 
            providesTags: ["User", "UserList"]
            
        }),

        editUser: builder.mutation<User, {userEdit: EditUser, onSuccess?: any} >({
            query: (editUser) => ({
                url: `/users/${editUser.userEdit.id}`,
                method: "PUT",
                body: editUser.userEdit
            }),
            invalidatesTags: ["User"],

            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                }catch{

                }
            }
        }),

        addUser: builder.mutation<User, {newUser: NewUser, onSuccess?: any}>({
            query: newUser => ({
                url: '/users',
                method: 'POST',
                body: newUser.newUser
              }),
            invalidatesTags: ["User"], 

            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                }catch{

                }
            },
        }),

        updateUserStatus: builder.mutation<User, {id: string, value: string, cacheParams?: {customerId: string, filterParams: UsersListInputParameters}, onSuccess?:any}>({
            query: (data) => ({
                url:`/users/toggle/${data.id}?value=${data.value}`,
                method: "PUT",
                body: {id: data.id, value: data.value},
            }),  
           invalidatesTags: ["UserList", "SingleUser"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){

                // If endpoint called from user table 
                if(args.cacheParams){
                    const patchResult = dispatch(api.util.updateQueryData('getUsersByCustomerId', {customerId: args.cacheParams.customerId, params: args.cacheParams.filterParams}, (draft) => {
                        // Directly updating the user     
                        let userToUpdate = draft.users.find(user => user.id === args.id)
                            //Some error handling needed here if usertoupdate is not found
                            if(!userToUpdate){
                                return
                            }
                            if(args.value === "ACTIVATE"){
                                userToUpdate.isActive = true;
                            }
                            else if(args.value === "DEACTIVATE"){
                                userToUpdate.isActive = false;
                            }
                        })
                    )
                    try{
                        const {data} = await queryFulfilled; 
                        // Manually update the redux store data 
                        dispatch(api.util.updateQueryData('getUserById', args.id, (draft) => {
                            Object.assign(draft, data)
                        }))

                        // Setting toast to indicate success
                        if(args.value === "ACTIVATE"){
                            new Toast("Bruker er aktivert", {type: "success", autoClose: ToastTimeout.short})
                       }
                        else {
                            new Toast("Bruker er deaktivert!", {type: "success", autoClose: ToastTimeout.short})
                        } 
                    }
                    catch (e){
                        //handle exception if it occurs
                        patchResult.undo();
                    }
            } 
            // Otherwise from user edit modal
            else {
                try{
                    const {data} = await queryFulfilled; 
                   
                    dispatch(api.util.updateQueryData('getUserById', args.id, (draft) => {
                        console.log("Draft;", draft.name)
                        console.log("data;", data)
                        Object.assign(draft, data)
                    }))

                    args.onSuccess();
                    if(args.value === "ACTIVATE"){
                        new Toast("Bruker er aktivert", {type: "success", autoClose: ToastTimeout.short})
                    }
                    else {
                        new Toast("Bruker er deaktivert!", {type: "success", autoClose: ToastTimeout.short})
                    }
                    
                }
                catch (e){
                    //handle exception if it occurs
                    new Toast("Endring feilet", {type: "error", autoClose: ToastTimeout.short})
                   
                }
            }
        }
        }),

        transferUsers: builder.mutation<void, {usersToTransfer: TransferUsers, onSuccess?: any}>({
            query: (data) => ({
                url: '/users/transfer',
                method: 'PATCH',
                body: data.usersToTransfer,
            }), 
            invalidatesTags: ["UserList"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                   new Toast(`${args.usersToTransfer.userIds.length} brukere flyttet!`,{type: "success", autoClose: ToastTimeout.medium})
                }catch (error){
                    console.log("error transfer,", error)
                    new Toast(`Noe feilet under flytting av brukere`,{type: "error", autoClose: ToastTimeout.medium})
                }}
        }),

        /* AccountPlans */
        getAccountPlanList: builder.query<AccountPlan[], void>({
            query: () => `/accountPlan`,
            providesTags: ["AccountPlanList"]
        }),

        addAccountPlan: builder.mutation<AccountPlan, {newAccountPlan: NewAccountPlan, onSuccess?: any}>({
            query: data => ({
                url: '/accountPlan',
                method: 'POST',
                // Include the entire post object as the body of the request, automatically json serialized 
                body: data.newAccountPlan,
              }),
              invalidatesTags: ["AccountPlanList"],
              async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                  await queryFulfilled
    
                   args.onSuccess();


                }catch (e){
                    // handle error
                }
            }
        }),

        getAccountPlanById: builder.query<AccountPlan, string>({
            query: (id) => `/accountPlan/${id}`,
            providesTags: ["AccountPlan"],
        }),

        editAccountPlan: builder.mutation<AccountPlan, {editAccountPlan: EditAccountPlan, onSuccess?: any}>({
            query: data => ({
                url: `/accountPlan/${data.editAccountPlan.id}`,
                method: "PUT",
                body: data.editAccountPlan
            }),
            invalidatesTags: ["AccountPlanList", "AccountPlan"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
   
                try{
                    await queryFulfilled
              
                    args.onSuccess();
                }
                catch (e){
                    // patchResult.undo();
                }
            }
        }),

        updateAccountPlanStatus: builder.mutation<void, {id: string, value: string, onSuccess?: any}>({
            query: (data) => ({
                url:`/accountPlan/toggleActiveState/${data.id}?value=${data.value}`,
                method: "PUT",
                body: {id: data.id, value: data.value},
            }),  
            invalidatesTags: ["AccountPlanList", "AccountPlan"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                    if(args.value === "ACTIVATE"){
                        new Toast("Produktpakken er aktivert", {type: "success", autoClose: ToastTimeout.short})
                   }
                    else {
                        new Toast("Produktpakken er deaktivert!", {type: "success", autoClose: ToastTimeout.short})
                    }
                
                }catch{
                    new Toast("Produktpakken ble ikke oppdatert. ", {type: "error", autoClose: ToastTimeout.short})
                }
            }
        }),

        getAccountModel: builder.query<AccountModel, void>({
            query: () => '/accounts/accountModel'
        }),
        
        /*
         *  Products */
        getAllProducts: builder.query< Product[], void>({
            query: () => `/product`,
            providesTags: ["ProductList"]
        }),
    
        getProductsNotInAccountPlan: builder.query<Product[], {customerId: string, accountPlanId: string}>({
            query: (data) => `/product/accountPlan/${data.accountPlanId}?customerId=${data.customerId}`
        }),

        getProductsForAccountEdit: builder.query<ProductsForAccountEdit, {accountPlanId: string | null, customerId: string, accountId: string}>({
            query: (data) => `/product/productsForAccount/${data.accountPlanId}/${data.customerId}/${data.accountId}`
        }),
    
        getProductTypes: builder.query<ProductTypes[], void>({
            query: () => `/product/productType`
        }),

        getProduct: builder.query<Product, string>({
            query: (id) => `/product/${id}`,
            providesTags: ["Product"],
        }),

        addProduct: builder.mutation<Product, {newProduct: NewProduct, onSuccess?: any}>({
            query: (data) => ({
                url: '/product',
                method: 'POST',
                body: data.newProduct,
            }),
            invalidatesTags: ["ProductList"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try {
                    const {data} = await queryFulfilled
                    args.onSuccess();
                    new Toast("Nytt produkt lagt til", {type: "success", autoClose: ToastTimeout.medium})
                }catch (error) {
                    console.log({error})

                    new Toast("Noe feilet under opprettelse av produkt",{type: "error", autoClose: ToastTimeout.medium})
                }
        }}),

        editProduct: builder.mutation<Product, {productEdit: EditProduct, onSuccess?: any}>({
            query: (data) => ({
                url: `/product/${data.productEdit.id}`,
                method: "PUT",
                body: data.productEdit
            }),
            invalidatesTags: ["ProductList", "Product"],
        
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                }catch{

                }
            }
        }),

        updateProductStatus: builder.mutation<void, {id: string, value: string, onSuccess?: any}>({
            query: (data) => ({
                url:`/product/toggleActiveState/${data.id}?value=${data.value}`,
                method: "PUT",
                body: {id: data.id, value: data.value},
            }),  
            invalidatesTags: ["ProductList", "Product"],
            async onQueryStarted(args, {dispatch, queryFulfilled}){
                try{
                   await queryFulfilled
                   args.onSuccess();
                    if(args.value === "ACTIVATE"){
                        new Toast("Produkt er aktivert", {type: "success", autoClose: ToastTimeout.short})
                   }
                    else {
                        new Toast("Produkt er deaktivert!", {type: "success", autoClose: ToastTimeout.short})
                    }
                
                }catch{
                    new Toast("Produktet ble ikke endret. ", {type: "error", autoClose: ToastTimeout.short})
                }
            }
        }),

        importUsers: builder.mutation<any, {form: FormData, onSuccess?: any, onError?: any}>({
            query: (data) => ({
                url: '/users/import-csv',
                method: 'POST',
                body: data.form,
              }),
              invalidatesTags: ["UserList"],
              
              async onQueryStarted(args, {dispatch, queryFulfilled}){
                try {
                    const {data} = await queryFulfilled
                    console.log(data)
                    args.onSuccess();
                    new Toast("Importering var velykket", {type: "success", autoClose: ToastTimeout.medium})
                }catch (error) {
                    console.log({error})
                    args.onError();
            
                    new Toast("Importering feilet",{type: "error", autoClose: ToastTimeout.medium})
                  
                }
              }
       
        }),

        addUserMembership: builder.mutation<void, {userId: string[], accountId: string}>({
            query: (data) => ({
                url:`/users/add-membership/?accountId=${data.accountId}`,
                method: 'PUT',
                body:  data.userId,
              }),
              invalidatesTags: ["UserList"]
        }),

        getLanguages: builder.query<Language[], void>({
            query: () => `/language`
        })
    })
})

/* Custom hooks: used for fetching/posting/edits in components. Standard naming convention: use + name of endpoint + type(query/mutation) */
export const {useGetAllCustomersQuery, useGetCustomerQuery, 
    useAddCustomerMutation, useEditCustomerMutation, useSearchCustomerQuery,useLazySearchCustomerQuery,
    useGetAllCompanyTypesQuery, useGetSearchResultsQuery, 
    useLazyGetSearchResultsQuery, useGetAccountQuery, useLazyGetAccountsByCustomerIdQuery,
    useLazyGetAccountQuery, useAddAccountMutation, useEditAccountMutation,
    useGetUsersByCustomerIdQuery, useLazyGetUsersByCustomerIdQuery,
    useGetUserByIdQuery, useEditUserMutation,  useAddUserMutation, 
    useUpdateUserStatusMutation, useTransferUsersMutation, useGetAccountPlanListQuery, useAddAccountPlanMutation, useGetAccountPlanByIdQuery,
    useEditAccountPlanMutation, useUpdateAccountPlanStatusMutation, useGetAccountModelQuery,
    useUpdateAccountStatusMutation, useGetAllProductsQuery, useGetProductsNotInAccountPlanQuery, useLazyGetProductsNotInAccountPlanQuery,
    useGetProductsForAccountEditQuery, useGetProductTypesQuery, useGetProductQuery, 
    useEditProductMutation, useAddProductMutation, useUpdateProductStatusMutation, useImportUsersMutation, 
    useAddUserMembershipMutation, useGetLanguagesQuery } = api;