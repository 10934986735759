import styled from "styled-components/macro";
import { CustomerSearchResult, TransferUsers, User } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import { api } from "../../../api/ApiSlice";
import { useState, useEffect } from "react";
import CustomerSearch from "../../search/customerSearch/customerSearch";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import ExpandableDetailsBox from "../../misc/ExpandableDetailBox";
import { CloseButton, SaveButton } from "../Form.styles";

type Props = {
  users: User[];
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
  currentCustomerId: string;
  currentCustomerName: string;
  handleUpdateSelection: (usersChecked: string[]) => void;
};

enum FormProperties {
  userIds = "userIds",
  oldCustomerId = "oldCustomerId",
  newCustomerId = "newCustomerId",
  newAccountId = "newAccountId",
}

const TransferUsersForm = ({
  users,
  handleCloseModal,
  currentCustomerId,
  currentCustomerName,
  handleCloseModalOnSuccess,
  handleUpdateSelection,
}: Props) => {
  const [trigger, searchResults] = api.useLazySearchCustomerQuery();
  const [getAccounts, accountsResults] =
    api.useLazyGetAccountsByCustomerIdQuery();

  const [transferUsers, { isLoading, isError }] =
    api.useTransferUsersMutation();

  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerSearchResult>();

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const [userObjects, setUserObjects] = useState<User[]>(users);

  var ids = userObjects.map((i) => i.id); //users.map((i) => i.id);

  const [usersToTransfer, setUsersToTransfer] = useState<TransferUsers>({
    [FormProperties.userIds]: userObjects.map((i) => i.id),
    [FormProperties.oldCustomerId]: currentCustomerId,
    [FormProperties.newCustomerId]: "",
    [FormProperties.newAccountId]: "",
  });

  // Validation code
  const [errors, setErrors] = useState({}) as any;
  const validationErrors = {} as any;

  const validateForm = (transfer: TransferUsers) => {
    if (transfer.userIds.length <= 0) {
      validationErrors.userIds = "Ingen brukere å overføre";
    } else if (!transfer.newCustomerId) {
      validationErrors.newCustomerId =
        "Du må velge en ny kunde å overføre brukere til.";
    } else if (!transfer.newAccountId) {
      validationErrors.newAccountId = "Du må velge ny konto.";
    }

    setErrors(validationErrors);
  };

  const handleSelectedCustomer = (customer: CustomerSearchResult) => {
    setSelectedCustomer(customer);
    setUsersToTransfer({ ...usersToTransfer, newCustomerId: customer.id });

    // Update to remove validation error if any is set when customerId is selected.
    if (errors.newCustomerId) {
      errors.newCustomerId = "";
    }
  };

  const handleSelectedAccount = (accountId: string, accountName: string) => {
    setUsersToTransfer({ ...usersToTransfer, newAccountId: accountId });
    setSelectedAccountName(accountName);

    // Update to remove validation error if any is set when accountId is selected.
    if (errors.newAccountId) {
      errors.newAccountId = "";
    }
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const handleClickTransfer = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(usersToTransfer);

    if (Object.keys(validationErrors).length === 0) {
      transferUsers({ usersToTransfer, onSuccess });

      // selection in the user table is updated if
      // any users orgianlly checked are removed from transfer list
      handleUpdateSelection(usersToTransfer.userIds);
    }
  };

  // Remove users selected for transfer inside the modal
  const handleRemoveUserToTransfer = (
    userId: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    const updatedUsers = userObjects.filter((user) => user.id !== userId);
    setUserObjects(updatedUsers);

    setUsersToTransfer({
      ...usersToTransfer,
      userIds: updatedUsers.map((i) => i.id),
    });
  };

  // Fetching account list for the new customer selected to transfer users to.
  useEffect(() => {
    const handleGetAccounts = (id: string) => {
      getAccounts(id);
    };

    if (selectedCustomer) {
      handleGetAccounts(selectedCustomer.id);
    }
  }, [selectedCustomer, getAccounts]);

  return (
    <Form onSubmit={handleClickTransfer}>
      <ListWrapper>
        <Ul>
          <Li>
            <ExpandableDetailsBox
              handleRemoveUserToTransfer={handleRemoveUserToTransfer}
              userObjects={userObjects}
            />
          </Li>
          <Li>
            <DetailsWrapper>
              <LabelDetails>Fra kunde </LabelDetails>
              <P>{currentCustomerName}</P>
              <LabelDetails>Til kunde </LabelDetails>
              <P>{selectedCustomer?.name}</P>
              <LabelDetails>Til konto</LabelDetails>
              {selectedAccountName && <P>{selectedAccountName}</P>}
            </DetailsWrapper>
          </Li>

          <Li>
            <Label>Velg kunde</Label>
            {errors.newCustomerId && (
              <ErrorMessage>{errors.newCustomerId}</ErrorMessage>
            )}
            <CustomerSearch
              trigger={trigger}
              searchResults={searchResults}
              setSelectedCustomer={handleSelectedCustomer}
            />
          </Li>

          <Li>
            <Label>Velg konto</Label>
            {errors.newAccountId && (
              <ErrorMessage>{errors.newAccountId}</ErrorMessage>
            )}

            <DropDownSingleSelect
              options={accountsResults.data}
              handleSelected={(id, name) =>
                handleSelectedAccount(id, name as string)
              }
              isDisabled={accountsResults.data ? false : true}
              placeholderText="Velg konto"
            ></DropDownSingleSelect>
          </Li>
        </Ul>
      </ListWrapper>
      <ButtonWrapper>
        <CloseButton onClick={handleCloseModal}>Avbryt</CloseButton>
        <SaveButton type="submit">Flytt brukere</SaveButton>
      </ButtonWrapper>
    </Form>
  );
};

export default TransferUsersForm;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
  margin-left: 0.5rem;
`;

export const ListWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 1;
  padding: 0;
  padding-left: 2rem;

  max-width: 600px;
`;

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  margin-bottom: ${(props) => props.theme.spacingSystem[4]};
  padding: 0;
  padding-left: 0.5rem;
  margin-top: 1rem;
`;

export const P = styled.p`
  color: ${(props) => props.theme.primaryColorsSystem[6]};
  padding: 0;
  margin: 0;
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.greyColorsSystem[1]};
`;

export const TransferActionWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-direction: column;
  }
  max-width: 600px;
`;

export const SearchWrapper = styled.div``;

export const LabelDetails = styled.label`
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.primaryColorsSystem[6]};
`;
export const DetailsWrapper = styled.div`
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 100px 180px 100px 190px;
  margin-bottom: 1rem;
  margin-bottom: 1rem;

  border-radius: 0.5rem;
  padding: 1rem;

  background: rgba(62, 136, 193, 0.1);
`;

export const RemoveUserButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: #7a7a7a;

  &:hover {
    color: red;
  }
`;

export const ErrorMessage = styled.span`
  margin: 0.2rem;
  font-size: small;
  color: red;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacingSystem[7]};
  margin-right: ${(props) => props.theme.spacingSystem[4]};
`;
