import Select from "react-select";

const MultiSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderRadius: "0.5rem",
    boxShadow: 0,
    fontSize: "0.875rem",
    border: state.isFocused ? "1px solid #999999" : "1px solid #e6e6e6",

    padding: "0.5rem",

    "&:hover": {
      cursor: "pointer",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    border: "1px solid #999999",
    borderRadius: "0.5rem",
  }),
  option: (baseStyles: any, { data, isFocused, isDisabled }: any) => ({
    ...baseStyles,
    backgroundColor: isFocused ? "#e6e6e6" : "none",
    color: data.isActive ? "none" : "red",
    fontSize: "0.875rem",

    "&:hover": {
      backgroundColor: isDisabled ? "none" : "#e6e6e7",
      cursor: isDisabled ? "not-allowed" : "pointer",
    },
  }),
  multiValue: (provided: any, { data, isFocused, isDisabled }: any) => ({
    ...provided,
    margin: "0.2rem",
    backgroundColor: data.isActive ? "#E6E6E6" : "#F6B6B6",
    borderRadius: "0.5rem",
  }),
};

type Props = {
  options: any;
  defaultOptions?: any;
  handleSelected: (id: string[], name?: string[]) => void;
  placeholder?: string;
  isDisabled?: boolean;
};

const DropDownMultiSelect = ({
  options,
  defaultOptions,
  handleSelected,
  placeholder,
  isDisabled,
}: Props) => {
  var transformOptions;

  if (options) {
    transformOptions = options.map((option: any) => {
      if (option.hasOwnProperty("isnumLicensesReached")) {
        return {
          value: option.id,
          label: option.isnumLicensesReached
            ? option.name + " (Maks antall lisenser brukt)"
            : option.name && option.isActive
            ? option.name
            : option.name + " (Deaktivert)",
          isDisabled: option.isnumLicensesReached ? true : false,
          isActive: option.isActive ? true : false,
        };
      } else {
        return {
          value: option.id,
          label: option.isActive ? option.name : option.name + " (Deaktivert)",
          isActive: option.isActive ? true : false,
        };
      }
    });
  } else {
    transformOptions = null;
  }

  const defaultValues = defaultOptions
    ? transformOptions.filter(
        (option: any) =>
          defaultOptions.includes(option.value) ||
          defaultOptions.some((i: any) => i.id === option.value)
      )
    : null;

  const handleChange = (selectedOption: any) => {
    const id = selectedOption.map((item: any) => item.value);
    handleSelected(id);
  };

  return (
    <Select
      styles={MultiSelectStyles}
      defaultValue={defaultValues}
      options={transformOptions}
      onChange={handleChange}
      isMulti
      placeholder={placeholder}
      noOptionsMessage={() => "Ingen flere, + konto?"}
      isDisabled={isDisabled}
    ></Select>
  );
};

export default DropDownMultiSelect;
