import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../types";

interface DataState {
  [id: string]: User[];
}

const initialState: DataState = {};

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
});
