import React, { Component } from "react";
import NavBar from "./components/navbar/navbar";
import styled from "styled-components/macro";
import AppRoutes from "./app/AppRoutes";
import ModalProvider from "./app/modalProvider/ModalProvider";
import { MsalContext } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PageContainer = styled.div`
  max-height: calc(100vh - 10%);
`;

const ContentContainer = styled.div`
  display: flex;
  margin: auto;
  max-width: 2500px;
  /* max-height: 90vh; */
  gap: 35px;
  padding-top: 7rem;
`;

export default class App extends Component {
  static displayName = App.name;
  static contextType = MsalContext;

  render() {
    if (this.context.accounts.length > 0) {
      return (
        <>
          <PageContainer>
            <ModalProvider>
              <NavBar />
              <ContentContainer>
                <AppRoutes />
              </ContentContainer>
            </ModalProvider>
            <ToastContainer />
          </PageContainer>
        </>
      );
    } else {
      return (
        <>
          <button onClick={() => this.context.instance.loginPopup()}>
            Login
          </button>
        </>
      );
    }
  }
}
