import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersListInputParameters } from "../../types";

const initialState = {
  params: {
    isAscending: true,
    isActive: null,
    orderBy: "name",
    filter: "",
    PageNumber: 0,
    pageSize: 10,
    accountId: [],
  } as UsersListInputParameters,
};

export const FilterParamsSlice = createSlice({
  name: "filterParams",
  initialState,
  reducers: {
    update(state, action: PayloadAction<UsersListInputParameters>) {
      state.params = { ...action.payload };
    },
  },
});
