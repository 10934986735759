import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/Clear";

enum STATUS {
  ACTIVE = "isActive",
  INACTIVE = "isInactive",
  ALL = "",
}
interface FilterInputProps {
  handleFilterChange: <T>(filter: Partial<T>) => void;

  setSelectedFilter: (value: string | ((prevValue: string) => string)) => void;
  selectedFilter: string;

  setSearchFilter: (value: string | ((prevValue: string) => string)) => void;
  searchFilter: string;
}

const FilterOptions = ({
  handleFilterChange,
  setSelectedFilter,
  selectedFilter,
  setSearchFilter,
  searchFilter,
}: FilterInputProps) => {
  /* Handles changes to filter for user table status of viewing ALL, ACTIVE OR INACTIVE users */
  const handleChangeToSelectStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedFilter(e.target.value);

    if (e.target.value === STATUS.ALL) {
      handleFilterChange({ isActive: null, PageNumber: 0 });
    }
    if (e.target.value === STATUS.ACTIVE) {
      handleFilterChange({ isActive: true, PageNumber: 0 });
    }
    if (e.target.value === STATUS.INACTIVE) {
      handleFilterChange({ isActive: false, PageNumber: 0 });
    }
  };

  /* handle changes to search filter on user table  */
  const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);
  };

  const handleClickSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedFilter("");
    handleFilterChange({
      filter: searchFilter,
      PageNumber: 0,
      isActive: null,
    });
  };

  const handleEnterSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setSelectedFilter("");
      handleFilterChange({
        filter: searchFilter,
        PageNumber: 0,
        isActive: null,
      });
    }
  };

  const handleResetFilter = () => {
    // tmp variable for access to empty string to instantly update filterparams (since state is async)
    const cleanedUpSearchFilter = "";
    // Update state
    setSearchFilter(cleanedUpSearchFilter);
    // Update and change filter to re-render list
    handleFilterChange({ filter: cleanedUpSearchFilter, PageNumber: 0 });
  };

  return (
    <FilterContentWrapper>
      <FilterWrapper>
        <FilterSearchBox>
          <SearchButton>
            {" "}
            <SearchIcon
              onClick={(e) => handleClickSearch(e)}
              style={IconStyles}
            />
          </SearchButton>

          <FilterInput
            placeholder="Filtrer"
            value={searchFilter}
            onChange={handleSearchFilter}
            onKeyDown={handleEnterSearch}
          ></FilterInput>
          {searchFilter ? (
            <ResetFilterWrapper>
              <ResetButton onClick={handleResetFilter}>
                <ClearIcon style={{ fontSize: "small" }}></ClearIcon>
              </ResetButton>
            </ResetFilterWrapper>
          ) : null}
        </FilterSearchBox>
        <FilterSelector
          value={selectedFilter}
          onChange={(e) => handleChangeToSelectStatus(e)}
        >
          <FilterOption value={STATUS.ALL}>Vis Alle</FilterOption>
          <FilterOption value={STATUS.ACTIVE}>Vis Aktive</FilterOption>
          <FilterOption value={STATUS.INACTIVE}>Vis Inaktive</FilterOption>
        </FilterSelector>
      </FilterWrapper>
    </FilterContentWrapper>
  );
};

export default FilterOptions;

const FilterContentWrapper = styled.div``;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterSearchBox = styled.div`
  display: flex;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};

  margin: ${(props) => props.theme.spacingSystem[2]};
  padding: ${(props) => props.theme.spacingSystem[2]};

  width: ${(props) => props.theme.spacingSystem[12]};
`;

const FilterInput = styled.input`
  flex: 2;
  border: none;
  width: ${(props) => props.theme.spacingSystem[7]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  margin-left: ${(props) => props.theme.spacingSystem[3]};

  &:focus {
    outline: none;
  }
`;

const IconStyles = {
  fontSize: "large",
  color: "grey",
  marginRight: "0.5rem",
};

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  border-right: 1px solid ${(props) => props.theme.greyScale[1]};
  background: none;
  width: 35px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterSelector = styled.select`
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};
  margin: ${(props) => props.theme.spacingSystem[0]};
  padding: ${(props) => props.theme.spacingSystem[2]};

  font-size: ${(props) => props.theme.fontSizeSystem[0]};
  color: ${(props) => props.theme.greyColorsSystem[0]};

  &:focus {
    outline: none;
  }
`;

const FilterOption = styled.option``;

const ResetFilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResetButton = styled.button`
  display: flex;
  border: none;
  background: none;
  margin-left: ${(props) => props.theme.spacing[2]};

  &:hover {
    color: ${(props) => props.theme.primaryColorsSystem[7]};
    cursor: pointer;
  }
`;
