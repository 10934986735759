export function splitStringByThreeLetters(string: string) {

    if(!string){
        return
    }
    // Remove any existing spaces from the input string
    string = string.replace(/\s+/g, '');
  
    // Use regular expressions to split the string into groups of three letters
    var regex = /.{1,3}/g;
    var groups = string.match(regex);
    
    if(groups){
        // Join the groups with spaces in between
        var result = groups.join(' ');
        return result;
    }
    else
    {
        var emptyResult = "";
        return emptyResult;
    } 

  }


  export const splitString = (str: string, char: string) => {
    const index = str.indexOf(char);
    if (index === -1) {
      return str;
    } else {
      return str.slice(0, index);
    }
  }
