import styled from "styled-components/macro";
import {
  ButtonWrapper,
  CloseButton,
  DeactivateButton,
  ErrorMessage,
  FooterWrapper,
  InfoText,
  Input,
  Label,
  Li,
  LogDetails,
  LogDetailsWrapper,
  LogLabel,
  NoEditInputField,
  P,
  SaveButton,
} from "../Form.styles";
import { CustomerSearchResult, EditProduct, Product } from "../../../types";
import { useState } from "react";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import { api } from "../../../api/ApiSlice";
import TextFieldToSearch from "../../search/textFieldToSearch/textFieldToSearch";

type Props = {
  product: Product;
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};

enum PRODUCT_STATUS {
  activate = "ACTIVATE",
  deactivate = "DEACTIVATE",
}

enum ProductProperties {
  ID = "id",
  NAME = "name",
  DESCRIPTION = "description",
  ISACTIVE = "isActive",
  LASTCHANGE = "lastChange",
  LASTCHANGEBY = "lastChangeBy",
  PRODUCT_CATEGORY_ID = "productCategoryId",
  PRODUCT_TYPE_ID = "productTypeId",
  PRODUCT_TYPE_NAME = "productTypeName",
  PRODUCT_TYPE_LIST = "productTypeList",
  LANGUAGE_ID = "languageId",
  LANGUAGE_NAME = "languageName",
  LANGUAGE_LIST = "languageList",
  TIMESTAMP = "timestamp",
  CUSTOMER_ID = "customerId",
}

const EditProductForm = ({
  product,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: Props) => {
  const [trigger, searchResults] = api.useLazySearchCustomerQuery();

  const [updateProduct] = api.useEditProductMutation();

  const [updateStatusProduct] = api.useUpdateProductStatusMutation();

  const [errors, setErrors] = useState({}) as any;

  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerSearchResult>();

  const [editCustomerClicked, setEditCustomerClicked] =
    useState<boolean>(false);

  const initialProductState = {
    [ProductProperties.ID]: product.id,
    [ProductProperties.NAME]: product.name,
    [ProductProperties.DESCRIPTION]: product.description,
    [ProductProperties.ISACTIVE]: product.isActive,
    [ProductProperties.LASTCHANGE]: product.lastChange,
    [ProductProperties.LASTCHANGEBY]: product.lastChangeBy,
    [ProductProperties.PRODUCT_CATEGORY_ID]: product.productCategoryId,
    [ProductProperties.PRODUCT_TYPE_ID]: product.productTypeId,
    [ProductProperties.PRODUCT_TYPE_NAME]: product.productTypeName,
    [ProductProperties.LANGUAGE_ID]: product.languageId,
    [ProductProperties.LANGUAGE_NAME]: product.productTypeId,
    [ProductProperties.TIMESTAMP]: product.timestamp,
    [ProductProperties.CUSTOMER_ID]: product.customerId,
  };

  const [productEdit, setProductEdit] =
    useState<EditProduct>(initialProductState);

  /* Sorting language list */
  const languagesSorted = product.languagesList
    ? [...product.languagesList].sort((a, b) => a.sortOrder - b.sortOrder)
    : [];
  console.log("Data", languagesSorted);

  /* Action Handlers  */
  const handleInputChange = (value: string | boolean, type: string) => {
    setProductEdit({ ...productEdit, [type]: value });
    setChanged(true);
  };

  const handleSelectChangesProductType = (id: string, name?: string) => {
    console.log("Product type changes", id, name);

    if (!name) {
      return;
    }

    // If the previous product type in internal module -> customerId needs updating to DIB as owner?
    if (productEdit.productTypeName === "Internmodul") {
    }

    setProductEdit({
      ...productEdit,
      [ProductProperties.PRODUCT_TYPE_ID]: id,
      [ProductProperties.PRODUCT_TYPE_NAME]: name,
    });
  };

  const handleSelectChangesLanguage = (id: string | number, name?: string) => {
    console.log("Product type changes", id, name);

    if (name) {
      setProductEdit({
        ...productEdit,
        [ProductProperties.LANGUAGE_ID]: id === 999 ? null : id,
        [ProductProperties.LANGUAGE_NAME]: name,
      });
    }
  };

  const handleSelectedCustomer = (customer: CustomerSearchResult) => {
    console.log("customer", customer);

    setSelectedCustomer(customer);
    setEditCustomerClicked(false);
    setProductEdit({
      ...productEdit,
      [ProductProperties.CUSTOMER_ID]: customer.id,
    });

    setChanged(true);
  };

  const handleClickChangeActiveStatus = (e: React.MouseEvent) => {
    e.preventDefault();

    const newIsActive = !productEdit.isActive;

    // Update the local state
    setProductEdit({
      ...productEdit,
      [ProductProperties.ISACTIVE]: newIsActive,
    });

    const statusToSet = newIsActive
      ? PRODUCT_STATUS.activate
      : PRODUCT_STATUS.deactivate;

    updateStatusProduct({
      id: productEdit.id,
      value: statusToSet,
      onSuccess: onSuccess,
    });
  };

  const handleClickEditCustomer = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Clicked edit customer");
    setEditCustomerClicked(!editCustomerClicked);
  };
  /* Validation stuff */
  const validationErrors = {} as any;

  const validateForm = (product: EditProduct) => {
    if (!productEdit.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (!product.productTypeId) {
      validationErrors.productTypeId = "Kan ikke være uten produkt type";
    }
    setErrors(validationErrors);
  };

  /* Form submitt */
  const handleSubmitForm = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(productEdit);

    if (Object.keys(validationErrors).length === 0) {
      updateProduct({ productEdit, onSuccess });
    }
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  console.log("edits to product", productEdit);

  return (
    <Form onSubmit={handleSubmitForm}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Navn</Label>
            <Input
              value={productEdit.name}
              onChange={(e) =>
                handleInputChange(e.target.value, ProductProperties.NAME)
              }
            ></Input>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>

          {productEdit.productTypeName === "Internmodul" ? (
            <Li>
              <Label>Produkt type </Label>
              <NoEditInputField>
                <P>{productEdit.productTypeName}</P>
              </NoEditInputField>
            </Li>
          ) : (
            <Li>
              <Label>Produkt type</Label>
              <DropDownSingleSelect
                options={product.productTypesList}
                defaultOptions={productEdit.productTypeId}
                handleSelected={handleSelectChangesProductType}
              ></DropDownSingleSelect>
              {errors.productTypeId && (
                <ErrorMessage>{errors.productTypeId}</ErrorMessage>
              )}
            </Li>
          )}
          <Li>
            <Label>Språk</Label>
            <DropDownSingleSelect
              options={languagesSorted}
              defaultOptions={productEdit.languageId}
              handleSelected={handleSelectChangesLanguage}
              placeholderText="Velg språk"
            ></DropDownSingleSelect>
          </Li>
        </Ul>
        <Ul>
          {productEdit.productTypeName === "Internmodul" && (
            <>
              <Li>
                <Label>Kunde/Eier </Label>
                <TextFieldToSearch
                  trigger={trigger}
                  searchResults={searchResults}
                  setSelectedCustomer={handleSelectedCustomer}
                  defaultValueInput="Søk på kunder"
                  handleClickEditCancel={handleClickEditCustomer}
                  editIsClicked={editCustomerClicked}
                  value={
                    selectedCustomer?.name
                      ? selectedCustomer.name
                      : product.customerName
                  }
                />
              </Li>
            </>
          )}
          <Li>
            <Label>Beskrivelse</Label>
            <InfoText
              value={productEdit.description ? productEdit.description : ""}
              onChange={(e) =>
                handleInputChange(e.target.value, ProductProperties.DESCRIPTION)
              }
            ></InfoText>
          </Li>
          <Li>
            <Label>Ekstern Id</Label>
            <NoEditInputField>
              <P>{"noe her?"}</P>
            </NoEditInputField>
          </Li>
        </Ul>
      </ListWrapper>
      <FooterWrapper>
        <LogDetailsWrapper>
          <LogLabel>Sist endret av </LogLabel>
          <LogDetails>{productEdit.lastChangeBy}</LogDetails>
          <LogLabel>Dato endret</LogLabel>
          <LogDetails>
            {productEdit.lastChange
              ? new Date(productEdit.lastChange).toLocaleString()
              : ""}
          </LogDetails>
        </LogDetailsWrapper>
        <ButtonWrapper>
          <DeactivateButton
            onClick={handleClickChangeActiveStatus}
            isActive={productEdit.isActive}
          >
            {" "}
            {productEdit.isActive ? "Deaktiver" : "Aktiver"}
          </DeactivateButton>
          <SaveButton type="submit">Lagre</SaveButton>
          <CloseButton type="button" onClick={handleCloseModal}>
            Lukk
          </CloseButton>
        </ButtonWrapper>
      </FooterWrapper>
    </Form>
  );
};

export default EditProductForm;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
`;

export const ListWrapper = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
  }

  margin-top: 1rem;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 2;
  padding: 0 2rem 0;
  max-width: 500px;
`;
