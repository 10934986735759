import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { api } from "../../api/ApiSlice";
import { CustomersListInputParameters } from "../../types";
import FilterOptions from "../filter/FilterOptions";
import Loader from "../misc/Loader";
import CustomerList from "../customers/CustomerList";
import FiltersCustomerList from "../filter/FiltersCustomerList";

export const ContentWrapperCustomer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  min-width: 400px;
  margin-left: 1rem;

  /* position: fixed; */
  /* 
  @media only screen and (max-width: 1026px) {
    display: none;
  } */
  /* background: ${(props) => props.theme.greyColorsSystem[4]};
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  box-shadow: ${(props) => props.theme.shadowSystem[1]}; */
`;

const SideBarMenu = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("isActive");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [orderByFilter, setOrderByFilter] = useState<string>("lastChange");
  const [isAscending, setIsAscending] = useState<boolean>(false);
  // Initial state for filter params
  const [filterParameters, setFilterParameters] =
    useState<CustomersListInputParameters>({
      isAscending: isAscending,
      isActive: true,
      orderBy: orderByFilter,
      filter: "",
      PageNumber: 0,
      pageSize: 10,
    });

  const navigate = useNavigate();

  // API request to fetch customers based on filterParams
  const customersData = api.useGetAllCustomersQuery(filterParameters);

  const { customerId } =
    useParams<Readonly<Partial<{ customerId?: string | undefined }>>>();

  // Handling changes to filter params
  const handleFilterChange = (
    filterParam: Partial<CustomersListInputParameters>
  ) => {
    setFilterParameters({ ...filterParameters, ...filterParam });
  };

  // Handle interaction with click or enter on customer
  const handleClickOrEnterOnCustomer = (id: string) => {
    if (!(id === customerId)) {
      navigate(`/customers/${id}`);
    }
  };

  return (
    <>
      <ContentWrapperCustomer>
        <FiltersCustomerList
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          handleFilterChange={handleFilterChange}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          orderByFilter={orderByFilter}
          setOrderByFilter={setOrderByFilter}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          pager={customersData?.data?.pager}
        />
        {(customersData.isFetching || customersData.isLoading) && <Loader />}

        {customersData.isError && "An Error Occured retrieving data"}

        {customersData.isSuccess && !customersData.isFetching && (
          <>
            <CustomerList
              pager={customersData.data.pager}
              customers={customersData.data.customers}
              handleFilterChange={handleFilterChange}
              customerIdFromUrl={customerId}
              handleClickOrEnterOnCustomer={handleClickOrEnterOnCustomer}
            />
          </>
        )}
      </ContentWrapperCustomer>
      <Outlet />
    </>
  );
};

export default SideBarMenu;
