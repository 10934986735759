import Modal from "react-modal";
import { Customer } from "../../../types";
import { useState } from "react";
import { ModalTypes, useModal } from "../../../app/modalProvider/ModalProvider";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from "../Modal.Styles";

import ModalChangesAlertDialog from "../../dialogs/ModalChangesAlert";
import AddAccountPlanForm from "../../forms/systemSettings/AccountPlanAddForm";
import { api } from "../../../api/ApiSlice";
import Loader from "../../misc/Loader";

//Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "770px",

    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

interface Props {
  customer: Customer;
}

const AccountPlanAddModal = ({ customer }: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  /* API */
  const languagesData = api.useGetLanguagesQuery();
  const productsData = api.useGetAllProductsQuery();

  /* Modal methods */
  const { closeModal } = useModal();

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.ADD_ACCOUNTPLAN_MODAL);
    }
  };

  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.ADD_ACCOUNTPLAN_MODAL);
  };

  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.ADD_ACCOUNTPLAN_MODAL);
  };

  return (
    <>
      <Modal
        isOpen
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <Title>Opprett ny produktpakke </Title>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>
          {productsData.isLoading && languagesData.isLoading && <Loader />}
          {productsData.isSuccess && languagesData.isSuccess && (
            <AddAccountPlanForm
              products={productsData.data}
              languages={languagesData.data}
              setChanged={setChanged}
              handleCloseModal={handleCloseModal}
              handleCloseModalOnSuccess={handleCloseModalOnSuccess}
            />
          )}
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default AccountPlanAddModal;
