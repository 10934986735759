import { Route, Routes, useParams } from "react-router-dom";
import MainContentView from "../components/main/MainContentView";
import SideBarMenu from "../components/menus/SideBarMenu";
import SettingsOverview from "../components/settings/SettingsOverview";

// To enable the key option to remount component whenever customer Id param in url changes..
const CustomersViewWrapperForKey = () => {
  const { customerId } = useParams();
  return <MainContentView key={customerId} />;
};
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SideBarMenu />}>
        <Route
          path="customers/:customerId/"
          element={<CustomersViewWrapperForKey />}
        />
      </Route>
      <Route
        path="/logs"
        element={
          <>
            <div>
              <h1>Logs here</h1>
            </div>
          </>
        }
      />
      <Route
        path="/settings"
        element={
          <>
            <SettingsOverview />
          </>
        }
      />

      {/* <Route
        path="expand"
        element={
          <>
            <ExpandableDetailsBox />
          </>
        }
      /> */}
      {/* If path not found: */}
      <Route path="*" element={<h1>Not found component here</h1>} />
    </Routes>
  );
};

export default AppRoutes;
