import styled from "styled-components/macro";
import { CustomerSearchResult } from "../../../types";

type Props = {
  results: CustomerSearchResult[];
  handleOnEnterPress: (customer: CustomerSearchResult) => void;
  handleOnClick: (customer: CustomerSearchResult) => void;
};

const CustomerSearchResults = ({
  results,
  handleOnEnterPress,
  handleOnClick,
}: Props) => {
  const handleEnterPress = (e: React.KeyboardEvent, customer: any) => {
    if (e.key === "Enter") {
      handleOnEnterPress(customer);
    }
  };

  const handleClick = (customer: any) => {
    handleOnClick(customer);
  };

  return (
    <SearchListContainer>
      {!results.length ? (
        "No Data"
      ) : (
        <ListWrapper>
          {results.map((result, index) => (
            <Li
              key={result.id}
              onClick={() => handleClick(result)}
              onKeyDown={(e) => handleEnterPress(e, result)}
              tabIndex={0}
            >
              <P>{result.name}</P>
              <P>{result.isActive ? "Aktiv" : "Inaktiv"}</P>
            </Li>
          ))}
        </ListWrapper>
      )}
    </SearchListContainer>
  );
};

export default CustomerSearchResults;

export const SearchListContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.secondaryColors[2]};
  z-index: 1;
  min-height: auto;
  width: 500px;
  min-width: 300px;

  margin-top: ${(props) => props.theme.spacing[0]};

  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
  border-radius: ${(props) => props.theme.borderRadius[1]};
`;

export const ListWrapper = styled.ul`
  list-style-type: none;

  padding: 0.2rem;

  margin-top: ${(props) => props.theme.spacing[0]};

  margin-bottom: ${(props) => props.theme.spacing[1]};

  height: auto;
  max-height: 40vh;

  overflow-x: auto;

  border-radius: ${(props) => props.theme.borderRadius[1]};
`;

export const Li = styled.li`
  display: flex;
  justify-content: space-between;

  padding-top: 0.1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.greyScale[0]};
  }
`;

export const P = styled.p`
  padding: 0;
  margin: 0.2rem;
`;
