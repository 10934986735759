import styled from "styled-components";

const LoaderSpinner = styled.div`
  border: 10px solid #f6f4f4;
  border-top: 10px solid #2b5e84;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  /* margin: 1rem; */
`;

type LoaderInputProps = {
  width: number;
  heigth: number;
};
const Loader = ({ width, heigth }: Partial<LoaderInputProps>) => {
  return (
    <LoaderWrapper>
      <LoaderSpinner style={{ width: width, height: heigth }}></LoaderSpinner>
    </LoaderWrapper>
  );
};

export default Loader;
