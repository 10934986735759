import { PagerInfo, User, UsersListInputParameters } from "../../../types";
import Paging from "../../filter/Paging";
import {
  TableWrapper,
  Thead,
  Row,
  Column,
  TableDataCell,
  Tbody,
  Button,
  CheckboxCell,
  ActivateButton,
  Table,
  DeactivateButton,
  P,
} from "./UsersTable.styles";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "../../misc/checkbox/Checkbox";

interface UserTableProps {
  users: User[];
  handleSelection: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  selectedUserIds: string[];
  handleAllSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pager: PagerInfo;
  handleFilterChange: (filter: Partial<UsersListInputParameters>) => void;
  selected: string[];
  handleClickUser: (userId: string) => void;
  handleChangeActiveStatus: (e: React.MouseEvent, userId: string) => void;
  handleColumnClick: (columnId: string) => void;
  isAscending: boolean;
  columnSorted: string;
}

const columnConfiguration = [
  { name: "Navn", id: "name" },
  { name: "Konto", id: "account" },
  { name: "E-post", id: "email" },
  { name: "Sist Pålogget", id: "lastLogOn" },
  { name: "Sist Endret", id: "lastChange" },
  { name: "Valg", id: "option" },
];

const UsersTable = ({
  users,
  handleSelection: handleUserSelection,
  selectedUserIds,
  handleAllSelection: handleAllUsersSelection,
  pager,
  handleFilterChange,
  selected,
  handleClickUser,
  handleChangeActiveStatus,
  handleColumnClick,
  isAscending,
  columnSorted,
}: UserTableProps) => {
  //For paging the userlist
  const handleClickPrevPage = () => {
    var page = pager.CurrentPage;

    if (page <= 0) {
    } else {
      page--;
      handleFilterChange({ PageNumber: page });
    }
  };

  const handleClickNextPage = () => {
    var page = pager.CurrentPage;
    if (page >= pager.TotalPages) {
    } else {
      page++;
      handleFilterChange({ PageNumber: page });
    }
  };

  /* To track selected users from the table */
  const userIds = users.map((user) => user.id);
  const allChecked = userIds.reduce<boolean>((accumulator, current) => {
    if (!accumulator) {
      return accumulator;
    }
    if (selectedUserIds.indexOf(current) === -1) {
      return false;
    }

    return true;
  }, true);

  const handleClickSendLink = (e: React.MouseEvent, userId: string) => {
    e.stopPropagation();
  };

  return (
    <TableWrapper>
      <Table>
        <Thead>
          <Row isHeader={true} isActive={false}>
            <CheckboxCell>
              <Checkbox
                checked={allChecked}
                handleChange={handleAllUsersSelection}
              />
            </CheckboxCell>
            {columnConfiguration.map((col) => (
              <Column
                width={"auto"}
                key={col.id}
                id={col.id}
                colId={columnSorted}
                isAscending={isAscending}
                onClick={() => handleColumnClick(col.id)}
              >
                {col.name}
              </Column>
            ))}
          </Row>
        </Thead>

        <Tbody>
          {users.map((user) => (
            <Row
              key={user.id}
              isHeader={false}
              isActive={user.isActive}
              onClick={() => handleClickUser(user.id)}
            >
              <CheckboxCell
                style={{ verticalAlign: "middle" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox
                  checked={selected.indexOf(user.id) !== -1}
                  handleChange={(e) => handleUserSelection(e, user.id)}
                />
              </CheckboxCell>

              <TableDataCell isClickable={true} width={"150px"}>
                {user.name}
              </TableDataCell>

              <TableDataCell isClickable={true} width={"150px"}>
                {user.accounts.map((a) => (
                  <P isActive={a.isActive} key={a.id}>
                    {a.name},
                  </P>
                ))}
              </TableDataCell>

              <TableDataCell width={"150px"}>{user.email}</TableDataCell>
              <TableDataCell width={"130px"}>{0}</TableDataCell>
              <TableDataCell width={"130px"}>
                {user.lastChange
                  ? new Date(user.lastChange).toLocaleDateString()
                  : "-"}
              </TableDataCell>
              <TableDataCell width={"130px"}>
                <Button onClick={() => handleClickUser(user.id)}>
                  <EditIcon style={{ fontSize: "large" }} />
                </Button>
                <Button onClick={(e) => handleClickSendLink(e, user.id)}>
                  <EmailIcon style={{ fontSize: "large" }} />
                </Button>
                {user.isActive ? (
                  <DeactivateButton
                    onClick={(e) => handleChangeActiveStatus(e, user.id)}
                  >
                    <CloseIcon style={{ fontSize: "large" }} />
                  </DeactivateButton>
                ) : (
                  <ActivateButton
                    onClick={(e) => handleChangeActiveStatus(e, user.id)}
                  >
                    {"Aktiver"}
                  </ActivateButton>
                )}
              </TableDataCell>
            </Row>
          ))}
        </Tbody>
      </Table>
      <Paging
        currentPage={pager.CurrentPage}
        totalPages={pager.TotalPages}
        handleClickOnNextPage={handleClickNextPage}
        handleClickOnPrevPage={handleClickPrevPage}
      />
    </TableWrapper>
  );
};

export default UsersTable;
