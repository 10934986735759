import Select from "react-select";

const SingleSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    border: state.isFocused ? "1px solid #999999" : "1px solid #e6e6e6",
    boxShadow: "none",
    borderRadius: "0.5rem",
    height: "3rem",
    fontSize: "0.875rem",
    padding: "0.1rem",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    border: "1px solid #999999",
    borderRadius: "0.5rem",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? "#e6e6e6" : "none",
    color: "black",
    fontSize: "0.875rem",

    "&:hover": {
      backgroundColor: "#e6e6e7",
      cursor: "pointer",
    },
  }),
};
type Props = {
  options: any;
  defaultOptions?: any;
  placeholderText?: string;
  handleSelected: (id: string, name?: string) => void;
  isDisabled?: boolean;
};
const DropDownSingleSelect = ({
  options,
  defaultOptions,
  placeholderText,
  handleSelected,
  isDisabled,
}: Props) => {
  var transformOptions;
  if (options) {
    transformOptions = options.map((option: any) => {
      return { value: option.id, label: option.name };
    });
  } else {
    transformOptions = null;
  }

  const defaultValues = defaultOptions
    ? transformOptions.filter((option: any) => option.value === defaultOptions)
    : null;

  const handleChange = (selectedOption: any) => {
    handleSelected(selectedOption.value, selectedOption.label);
  };

  return (
    <Select
      defaultValue={defaultValues}
      options={transformOptions}
      onChange={handleChange}
      styles={SingleSelectStyles}
      placeholder={placeholderText}
      isDisabled={isDisabled}
      minMenuHeight={90}
      noOptionsMessage={() => "Ingen "}
    ></Select>
  );
};

export default DropDownSingleSelect;
