import { useEffect, useState } from "react";

export enum debounceDelay{
    standard = 1000,
}

export const useDebounce = <T>(value: T, delay = debounceDelay.standard) => {
    const [debouncedValue, setDebouncedValue] = useState(value);


    useEffect(() => {
        // If timer expires without any changes, the value is set and returned. 
        const handler = setTimeout(()=> {
            setDebouncedValue(value);
        }, delay)
        
        // Will reset the timer if value changes 
        return () => clearTimeout(handler);
    }, [value, delay])

    return debouncedValue;

}