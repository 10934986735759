import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { Customer } from "../../types";
import CustomerEditForm from "../forms/customer/CustomerEditForm";
import { useState } from "react";
import ModalChangesAlertDialog from "../dialogs/ModalChangesAlert";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import {
  CloseButton,
  HeaderWrapper,
  InactiveSymbol,
  InactiveText,
  ModalContentWrapper,
  Title,
  WrapperTitle,
  modalDefaultStyle,
} from "./Modal.Styles";

Modal.setAppElement("#root");

interface CustomerEditModalProps {
  customer: Customer;
  closeModal: (type: ModalTypes) => void;
}

const CustomerEditModal = ({
  customer,
  closeModal,
}: CustomerEditModalProps) => {
  const [changed, setChanged] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.CUSTOMER_EDIT_MODAL);
    }
  };

  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.CUSTOMER_EDIT_MODAL);
  };

  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.CUSTOMER_EDIT_MODAL);
  };

  return (
    <>
      <Modal
        isOpen
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <WrapperTitle>
              <Title>Endre kunde</Title>
              {!customer.isActive && (
                <InactiveSymbol>
                  <InactiveText>Inaktiv</InactiveText>
                </InactiveSymbol>
              )}
            </WrapperTitle>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>
          <CustomerEditForm
            customer={customer}
            setChanged={setChanged}
            handleCloseModal={handleCloseModal}
            handleCloseModalOnSuccess={handleCloseModalOnSuccess}
          />
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default CustomerEditModal;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "700px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};
