import styled from "styled-components/macro";
import Table from "../Table";
import ProductsFilterButtons from "./ProductsFilterButtons";
import { api } from "../../../api/ApiSlice";
import Loader from "../../misc/Loader";
import { useEffect, useState } from "react";
import { Product, columnConfig } from "../../../types";
import { ModalTypes, useModal } from "../../../app/modalProvider/ModalProvider";
import FilterSystemSettings from "../../filter/FilterSystemSettings";

const columnConfiguration = [
  { name: "Navn", id: "name" },
  { name: "Type", id: "type" },
  { name: "Beskrivelse", id: "description" },
  { name: "Språk", id: "language" },
  { name: "Sist endret", id: "lastChange" },
  { name: "Sist endret av", id: "lastChangeBy" },
  { name: "Aktiv", id: "isActive" },
] as columnConfig[];

enum TypeOptions {
  ALL = "Alle",
  SUBJECT_AREA = "Fagområde",
  INTERNAL_MODULE = "Internmodul",
  FUNCTIONALITY = "Funksjonalitet",
}

enum SortOptions {
  NAME = "name",
  LAST_CHANGE = "lastChange",
  LAST_CHANGE_BY = "lastChangeBy",
  LANGUAGE = "language",
  TYPE = "type",
  ISACTIVE = "isActive",
}

const ProductSettings = () => {
  /* API  */
  const productsData = api.useGetAllProductsQuery();

  /* Settings the products in state */
  const [products, setProducts] = useState<Product[] | undefined>();

  useEffect(() => {
    if (productsData.isSuccess) {
      setProducts(productsData.data);
    }
  }, [productsData.data, productsData.isSuccess]);

  /* Column Sorting on table */
  const [columnSorted, setColumnSorted] = useState<string>("");
  const [isAscending, setIsAscending] = useState<boolean>();

  const [isAscendingName, setIsAscendingName] = useState<boolean>();
  const [isAscendingLastChange, setIsAscendingLastChange] = useState<boolean>();
  const [isAscendingLastChangeBy, setIsAscendingLastChangeBy] =
    useState<boolean>();
  const [isAscendingLanguage, setIsAscendingLanguage] = useState<boolean>();
  const [isAscendingType, setIsAscendingType] = useState<boolean>();
  const [isAscendingIsActive, setIsAscendingIsActive] = useState<boolean>();

  /*  Handlers && Filtering and searches  */
  const [typeFilter, setTypeFilter] = useState<string>(TypeOptions.ALL);

  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterSearch = (query: string, reset: boolean) => {
    if (reset) {
      setProducts(productsData.data);
      console.log(products);
    } else {
      /* Incase filtering on product type is set,
       * reset view and filter on the orginal data */
      setTypeFilter(TypeOptions.ALL);
      const filteredData = productsData?.data?.filter(
        (product) =>
          product.name.toLowerCase().includes(query.toLowerCase()) ||
          product.productTypeName.toLowerCase().includes(query.toLowerCase()) ||
          product.lastChangeBy.toLowerCase().includes(query.toLowerCase()) ||
          product.languageName?.toLowerCase().includes(query.toLowerCase())
      );

      setProducts(filteredData);
      setSearchQuery(query);
    }
  };

  const handleTypeFilterChange = (type: string) => {
    var filteredProducts;
    switch (type) {
      case TypeOptions.ALL:
        setProducts(productsData.data);
        setTypeFilter(TypeOptions.ALL);
        break;
      case TypeOptions.SUBJECT_AREA:
        filteredProducts = productsData?.data?.filter(
          (p) => p.productTypeName === TypeOptions.SUBJECT_AREA
        );

        setProducts(filteredProducts);
        setTypeFilter(TypeOptions.SUBJECT_AREA);
        break;
      case TypeOptions.INTERNAL_MODULE:
        filteredProducts = productsData?.data?.filter(
          (p) => p.productTypeName === TypeOptions.INTERNAL_MODULE
        );

        setProducts(filteredProducts);
        setTypeFilter(TypeOptions.INTERNAL_MODULE);
        break;

      case TypeOptions.FUNCTIONALITY:
        filteredProducts = productsData?.data?.filter(
          (p) => p.productTypeName === TypeOptions.FUNCTIONALITY
        );

        setProducts(filteredProducts);
        setTypeFilter(TypeOptions.FUNCTIONALITY);
        break;
      default:
        setProducts(productsData.data);
        break;
    }
  };

  const handleSortColumn = (columnId: string) => {
    if (!products) return;

    switch (columnId) {
      case SortOptions.NAME: {
        const data = [...products] as Product[];

        const sorted = data.sort((a, b) => {
          return isAscendingName
            ? a.name > b.name
              ? 1
              : -1
            : b.name > a.name
            ? 1
            : -1;
        });
        setProducts(sorted);
        setIsAscendingName(!isAscendingName);
        setIsAscending(!isAscendingName);
        setColumnSorted(SortOptions.NAME);

        break;
      }
      case SortOptions.LAST_CHANGE: {
        const data = [...products] as Product[];

        const sorted = data.sort((a, b) => {
          var date_a = a.lastChange?.split("T")[0];
          var date_b = b.lastChange?.split("T")[0];

          return isAscendingLastChange
            ? Date.parse(date_a) - Date.parse(date_b)
            : Date.parse(date_b) - Date.parse(date_a);
        });

        setProducts(sorted);
        setIsAscendingLastChange(!isAscendingLastChange);
        setIsAscending(!isAscendingLastChange);
        setColumnSorted(SortOptions.LAST_CHANGE);

        break;
      }
      case SortOptions.LAST_CHANGE_BY: {
        const data = [...products] as Product[];

        const sorted = data.sort((a, b) => {
          return isAscendingLastChangeBy
            ? a.lastChangeBy > b.lastChangeBy
              ? 1
              : -1
            : b.lastChangeBy > a.lastChangeBy
            ? 1
            : -1;
        });

        setProducts(sorted);
        setIsAscendingLastChangeBy(!isAscendingLastChangeBy);
        setIsAscending(!isAscendingLastChangeBy);
        setColumnSorted(SortOptions.LAST_CHANGE_BY);

        break;
      }
      case SortOptions.LANGUAGE: {
        const data = [...products] as Product[];

        const sorted = data.sort((a, b) => {
          return isAscendingLanguage
            ? a.sortOrderLanguage - b.sortOrderLanguage
            : b.sortOrderLanguage - a.sortOrderLanguage;
        });

        setProducts(sorted);
        setIsAscendingLanguage(!isAscendingLanguage);
        setIsAscending(!isAscendingLanguage);
        setColumnSorted(SortOptions.LANGUAGE);

        break;
      }
      case SortOptions.TYPE: {
        const data = [...products] as Product[];

        const sorted = data.sort((a, b) => {
          return isAscendingType
            ? a.productTypeName > b.productTypeName
              ? 1
              : -1
            : b.productTypeName > a.productTypeName
            ? 1
            : -1;
        });

        setProducts(sorted);
        setIsAscendingType(!isAscendingType);
        setIsAscending(!isAscendingType);
        setColumnSorted(SortOptions.TYPE);

        break;
      }
      // case SortOptions.ISACTIVE: {
      //   const data = [...products] as Product[];

      //   const sorted = data.sort((a, b) => {
      //     return isAscendingType
      //       ? a.isActive > b.isActive
      //         ? 1
      //         : -1
      //       : b.isActive > a.isActive
      //       ? 1
      //       : -1;
      //   });

      //   setProducts(sorted);
      //   setIsAscendingIsActive(!isAscendingIsActive);
      //   setIsAscending(!isAscendingIsActive);
      //   setColumnSorted(SortOptions.ISACTIVE);

      //   break;
      // }
      default:
        console.log("no match");
        break;
    }
  };

  /* Modal functionality and methods  */
  const { openModal, closeModal } = useModal();

  const handleEditProduct = (id: string) => {
    openModal(ModalTypes.EDIT_PRODUCT_MODAL, {
      id: id,
      closeModal,
    });
  };

  const handleAddNewProduct = () => {
    openModal(ModalTypes.ADD_PRODUCT_MODAL, {
      closeModal,
    });
  };

  return (
    <ProductsWrapper>
      <TableActionWrapper>
        <Button onClick={handleAddNewProduct}>Produkt</Button>
      </TableActionWrapper>

      {(productsData.isFetching || productsData.isLoading) && <Loader />}
      {productsData.data && products && (
        <ProductsTableWrapper>
          <ProductsFilterWrapper>
            <ProductsFilterButtons
              typeOptions={TypeOptions}
              handleTypeFilterChange={handleTypeFilterChange}
              typeFilter={typeFilter}
            />
            <FilterSystemSettings
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleSearch={handleFilterSearch}
            />
          </ProductsFilterWrapper>
          <Table
            columnConfig={columnConfiguration}
            tableContent={products}
            handleSortColumn={handleSortColumn}
            columnSorted={columnSorted}
            isAscending={isAscending}
            handleEdit={handleEditProduct}
          />
        </ProductsTableWrapper>
      )}
    </ProductsWrapper>
  );
};

export default ProductSettings;

const ProductsWrapper = styled.div``;

const ProductsFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacingSystem[5]};
`;

const ProductsTableWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing[4]};
`;

const TableActionWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacingSystem[8]};
  margin-top: ${(props) => props.theme.spacingSystem[7]};
`;

const Button = styled.button`
  margin-right: ${(props) => props.theme.spacingSystem[5]};

  border: 0;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  box-shadow: ${(props) => props.theme.shadowSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: hsl(0, 0%, 20%);

  padding: ${(props) => props.theme.spacingSystem[5]};
  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  &:before {
    content: url("svg/add-2.svg");
    position: relative;
    top: 2px;
    bottom: 0;
    right: ${(props) => props.theme.spacingSystem[2]};
  }

  &:hover {
    background-color: ${(props) => props.theme.greyColorsSystem[3]};
  }
`;
