import styled from "styled-components/macro";

export const SearchListContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.greyColorsSystem[5]};
  z-index: 1;
  min-height: auto;

  max-width: 500px;
  min-width: 500px;

  margin-top: ${(props) => props.theme.spacingSystem[1]};

  box-shadow: ${(props) => props.theme.shadowSystem[3]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
`;

export const ListWrapper = styled.ul`
  list-style-type: none;
  margin-top: ${(props) => props.theme.spacingSystem[2]};
  padding: ${(props) => props.theme.spacingSystem[1]};

  height: auto;
  max-height: 80vh;
  overflow-x: auto;
  

  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
`;

export const WrapperCustomer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  padding-left:  ${(props) => props.theme.spacingSystem[2]};
  padding-right:  ${(props) => props.theme.spacingSystem[4]};

  border-bottom: 0.1px solid transparent;
  &:focus {
    outline: none;
    text-decoration: underline;
   
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
   
  }
`;

export const WrapperAccountDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-left:  ${(props) => props.theme.spacingSystem[2]};
  padding-right:  ${(props) => props.theme.spacingSystem[4]};

`;

export const WrapperUsers = styled.div`
  padding-left:  ${(props) => props.theme.spacingSystem[4]};
  padding-right:  ${(props) => props.theme.spacingSystem[4]};
  margin-top: ${(props) => props.theme.spacingSystem[1]};

`;

export const SearchResultElement = styled.li<{active: boolean}>`

  border: none;
  border-left: 2px solid transparent;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding: ${(props) => props.theme.spacingSystem[3]};

 
  margin-bottom: ${(props) => props.theme.spacingSystem[3]};
  margin-left: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[3]};

  box-shadow: ${(props) => props.theme.shadowSystem[1]};

  //background: ${(props) => props.active ? "rgba(62,136,193,0.06)" : "rgba(251, 35, 46, 0.1)"}; 
  background: ${(props) => props.active ? "rgba(122, 122,122, 0.08)" : "rgba(251, 35, 46, 0.1)"};

  &:hover {
    //background: rgba(122, 122,122, 0.2);
    /* border-left: 2px solid #808080;
    border-top-left-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem; */
  
  }
`;

export const CustomerResult = styled.p<{ fontWeight: string; isActive: boolean }>`
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => (props.isActive ? props.theme.primaryColorsSystem[0] : props.theme.primaryColorsSystem[7])};
  margin: 0;

`;

export const AccountResult = styled.p<{isActive: boolean }>`
  margin: 0;
  color: ${(props) => (props.isActive ? props.theme.primaryColorsSystem[0] : props.theme.primaryColorsSystem[7])};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
  margin-top: ${(props) => props.theme.spacingSystem[1]};


`;

export const UserResult = styled.p<{isActive: boolean }>`
  margin: 0;
  color: ${(props) => (props.isActive ? props.theme.primaryColorsSystem[0] : props.theme.primaryColorsSystem[7])};
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  margin-top: ${(props) => props.theme.spacingSystem[1]};
  margin-bottom: ${(props) => props.theme.spacingSystem[3]};

  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const IconWrapperNoData = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${(props) => props.theme.spacingSystem[2]};
    padding-bottom: ${(props) => props.theme.spacingSystem[2]};
    margin-left: auto;
    margin-right: auto;
`; 

export const NoDataMessage = styled.p`
    margin-left: auto;
    margin-right: auto;
`;