import styled from "styled-components";

const Suppliers = () => {
  return (
    <Wrapper>
      <img src="svg/abstract-character-50.svg" alt="avatar" />
      <P>In progress..</P>
    </Wrapper>
  );
};

export default Suppliers;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
`;

const P = styled.p``;
