import styled from "styled-components/macro";

type Props = {
  type: string;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

const RadioSwitch = ({ type, handleChange }: Props) => {
  return (
    <>
      <SegmentButtons>
        <LabelLeft>
          <RadioButton
            id="all"
            type="radio"
            value="all"
            name="account"
            checked={type === "all"}
            onChange={handleChange}
          />
          <WrapperLeft>
            <P>Alle</P>
          </WrapperLeft>
        </LabelLeft>
        <LabelRight>
          <RadioButton
            id="active"
            type="radio"
            value="active"
            name="account"
            checked={type === "active"}
            onChange={handleChange}
          />
          <WrapperRight>
            <P>Aktive</P>
          </WrapperRight>
        </LabelRight>
      </SegmentButtons>
    </>
  );
};

export default RadioSwitch;

const SegmentButtons = styled.div`
  display: flex;
  width: 6rem;
  height: 2.2rem;

  border: none;
`;

const RadioButton = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const LabelLeft = styled.label`
  display: flex;
  position: relative;
  color: ${(props) => props.theme.primaryColorsSystem[1]};

  &:hover {
    cursor: pointer;
  }

  & > input[type="radio"]:checked + div {
    background-color: ${(props) => props.theme.primaryColorsSystem[5]};
    color: #ffffff;
    border: 1px solid ${(props) => props.theme.primaryColorsSystem[4]};
  }
`;

const LabelRight = styled.label`
  display: flex;
  position: relative;
  color: ${(props) => props.theme.primaryColorsSystem[1]};

  &:hover {
    cursor: pointer;
  }

  & > input[type="radio"]:checked + div {
    background-color: ${(props) => props.theme.primaryColorsSystem[5]};
    color: #ffffff;
    border: 1px solid ${(props) => props.theme.primaryColorsSystem[4]};
  }
`;

const WrapperLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;

  border: 1px solid ${(props) => props.theme.greyScale[2]};
  border-right: 1px solid ${(props) => props.theme.greyScale[2]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;

  border: 1px solid ${(props) => props.theme.greyScale[2]};
  border-right: 1px solid ${(props) => props.theme.greyScale[2]};
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
`;

const P = styled.p`
  text-align: center;
  margin: 0;

  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};
`;
