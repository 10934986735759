import styled from "styled-components/macro";
import {
  ButtonWrapper,
  CloseButton,
  DeactivateButton,
  ErrorMessage,
  FooterWrapper,
  InfoText,
  Input,
  Label,
  Li,
  LogDetails,
  LogDetailsWrapper,
  LogLabel,
  SaveButton,
} from "../Form.styles";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import { api } from "../../../api/ApiSlice";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";
import { useEffect, useState } from "react";
import {
  AccountPlan,
  EditAccountPlan,
  Language,
  Product,
} from "../../../types";

enum ACCOUNTPLAN_STATUS {
  activate = "ACTIVATE",
  deactivate = "DEACTIVATE",
}
enum AccountPlanProperties {
  ID = "id",
  NAME = "name",
  DESCRIPTION = "description",
  ISACTIVE = "isActive",
  LANGUAGE_ID = "languageId",
  PRODUCT_IDS = "productIds",
  LAST_CHANGE = "lastChange",
  LAST_CHANGE_BY = "lastChangeBy",
  TIMESTAMP = "timestamp",
  PRODUCT_CHANGES = "productChanges",
}

type Props = {
  accountPlan: AccountPlan;
  languages: Language[];
  products: Product[];
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};
const EditAccountPlanForm = ({
  accountPlan,
  languages,
  products,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: Props) => {
  /* API  */
  const [updateAccountPlan] = api.useEditAccountPlanMutation();

  const [updateStatusAccountPlan] = api.useUpdateAccountPlanStatusMutation();

  /* For storing validation errors */
  const [errors, setErrors] = useState({}) as any;

  /*  Creating variables for selectors */

  /*  All products divided into categories */
  var FUNCTIONALITY_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Funksjonalitet"
  );

  var SUBJECT_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Fagområde"
  );

  var INTERNAL_MODULE_PRODUCTS = products.filter(
    (p) => p.productTypeName === "Internmodul"
  );

  /** To set the currently selected products on account plan */
  var CURRENT_SELECTED_FUNC_PRODUCTS = accountPlan.products.filter(
    (p) => p.productTypeName === "Funksjonalitet"
  );

  var CURRENT_SELECTED_SUBJ_PRODUCTS = accountPlan.products.filter(
    (p) => p.productTypeName === "Fagområde"
  );

  var CURRENT_SELECTED_INTERNAL_PRODUCTS = accountPlan.products.filter(
    (p) => p.productTypeName === "Internmodul"
  );

  /* Handling state and storing product IDs */
  const originalProductIds = accountPlan.products.map((i) => i.id);

  const [selectedFuncProducts, setSelectedFuncProducts] = useState<string[]>(
    CURRENT_SELECTED_FUNC_PRODUCTS.map((i) => i.id)
  );

  const [selectedSubjProducts, setSelectedSubjProducts] = useState<string[]>(
    CURRENT_SELECTED_SUBJ_PRODUCTS.map((i) => i.id)
  );

  const [selectedInternalProducts, setSelectedInternalProducts] = useState<
    string[]
  >(CURRENT_SELECTED_INTERNAL_PRODUCTS.map((i) => i.id));

  /* Probably a bad way to solve updating productIds, but it works :shrug:
   * Updating the editAccountPlan Object with the productIds on change in any of the category types */
  useEffect(() => {
    var newProductIds = [
      ...selectedFuncProducts,
      ...selectedInternalProducts,
      ...selectedSubjProducts,
    ];
    setEditAccountPlan({
      ...editAccountPlan,
      [AccountPlanProperties.PRODUCT_IDS]: newProductIds,
      [AccountPlanProperties.PRODUCT_CHANGES]: true,
    });
  }, [selectedFuncProducts, selectedSubjProducts, selectedInternalProducts]);

  /* AccountPlan Object */
  const [editAccountPlan, setEditAccountPlan] = useState<EditAccountPlan>({
    [AccountPlanProperties.ID]: accountPlan.id,
    [AccountPlanProperties.NAME]: accountPlan.name,
    [AccountPlanProperties.DESCRIPTION]: accountPlan.description,
    [AccountPlanProperties.ISACTIVE]: accountPlan.isActive,
    [AccountPlanProperties.LANGUAGE_ID]: accountPlan.languageId,
    [AccountPlanProperties.PRODUCT_IDS]: originalProductIds,
    [AccountPlanProperties.LAST_CHANGE]: accountPlan.lastChange,
    [AccountPlanProperties.LAST_CHANGE_BY]: accountPlan.lastChangeBy,
    [AccountPlanProperties.TIMESTAMP]: accountPlan.timestamp,
    [AccountPlanProperties.PRODUCT_CHANGES]: false,
  });

  /* Handlers */
  const handleLanguageSelection = (id: string, name?: string) => {
    setEditAccountPlan({ ...editAccountPlan, languageId: id });
    setChanged(true);
  };

  const handleInputChange = (value: string | boolean, type: string) => {
    setEditAccountPlan({ ...editAccountPlan, [type]: value });
    setChanged(true);
  };

  const handleFunctionalityProductSelection = (
    id: string[],
    name?: string[]
  ) => {
    setSelectedFuncProducts(id);
    setChanged(true);
  };

  const handleSubjectProductSelection = (id: string[], name?: string[]) => {
    setSelectedSubjProducts(id);
    setChanged(true);
  };

  const handleClickChangeActiveStatus = (e: React.MouseEvent) => {
    e.preventDefault();

    const newIsActive = !editAccountPlan.isActive;

    // Update the local state
    setEditAccountPlan({
      ...editAccountPlan,
      [AccountPlanProperties.ISACTIVE]: newIsActive,
    });

    const statusToSet = newIsActive
      ? ACCOUNTPLAN_STATUS.activate
      : ACCOUNTPLAN_STATUS.deactivate;

    updateStatusAccountPlan({
      id: editAccountPlan.id,
      value: statusToSet,
      onSuccess: onSuccess,
    });
  };
  /* validation */
  const validationErrors = {} as any;

  const validateForm = (accountPlan: EditAccountPlan) => {
    if (!accountPlan.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (accountPlan.productIds.length <= 0) {
      validationErrors.productIds = "Du må legge til produkter";
    }

    setErrors(validationErrors);
  };

  /* Submit form handling  */
  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(editAccountPlan);

    if (Object.keys(validationErrors).length === 0) {
      updateAccountPlan({ editAccountPlan, onSuccess });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Navn</Label>
            <Input
              value={editAccountPlan.name}
              onChange={(e) =>
                handleInputChange(e.target.value, AccountPlanProperties.NAME)
              }
            ></Input>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>
          <Li>
            <Label>Språk</Label>
            <DropDownSingleSelect
              defaultOptions={editAccountPlan.languageId}
              options={languages}
              handleSelected={handleLanguageSelection}
              placeholderText="Velg språk"
            ></DropDownSingleSelect>
          </Li>
        </Ul>
        <Ul>
          <Li>
            <Label>Beskrivelse</Label>
            <InfoText
              value={editAccountPlan.description}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  AccountPlanProperties.DESCRIPTION
                )
              }
            ></InfoText>
          </Li>
        </Ul>
      </ListWrapper>
      <OptionsListWrapper>
        <OptionsListUl>
          <>
            {errors.productIds && (
              <ErrorMessage>{errors.productIds}</ErrorMessage>
            )}
            <OptionsWrapper>
              <Label>Fagområder</Label>
              <DropDownMultiSelect
                defaultOptions={CURRENT_SELECTED_SUBJ_PRODUCTS}
                options={SUBJECT_PRODUCTS}
                handleSelected={handleSubjectProductSelection}
                placeholder="Velg fagområder"
              ></DropDownMultiSelect>
            </OptionsWrapper>
            <OptionsWrapper>
              <Label>Funksjonalitet</Label>
              <DropDownMultiSelect
                defaultOptions={CURRENT_SELECTED_FUNC_PRODUCTS}
                options={FUNCTIONALITY_PRODUCTS}
                handleSelected={handleFunctionalityProductSelection}
                placeholder="Velg funksjonalitet"
              ></DropDownMultiSelect>
            </OptionsWrapper>
          </>
        </OptionsListUl>
      </OptionsListWrapper>

      <FooterWrapper>
        <LogDetailsWrapper>
          <LogLabel>Sist endret av </LogLabel>
          <LogDetails>{editAccountPlan.lastChangeBy}</LogDetails>
          <LogLabel>Dato endret</LogLabel>
          <LogDetails>
            {editAccountPlan.lastChange
              ? new Date(editAccountPlan.lastChange).toLocaleString()
              : ""}
          </LogDetails>
        </LogDetailsWrapper>
        <ButtonWrapper>
          <DeactivateButton
            isActive={editAccountPlan.isActive}
            onClick={handleClickChangeActiveStatus}
          >
            {" "}
            {editAccountPlan.isActive ? "Deaktiver" : "Aktiver"}
          </DeactivateButton>
          <SaveButton type="submit">Lagre</SaveButton>
          <CloseButton type="button" onClick={handleCloseModal}>
            Lukk
          </CloseButton>
        </ButtonWrapper>
      </FooterWrapper>
    </Form>
  );
};

export default EditAccountPlanForm;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  overflow: auto;
  margin: 0.75rem;
`;

export const ListWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-flow: row wrap;
  }

  margin-top: 1rem;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 2;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 500px;
`;

export const OptionsListWrapper = styled.div`
  /* display: flex;
  flex-flow: column; */

  @media (min-width: 300px) {
    display: flex;
    flex-direction: row;
  }
`;

export const OptionsListUl = styled.ul`
  list-style-type: none;
  list-style-type: none;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  //max-width: 900px;
`;
export const OptionsWrapper = styled.li`
  /* width: 900px; */
  display: flex;
  flex-direction: column;
  min-height: 90px;
  margin-bottom: ${(props) => props.theme.spacingSystem[1]};
`;
