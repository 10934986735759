import styled from "styled-components/macro";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
  setSearchQuery: (value: string | ((prevValue: string) => string)) => void;
  searchQuery: string;
  handleSearch: (query: string, reset: boolean) => void;
};
const FilterSystemSettings = ({
  setSearchQuery,
  searchQuery,
  handleSearch,
}: Props) => {
  const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClickSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    handleSearch(searchQuery, false);
  };

  const handleResetFilter = () => {
    // tmp variable for access to empty string to instantly update filterparams (since state is async)
    const cleanedUpSearchFilter = "";
    // Update state
    setSearchQuery(cleanedUpSearchFilter);

    handleSearch(cleanedUpSearchFilter, true);
  };

  return (
    <FilterSearchWrapper>
      <FilterWrapper>
        <FilterSearchBox>
          <SearchButton onClick={(e) => handleClickSearch(e)}></SearchButton>
          <FilterInput
            placeholder="Filtrer"
            value={searchQuery}
            onChange={handleSearchFilter}
          ></FilterInput>
          {searchQuery ? (
            <ResetFilterWrapper>
              <ResetButton onClick={handleResetFilter}>
                <ClearIcon style={{ fontSize: "medium" }}></ClearIcon>
              </ResetButton>
            </ResetFilterWrapper>
          ) : null}
        </FilterSearchBox>
      </FilterWrapper>
    </FilterSearchWrapper>
  );
};

export default FilterSystemSettings;

const FilterSearchWrapper = styled.div`
  /* margin-bottom: 2rem; */
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterSearchBox = styled.div`
  display: flex;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};

  margin: ${(props) => props.theme.spacingSystem[2]};
  padding: ${(props) => props.theme.spacingSystem[1]};

  width: ${(props) => props.theme.spacingSystem[12]};
`;

const FilterInput = styled.input`
  flex: 2;
  border: none;
  width: ${(props) => props.theme.spacingSystem[7]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};

  &:focus {
    outline: none;
  }
`;

const SearchButton = styled.button`
  margin-right: ${(props) => props.theme.spacingSystem[2]};
  background: ${(props) => props.theme.greyColorsSystem[5]};
  border: 0;

  &:before {
    position: relative;
    content: url("svg/search-grey.svg");

    top: 4px;
  }

  &:hover {
    cursor: pointer;

    &:before {
      position: relative;
      content: url("svg/search-black.svg");
      top: 4px;
    }
  }
`;

const ResetFilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResetButton = styled.button`
  display: flex;
  border: 0;
  background: 0;
  color: ${(props) => props.theme.greyColorsSystem[0]};

  &:hover {
    color: ${(props) => props.theme.primaryColorsSystem[4]};
    cursor: pointer;
  }
`;
