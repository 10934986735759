import { useEffect, useState } from "react";

export const useArrowNavigation = <T,>(
  options: T[],
  onEnterPress: (selected: T) => void
): [number, (event: React.KeyboardEvent) => void, (index: number) => void] => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [options]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowDown" && selectedIndex < options.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else if (event.key === "ArrowUp" && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else if (event.key === "Tab") {
      setSelectedIndex(selectedIndex + 1);
    } else if (event.key === "Enter") {
      event.preventDefault();
      onEnterPress(options[selectedIndex]);
    }
  };

  const setIndex = (index: number) => {
    setSelectedIndex(index);
  };

  return [selectedIndex, handleKeyDown, setIndex];
};
