import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import { PagerInfo } from "../../types";
import Paging from "./Paging";

enum STATUS {
  ACTIVE = "isActive",
  INACTIVE = "isInactive",
  ALL = "",
}
interface FilterInputProps {
  handleFilterChange: <T>(filter: Partial<T>) => void;

  setSelectedFilter: (value: string | ((prevValue: string) => string)) => void;
  selectedFilter: string;

  setSearchFilter: (value: string | ((prevValue: string) => string)) => void;
  searchFilter: string;

  orderByFilter: string;
  setOrderByFilter: (value: string | ((prevValue: string) => string)) => void;

  isAscending: boolean;
  setIsAscending: (value: boolean) => void;

  pager?: PagerInfo;
}

const FilterCustomerList = ({
  handleFilterChange,
  setSelectedFilter,
  selectedFilter,
  setSearchFilter,
  searchFilter,
  orderByFilter,
  setOrderByFilter,
  isAscending,
  setIsAscending,
  pager,
}: FilterInputProps) => {
  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(e.target.value);

    if (e.target.value === STATUS.ALL) {
      handleFilterChange({ isActive: null, PageNumber: 0 });
    }
    if (e.target.value === STATUS.ACTIVE) {
      handleFilterChange({ isActive: true, PageNumber: 0 });
    }
    if (e.target.value === STATUS.INACTIVE) {
      handleFilterChange({ isActive: false, PageNumber: 0 });
    }
  };

  const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);
  };

  const handleClickSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedFilter("");
    handleFilterChange({
      filter: searchFilter,
      PageNumber: 0,
      isActive: null,
    });
  };

  const [optionsIsOpen, setOptionsIsOpen] = useState<boolean>(false);

  const handleClickFilterOptionsMenu = () => {
    if (!optionsIsOpen) {
      setOptionsIsOpen(true);
    } else {
      setOptionsIsOpen(false);
    }
  };

  const handleChangeSortOrder = () => {
    if (isAscending) {
      setIsAscending(false);
      handleFilterChange({ isAscending: false });
    } else {
      setIsAscending(true);
      handleFilterChange({ isAscending: true });
    }
  };

  const [isLastChangeOrdered, setIsLastChangeOrdered] = useState<boolean>(true);

  const handleOrderByChange = () => {
    if (isLastChangeOrdered) {
      setIsLastChangeOrdered(false);
      handleFilterChange({ orderBy: "name" });
    } else {
      setIsLastChangeOrdered(true);
      handleFilterChange({ orderBy: "lastChange" });
    }
  };

  const handleChangeOrderBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetId = e.target.id;

    handleFilterChange({ orderBy: targetId });
    setOrderByFilter(targetId);
  };

  const handleResetFilter = () => {
    // tmp variable for access to empty string to instantly update filterparams (since state is async)
    const cleanedUpSearchFilter = "";
    // Update state
    setSearchFilter(cleanedUpSearchFilter);
    // Update and change filter to re-render list
    handleFilterChange({ filter: cleanedUpSearchFilter, PageNumber: 0 });
  };

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setSelectedFilter("");
      handleFilterChange({
        filter: searchFilter,
        PageNumber: 0,
        isActive: null,
      });
    }
  };

  /* Paging */
  const handleClickNextPage = () => {
    if (!pager) {
      return;
    }
    var page = pager.CurrentPage;

    if (page >= pager.TotalPages) {
    } else {
      page++;
      handleFilterChange({ PageNumber: page });
    }
  };

  const handleClickPrevPage = () => {
    if (!pager) {
      return;
    }
    var page = pager.CurrentPage;

    if (page <= 0) {
    } else {
      page--;
      handleFilterChange({ PageNumber: page });
    }
  };

  return (
    <FilterContentWrapper>
      <FilterWrapper>
        <FilterSearchBox>
          <SearchButton onClick={(e) => handleClickSearch(e)}>
            <SearchIcon style={IconStyles} />
          </SearchButton>
          <FilterInput
            placeholder="Filtrer"
            value={searchFilter}
            onChange={handleSearchFilter}
            onKeyDown={handleEnter}
          ></FilterInput>
          {searchFilter ? (
            <ResetFilterWrapper>
              <ResetButton onClick={handleResetFilter}>
                <ClearIcon style={{ fontSize: "small" }}></ClearIcon>
              </ResetButton>
            </ResetFilterWrapper>
          ) : null}
        </FilterSearchBox>
        <FilterSelector
          value={selectedFilter}
          onChange={(e) => handleChangeSelect(e)}
        >
          <FilterOption value={STATUS.ALL}>Vis Alle</FilterOption>
          <FilterOption value={STATUS.ACTIVE}> Vis Aktive</FilterOption>
          <FilterOption value={STATUS.INACTIVE}>Vis Inaktive</FilterOption>
        </FilterSelector>
      </FilterWrapper>

      <Wrapper>
        <DetailsWrapper></DetailsWrapper>
        <SortWrapper>
          <FilterOptionToggleWrapper>
            <FilterOptionToggleButton onClick={handleOrderByChange}>
              {isLastChangeOrdered ? (
                <FilterOptionToggleText>Sist Endret</FilterOptionToggleText>
              ) : (
                <FilterOptionToggleText>Navn</FilterOptionToggleText>
              )}
            </FilterOptionToggleButton>
          </FilterOptionToggleWrapper>
          {isAscending ? (
            <SortButton onClick={handleChangeSortOrder}>
              <img
                src={"svg/arrange-list-ascending.svg"}
                alt="sorter"
                style={{ verticalAlign: "top", paddingTop: "0.1rem" }}
              ></img>
            </SortButton>
          ) : (
            <SortButton onClick={handleChangeSortOrder}>
              <img
                src={"svg/arrange-list-descending.svg"}
                alt="sorter"
                style={{ verticalAlign: "top", paddingTop: "0.3rem" }}
              ></img>
            </SortButton>
          )}
        </SortWrapper>
      </Wrapper>
    </FilterContentWrapper>
  );
};

export default FilterCustomerList;

const FilterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[3]};
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterSearchBox = styled.div`
  display: flex;
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};

  margin: ${(props) => props.theme.spacingSystem[2]};
  padding: ${(props) => props.theme.spacingSystem[2]};

  width: ${(props) => props.theme.spacingSystem[12]};
`;

const FilterInput = styled.input`
  flex: 2;
  border: none;
  width: ${(props) => props.theme.spacingSystem[7]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  margin-left: ${(props) => props.theme.spacingSystem[3]};
  &:focus {
    outline: none;
  }
`;

const IconStyles = {
  fontSize: "large",
  color: "grey",
  marginRight: "0.5rem",
};

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  border-right: 1px solid ${(props) => props.theme.greyScale[1]};
  background: none;
  width: 35px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterSelector = styled.select`
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};
  margin: ${(props) => props.theme.spacingSystem[0]};
  padding: ${(props) => props.theme.spacingSystem[2]};

  font-size: ${(props) => props.theme.fontSizeSystem[0]};
  color: ${(props) => props.theme.greyColorsSystem[0]};

  &:focus {
    outline: none;
  }
`;

const FilterOption = styled.option`
  font-family: ${(props) => props.theme.fontSizeSystem[0]};
`;

const ResetFilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResetButton = styled.button`
  display: flex;
  border: none;
  background: none;
  margin-left: ${(props) => props.theme.spacing[2]};

  &:hover {
    color: ${(props) => props.theme.primaryColorsSystem[7]};
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacingSystem[4]};
  margin-bottom: ${(props) => props.theme.spacingSystem[0]};
  margin-left: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[1]};
`;
const FilterOptionToggleWrapper = styled.div``;

const FilterOptionToggleButton = styled.button`
  border: 0;
  background: 0;
  &:hover {
    cursor: pointer;
  }
`;
const FilterOptionToggleText = styled.span`
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: ${(props) => props.theme.greyColorsSystem[1]};
  border-bottom: ${(props) => props.theme.borderSizeSystem[1]};
  margin-left: ${(props) => props.theme.spacingSystem[0]};
  margin-right: ${(props) => props.theme.spacingSystem[0]};

  &:hover {
    color: ${(props) => props.theme.greyColorsSystem[0]};
    border-bottom: ${(props) => props.theme.borderSizeSystem[3]};
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SortButton = styled.button`
  background-color: ${(props) => props.theme.greyColorsSystem[5]};
  border: 0;
`;
