import { toast, ToastContent, ToastOptions } from "react-toastify";

const defaultOptions: ToastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: false,
    autoClose: false,
    theme: "colored",
};

export enum ToastTimeout {
    short = 1000,
    medium = 2000,
    long = 3000,
}



class Toast {
    private id: string | number;

    constructor(content: ToastContent, options?: ToastOptions) {
        // If a dialog is open, get the last one opened
        const dialogornot = Array.from(
            document.querySelectorAll("dialog[open]")
        ).at(-1) as HTMLDialogElement;

        // get the containerId of the the ToastContainer, undefined if not found
        // this ensures that the toast is displayed and visible
        const containerId = dialogornot?.dataset?.containerid;

        this.id = toast(content, {
            ...defaultOptions,
            ...options,
            containerId,
        });
    }

    public destroy(delay?: number) {
        if (delay) {
            setTimeout(() => {
                toast.dismiss(this.id);
            }, delay);
        } else {
            toast.dismiss(this.id);
        }
    }

    public isActive() {
        return toast.isActive(this.id);
    }
}

export default Toast;