import { api } from "../../../api/ApiSlice";
import { Customer, NewAccount } from "../../../types";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import Loader from "../../misc/Loader";
import {
  AddAccountForm,
  Li,
  ListWrapper,
  Ul,
  Label,
  InfoText,
  ButtonWrapper,
  SaveButton,
  CloseButton,
  DateInput,
  Input,
  NoEditInputField,
  CheckboxLi,
  ErrorMessage,
  FooterWrapper,
  LogDetailsWrapper,
  LogLabel,
  LogDetails,
  UlInfo,
  P,
  FooterWrapperNoLogDetails,
} from "../Form.styles";
import { useEffect, useState } from "react";
import Toggle from "../../misc/toggles/Toggle";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";

interface Props {
  customer: Customer;
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
}

enum AccountProperties {
  Name = "name",
  IsActive = "isActive",
  AccountPlanId = "accountPlanId",
  CustomerId = "customerId",
  LanguageId = "languageId",
  Info = "info",
  LicenseStart = "licenseStart",
  LicenseEnd = "licenseEnd",
  numLicenses = "numLicenses",
  UseNumLicensesLimit = "useNumLicensesLimit",
  AccountPlans = "accountPlans",
  Languages = "languages",
  AdditionalProductIds = "additionalProductIds",
}
const AccountAddForm = ({
  customer,
  setChanged,
  handleCloseModalOnSuccess,
  handleCloseModal,
}: Props) => {
  const accountModel = api.useGetAccountModelQuery();
  const [addAccount, { isLoading, isError }] = api.useAddAccountMutation();

  const [errors, setErrors] = useState({}) as any;
  const [accountPlanSelected, setAccountPlanSelected] =
    useState<boolean>(false);

  const [newAccount, setNewAccount] = useState<NewAccount>({
    [AccountProperties.Name]: "",
    [AccountProperties.IsActive]: true,
    [AccountProperties.AccountPlanId]: "",
    [AccountProperties.CustomerId]: customer.id,
    [AccountProperties.LanguageId]: "",
    [AccountProperties.Info]: "",
    [AccountProperties.LicenseStart]: null,
    [AccountProperties.LicenseEnd]: null,
    [AccountProperties.numLicenses]: 0,
    [AccountProperties.UseNumLicensesLimit]: false,
    [AccountProperties.AdditionalProductIds]: [],
  });

  //
  const [trigger, resultProducts, lastPromiseInfo] =
    api.useLazyGetProductsNotInAccountPlanQuery();

  useEffect(() => {
    if (accountPlanSelected === true && newAccount.accountPlanId) {
      console.log(newAccount.customerId, newAccount.accountPlanId);
      trigger({
        customerId: newAccount.customerId,
        accountPlanId: newAccount.accountPlanId,
      });
    }
  }, [
    accountPlanSelected,
    newAccount.accountPlanId,
    newAccount.customerId,
    trigger,
  ]);

  const handleInputChange = (value: string | boolean, type: string) => {
    setNewAccount({ ...newAccount, [type]: value });
    setChanged(true);
  };

  // Should not be able to let the else statement kick in, as the account need to have a name
  const handleAccountPlanSelected = (accountPlanId: string, name?: string) => {
    if (name) {
      setNewAccount({
        ...newAccount,
        [AccountProperties.AccountPlanId]: accountPlanId,
        [AccountProperties.Name]: name,
      });
      setChanged(true);
      setAccountPlanSelected(true);
    } else {
      setNewAccount({
        ...newAccount,
        [AccountProperties.AccountPlanId]: accountPlanId,
      });
      setChanged(true);
      setAccountPlanSelected(true);
    }
  };

  const handleProductsAdded = (productId: string[], name?: string[]) => {
    setNewAccount({
      ...newAccount,
      [AccountProperties.AdditionalProductIds]: [...productId],
    });
  };

  const handleLanguageSelected = (id: string) => {
    setNewAccount({
      ...newAccount,
      [AccountProperties.LanguageId]: id,
    });
    setChanged(true);
  };

  const handleToggleLimitLicenses = () => {
    if (newAccount.useNumLicensesLimit) {
      setNewAccount({
        ...newAccount,
        [AccountProperties.UseNumLicensesLimit]: false,
      });
      setChanged(true);
    } else {
      setNewAccount({
        ...newAccount,
        [AccountProperties.UseNumLicensesLimit]: true,
      });
      setChanged(true);
    }
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(newAccount);

    if (Object.keys(validationErrors).length === 0) {
      addAccount({ newAccount, onSuccess });
    }
  };

  const validationErrors = {} as any;

  const validateForm = (account: NewAccount) => {
    if (!account.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (!account.accountPlanId) {
      validationErrors.accountPlanId = "Du må velge en kontoplan";
    }
    if (!account.languageId) {
      validationErrors.languageId = "Du må velge språk";
    }

    setErrors(validationErrors);
  };

  return (
    <>
      {accountModel.isSuccess ? (
        <AddAccountForm onSubmit={handleSubmit}>
          <ListWrapper>
            <Ul>
              <Li>
                <Label>Kunde</Label>
                <NoEditInputField>
                  <P>{customer.name}</P>
                </NoEditInputField>
              </Li>
              <Li>
                <Label>Kontonavn</Label>
                <NoEditInputField>
                  <P>{newAccount.name}</P>
                </NoEditInputField>
              </Li>
              <Li>
                <Label>*Produktpakke</Label>
                <DropDownSingleSelect
                  options={accountModel.data.accountPlans}
                  placeholderText={"Velg en produktpakke"}
                  handleSelected={handleAccountPlanSelected}
                ></DropDownSingleSelect>
                {errors.accountPlanId && (
                  <ErrorMessage>{errors.accountPlanId}</ErrorMessage>
                )}
              </Li>

              <Li>
                <Label>Legg til tilleggsprodukter (? info her)</Label>
                <DropDownMultiSelect
                  options={resultProducts.data}
                  handleSelected={handleProductsAdded}
                  placeholder={"- Legg til ekstra produkter -"}
                  isDisabled={
                    resultProducts.isSuccess && accountPlanSelected
                      ? false
                      : true
                  }
                ></DropDownMultiSelect>
              </Li>

              <Li>
                <Label>Kommentar </Label>
                <InfoText
                  value={newAccount.info}
                  onChange={(e) =>
                    handleInputChange(e.target.value, AccountProperties.Info)
                  }
                ></InfoText>
              </Li>
            </Ul>
            <UlInfo>
              <Li>
                <Label>*Språk</Label>
                <DropDownSingleSelect
                  options={accountModel.data.languages}
                  placeholderText={"Velg et språk"}
                  handleSelected={handleLanguageSelected}
                ></DropDownSingleSelect>
                {errors.languageId && (
                  <ErrorMessage>{errors.languageId}</ErrorMessage>
                )}
              </Li>

              <Li>
                <Label>Antall lisenser</Label>
                <Input
                  value={newAccount.numLicenses}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      AccountProperties.numLicenses
                    )
                  }
                ></Input>
              </Li>

              <Li>
                <Label>Lisens start</Label>
                <DateInput
                  type="date"
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      AccountProperties.LicenseStart
                    )
                  }
                ></DateInput>
              </Li>
              <Li>
                <Label>Lisens slutt</Label>
                <DateInput
                  type="date"
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      AccountProperties.LicenseEnd
                    )
                  }
                ></DateInput>
              </Li>
              <CheckboxLi>
                <Label>Begrens antall lisenser?</Label>
                <Toggle
                  id={"1"}
                  onToggle={handleToggleLimitLicenses}
                  toggled={newAccount.useNumLicensesLimit}
                  text={
                    newAccount.useNumLicensesLimit ? "Deaktiver" : "Aktiver"
                  }
                  color={"#3E88C1"}
                ></Toggle>
              </CheckboxLi>
            </UlInfo>
          </ListWrapper>
          <FooterWrapperNoLogDetails>
            {" "}
            <ButtonWrapper>
              <CloseButton type="button" onClick={handleCloseModal}>
                Lukk
              </CloseButton>
              <SaveButton type="submit">Lagre</SaveButton>
            </ButtonWrapper>
          </FooterWrapperNoLogDetails>
        </AddAccountForm>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AccountAddForm;
