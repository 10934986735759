import { useState } from "react";
import { api } from "../../../api/ApiSlice";
import { Customer, NewUser } from "../../../types";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";
import Loader from "../../misc/Loader";
import {
  AddUserForm,
  ButtonWrapper,
  CloseButton,
  NoEditInputField,
  ErrorMessage,
  InfoText,
  Input,
  Label,
  Li,
  ListWrapper,
  P,
  PhoneAreaCodeBox,
  PhoneFieldWrapper,
  PhoneInput,
  SaveButton,
  UlInfo,
  Ul,
  FooterWrapperNoLogDetails,
} from "../Form.styles";

interface UserAddProps {
  customer: Customer;
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
  accounts: any[];
}

enum UserProperties {
  ID = "id",
  NAME = "name",
  EMAIL = "email",
  PHONENUMBER = "phoneNumber",
  PHONEAREACODE = "phoneAreaCode",
  INFO = "info",
  ISACTIVE = "isActive",
  MEMBERSHIP = "membership",
  CUSTOMER_ID = "customerId",
}
const UserAddNewForm = ({
  customer,
  setChanged,
  handleCloseModal,
  handleCloseModalOnSuccess,
  accounts,
}: UserAddProps) => {
  const [addUser, { isLoading, isError }] = api.useAddUserMutation();

  const [errors, setErrors] = useState({}) as any;

  const [newUser, setNewUser] = useState<NewUser>({
    [UserProperties.NAME]: "",
    [UserProperties.EMAIL]: "",
    [UserProperties.PHONEAREACODE]: "",
    [UserProperties.PHONENUMBER]: "",
    [UserProperties.INFO]: "",
    [UserProperties.ISACTIVE]: true,
    [UserProperties.CUSTOMER_ID]: customer.id,
    memberships: [],
    emailConfirmed: true,
    phoneNumberConfirmed: false,
  });

  const handleInputChange = (value: string | boolean, type: string) => {
    setNewUser({ ...newUser, [type]: value });
    setChanged(true);
  };

  const handleSetAccountSelected = (id: string[]) => {
    setNewUser({ ...newUser, memberships: id });
    setChanged(true);
  };

  const validationErrors = {} as any;

  const validateForm = (user: NewUser) => {
    if (!user.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (!user.email) {
      validationErrors.email = "Mangler E-post!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      validationErrors.email = "E-post er ikke gyldig!";
    }
    if (!user.memberships.length) {
      validationErrors.memberships = "Mangler konto!";
    }
    if (user.phoneNumber) {
      if (user.phoneNumber.length > 8) {
        validationErrors.phoneNumber = "Telefonnummer er for langt";
      } else if (user.phoneNumber.length < 8) {
        validationErrors.phoneNumber = "Telefonnummer er for kort";
      }
    }
    // if (/^[0-9]+$/.test(user.phoneNumber)) {
    //   validationErrors.phoneNumber = "Telefonnummer kan kun inneholde tall!";
    // }
    setErrors(validationErrors);
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(newUser);

    if (Object.keys(validationErrors).length === 0) {
      addUser({ newUser, onSuccess });
    }
  };

  return (
    <AddUserForm onSubmit={handleSubmit}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Kunde</Label>
            <NoEditInputField>
              <P>{customer.name}</P>
            </NoEditInputField>
          </Li>
          <Li>
            <Label>Navn</Label>
            <Input
              type="text"
              value={newUser.name}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.NAME)
              }
            ></Input>

            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Li>
          <Li>
            <Label>E-post</Label>
            <Input
              value={newUser.email}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.EMAIL)
              }
            ></Input>

            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </Li>
          {/* <Li>
            <Label>Telefon:</Label>
            <PhoneFieldWrapper>
              <PhoneAreaCodeBox>+47</PhoneAreaCodeBox>
              <PhoneInput
                value={newUser.phoneNumber}
                onChange={(e) =>
                  handleInputChange(e.target.value, UserProperties.PHONENUMBER)
                }
              ></PhoneInput>
            </PhoneFieldWrapper>
            {errors.phoneNumber && (
              <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
            )}
          </Li> */}
        </Ul>
        <UlInfo>
          <Li>
            <Label>Konto</Label>
            <DropDownMultiSelect
              options={accounts}
              handleSelected={handleSetAccountSelected}
              placeholder={"- Legg til konto -"}
            />
            {errors.memberships && (
              <ErrorMessage>{errors.memberships}</ErrorMessage>
            )}
          </Li>
          <Li>
            <Label>Kommentar</Label>
            <InfoText
              value={newUser.info}
              onChange={(e) =>
                handleInputChange(e.target.value, UserProperties.INFO)
              }
            ></InfoText>
          </Li>
        </UlInfo>
      </ListWrapper>
      <FooterWrapperNoLogDetails>
        {" "}
        <ButtonWrapper>
          {isLoading && <Loader />}
          <CloseButton type="button" onClick={handleCloseModal}>
            Lukk
          </CloseButton>
          <SaveButton type="submit">Lagre</SaveButton>
        </ButtonWrapper>
      </FooterWrapperNoLogDetails>

      {isError && "en feil har oppstått"}
    </AddUserForm>
  );
};

export default UserAddNewForm;
