import { useRef, useState, useEffect } from "react";
import { api } from "../../../api/ApiSlice";
import { useDebounce } from "../../hooks/UseDebounce";
import useOnClickOutside from "../../hooks/UseOnClickOutside";
import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import CustomerSearchResults from "./customerSearchResult";
import { CustomerSearchResult } from "../../../types";

type Props = {
  trigger: (q: string, value: boolean) => void;
  searchResults: any;
  setSelectedCustomer: (value: CustomerSearchResult) => void;
  defaultValueInput?: string;
};
const CustomerSearch = ({
  trigger,
  searchResults,
  setSelectedCustomer,
  defaultValueInput,
}: Props) => {
  const [searchInputString, setSearchInputString] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const debouncedSearch = useDebounce(searchInputString);

  useEffect(() => {
    const handleSearch = () => {
      trigger(debouncedSearch, true);
      setIsOpen(true);
    };

    if (debouncedSearch.length) {
      handleSearch();
    }
  }, [debouncedSearch, trigger]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputString(e.target.value);
  };

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
    if (!componentRef.current || !e.relatedTarget) {
      return;
    }
    if (!componentRef.current?.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleOnEnterPress = (customer: CustomerSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");
      setSelectedCustomer(customer);
    }
  };

  const handleOnClick = (customer: CustomerSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");
      setSelectedCustomer(customer);
    }
  };

  // If tabbed outside of result component, the input params are not cleaned up, and results will display back when
  // Input field is back in focus
  const handleInputOnFocus: React.FocusEventHandler<HTMLDivElement> = (e) => {
    // If searchInputString is true, searchresults data should still be available.
    // Display data again, (in case user tabbed out of result view)
    if (searchInputString) {
      setIsOpen(true);
    }
  };

  function cleanUpCloseSearch() {
    setIsOpen(false);
    setSearchInputString("");
  }

  // Click outside search component closes the result list from search:
  // Performs cleanup
  useOnClickOutside(componentRef, () => cleanUpCloseSearch());

  return (
    <SearchBoxContainer ref={componentRef} onBlur={handleBlur} tabIndex={0}>
      <SearchInputWrapper>
        <SearchInput
          value={searchInputString}
          onChange={handleChangeSearchInput}
          onFocus={handleInputOnFocus}
          placeholder={defaultValueInput}
        ></SearchInput>
        <SearchButton onClick={handleClickSearch}>
          <SearchIcon style={SearchIconStyles} />
        </SearchButton>
      </SearchInputWrapper>

      {isOpen && searchResults.isSuccess && !searchResults.isFetching && (
        <CustomerSearchResults
          results={searchResults.data}
          handleOnEnterPress={handleOnEnterPress}
          handleOnClick={handleOnClick}
        />
      )}
    </SearchBoxContainer>
  );
};
const SearchIconStyles = {
  fontSize: "larger",
  color: "grey",
};

export default CustomerSearch;

export const SearchBoxContainer = styled.div`
  position: relative;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  height: 3rem;
  border-radius: 0.5rem;
  border: 0.5px solid ${(props) => props.theme.greyScale[3]};
`;

export const SearchInput = styled.input`
  height: 2.5rem;
  flex: 1;
  border: none;
  border-radius: 0.5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1rem;
  font-size: ${(props) => props.theme.fontSizeSystem[1]};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SearchButtonWrapper = styled.span`
  padding: 0.1rem;
  margin-right: ${(props) => props.theme.spacing[1]};

  &:hover {
    background-color: ${(props) => props.theme.greyScale[1]}; // #d9dcd6;
    border-radius: ${(props) => props.theme.borderRadius[4]};
    cursor: pointer;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  border-left: 0.5px solid ${(props) => props.theme.greyScale[1]};
  background: none;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
`;
