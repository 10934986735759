import { useState } from "react";
import styled, { css } from "styled-components/macro";
type Props = {
  typeOptions: any;
  handleTypeFilterChange: (type: string) => void;
  typeFilter: string;
};

const ProductsFilterButtons = ({
  handleTypeFilterChange,
  typeOptions,
  typeFilter,
}: Props) => {
  return (
    <FilterWrapper>
      <FilterButton
        id={typeOptions.ALL}
        typeFilter={typeFilter}
        onClick={() => handleTypeFilterChange(typeOptions.ALL)}
      >
        <Span>Alle</Span>
      </FilterButton>
      <FilterButton
        id={typeOptions.FUNCTIONALITY}
        typeFilter={typeFilter}
        onClick={() => handleTypeFilterChange(typeOptions.FUNCTIONALITY)}
      >
        <Span>Funksjonalitet</Span>
      </FilterButton>
      <FilterButton
        id={typeOptions.SUBJECT_AREA}
        typeFilter={typeFilter}
        onClick={() => handleTypeFilterChange(typeOptions.SUBJECT_AREA)}
      >
        <Span>Fagområder</Span>
      </FilterButton>
      <FilterButton
        id={typeOptions.INTERNAL_MODULE}
        typeFilter={typeFilter}
        onClick={() => handleTypeFilterChange(typeOptions.INTERNAL_MODULE)}
      >
        <Span>Internmoduler</Span>
      </FilterButton>
    </FilterWrapper>
  );
};

export default ProductsFilterButtons;

const FilterWrapper = styled.div`
  display: flex;
`;

const FilterButton = styled.button<{ typeFilter: string }>`
  display: flex;
  justify-content: center;

  border-radius: ${(props) => props.theme.borderRadiusSystem[2]};
  border: 0;

  background: hsl(206, 51%, 50%);
  //#FFA8AF

  // hsl(16, 93%, 85%);
  box-shadow: ${(props) => props.theme.shadowSystem[0]};

  font-size: ${(props) => props.theme.fontSizeSystem[0]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  color: hsl(206, 100%, 97%);

  //hsl(16, 100%, 30%);

  padding: ${(props) => props.theme.spacingSystem[5]};

  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  width: auto;

  margin-right: ${(props) => props.theme.spacingSystem[4]};

  ${(props) =>
    props.id === props.typeFilter
      ? css`
          &:before {
            display: inline-block;
            content: url("svg/check-white.svg");
            position: relative;
            right: 8px;
            top: 1px;
          }
          background-color: hsl(206, 51%, 44%); // hsl(16, 93%, 75%);
          color: white;
          box-shadow: ${(props) => props.theme.shadowSystem[2]};
        `
      : css``};

  &:hover {
    background: hsl(206, 51%, 44%);
    //hsl(16, 93%, 75%);
    //color: white;
  }
`;

const LanguageFilterButton = styled(FilterButton)`
  background: green;
`;
const Span = styled.span``;
