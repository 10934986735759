import Modal from "react-modal";
import { Customer } from "../../../types";
import { useState } from "react";
import { ModalTypes, useModal } from "../../../app/modalProvider/ModalProvider";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
  modalDefaultStyle,
} from "../Modal.Styles";

import ModalChangesAlertDialog from "../../dialogs/ModalChangesAlert";

import AddProductForm from "../../forms/systemSettings/ProductAddForm";

import { api } from "../../../api/ApiSlice";
import Loader from "../../misc/Loader";

//Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    height: "750px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};

const ProductAddModal = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const productTypes = api.useGetProductTypesQuery();

  const languages = api.useGetLanguagesQuery();

  const { closeModal } = useModal();

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.ADD_PRODUCT_MODAL);
    }
  };
  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.ADD_PRODUCT_MODAL);
  };
  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.ADD_PRODUCT_MODAL);
  };

  return (
    <>
      <Modal
        isOpen
        style={modalDefaultStyle}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <Title>Legg til produkt </Title>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>

          {productTypes.isLoading && <Loader />}
          {productTypes.isSuccess &&
            productTypes.data &&
            languages.isSuccess && (
              <AddProductForm
                setChanged={setChanged}
                handleCloseModal={handleCloseModal}
                handleCloseModalOnSuccess={handleCloseModalOnSuccess}
                productTypes={productTypes.data}
                languages={languages.data}
              />
            )}
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default ProductAddModal;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "680px",
    height: "750px",
    width: "calc(100vw - 50px)",
    overflow: "auto",
    padding: "0",
  },
  overlay: {
    backdropFilter: "none",
    zIndex: 1000,
  },
};
