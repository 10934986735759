import styled from "styled-components/macro";
import { CloseButton, Label, SaveButton } from "../Form.styles";
import { Account } from "../../../types";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import { useState } from "react";
import { api } from "../../../api/ApiSlice";

type Props = {
  accounts: Account[];
  customerId: string;
  setChanged: (value: boolean) => void;

  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};
const ImportUsers = ({
  accounts,
  customerId,
  handleCloseModal,
  handleCloseModalOnSuccess,
}: Props) => {
  const [file, setFile] = useState<any>();

  var importData = new FormData();

  const [importUsers, { isLoading, isError, data }] =
    api.useImportUsersMutation();

  const [selectedAccountId, setSelectedAccountId] = useState<string>();

  const handleSelectAccount = (id: string) => {
    setSelectedAccountId(id);
  };

  const handleOnSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedAccountId) {
      // addUserMembership({ userId: userIds, accountId: selectedAccountId });
      importData.append("accountId", selectedAccountId);
      importData.append("CSVFile", file);
      importData.append("customerId", customerId);

      importUsers({
        form: importData,
        onSuccess: UploadSuccess,
        onError: UploadFailed,
      });
    }
  };

  const UploadSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const UploadFailed = () => {
    handleCloseModal();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  console.log(file);
  return (
    <Form onSubmit={handleOnSubmit}>
      <ListWrapper>
        <Ul>
          <Li height="">
            <Wrapper>
              <UploadFileButton onChange={handleFileUpload}>
                <InputFile
                  type="file"
                  accept="text/csv"
                  id="input-button"
                  name="input-button"
                />
                <LabelUploadButton htmlFor="input-button">
                  <Span>Last opp CSV fil</Span>
                </LabelUploadButton>
              </UploadFileButton>
              {file && <FileName>{file.name}</FileName>}
            </Wrapper>
          </Li>

          <Li height="90px">
            {/* <Label> Velg konto brukere skal opprettes under </Label> */}
            <DropDownSingleSelect
              options={accounts}
              handleSelected={handleSelectAccount}
              placeholderText="Velg konto"
            />
          </Li>
        </Ul>
      </ListWrapper>

      <ButtonWrapper>
        <CloseButton onClick={handleCloseModal}>Avbryt</CloseButton>
        {file && selectedAccountId ? (
          <SaveButton type="submit">Importer</SaveButton>
        ) : (
          <NoSaveButton disabled>Importer</NoSaveButton>
        )}
      </ButtonWrapper>
    </Form>
  );
};

export default ImportUsers;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
  margin-left: 0.5rem;
`;

export const ListWrapper = styled.div`
  @media (min-width: 300px) {
    display: flex;
    flex-direction: row;
  }

  margin-top: 1rem;
`;

export const Ul = styled.ul`
  list-style-type: none;
  flex: 1;
  padding: 0 2rem 0;
  max-width: 600px;
`;

export const Li = styled.li<{ height: string }>`
  /* height: ${(props) => (props.height ? props.height : "50px")}; */
  max-height: 100px;
  margin-bottom: ${(props) => props.theme.spacingSystem[5]};
`;

const UploadFileButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 8.5rem;

  padding-top: ${(props) => props.theme.spacingSystem[2]};
  padding-bottom: ${(props) => props.theme.spacingSystem[2]};

  /* border: ${(props) => props.theme.borderSizeSystem[1]}; */
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  background: ${(props) => props.theme.primaryColorsSystem[4]};

  &:hover {
    cursor: pointer;

    background: ${(props) => props.theme.primaryColorsSystem[5]};
  }
`;

const InputFile = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const LabelUploadButton = styled.label``;

const Span = styled.span`
  padding: 0;
  margin: 0;

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};
  /* color: ${(props) => props.theme.primaryColorsSystem[1]}; */

  color: ${(props) => props.theme.greyColorsSystem[5]};

  &:hover {
    cursor: pointer;
  }
`;

const FileName = styled.p`
  margin: 0;
  margin-left: ${(props) => props.theme.spacingSystem[7]};
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacingSystem[4]};
  margin-right: ${(props) => props.theme.spacingSystem[4]};
`;

export const NoSaveButton = styled(SaveButton)`
  background: ${(props) => props.theme.greyColorsSystem[4]};
  color: ${(props) => props.theme.greyColorsSystem[2]};
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};
  &:hover {
    cursor: not-allowed;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
