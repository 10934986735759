import styled from "styled-components/macro";
import { Customer, CustomersListInputParameters, PagerInfo } from "../../types";
import Paging from "../filter/Paging";
import { useArrowNavigation } from "../hooks/UseArrowNavigation";
import { splitStringByThreeLetters } from "../misc/HelperFunctions";

const CustomerListContainer = styled.div`
  margin-right: ${(props) => props.theme.spacingSystem[2]};
  margin-left: ${(props) => props.theme.spacingSystem[2]};
  margin-top: ${(props) => props.theme.spacingSystem[1]};
`;

const ListWrapper = styled.ul`
  list-style-type: none;
  padding: ${(props) => props.theme.spacingSystem[2]};
  max-height: 70vh;
  overflow-x: auto;
  //border-bottom: ${(props) => props.theme.borderSizeSystem[0]};
`;

const markings = (isSelected?: boolean, isIndex?: boolean) => {
  if ((isSelected && isIndex) || isSelected) {
    return "rgba(122, 122,122, 0.1)";
  } else if (!isSelected && isIndex) {
    return "rgba(122, 122,122, 0.2)";
  }
};
const CustomerListElement = styled.li<{
  isSelected?: boolean;
  isIndex?: boolean;
}>`
  box-shadow: ${(props) => props.theme.shadowSystem[0]};
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background-color: ${(props) => markings(props.isSelected, props.isIndex)};
  margin: ${(props) => props.theme.spacingSystem[1]};
  padding: ${(props) => props.theme.spacingSystem[2]};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colorsRGBA[1]};
  }

  &:focus {
    outline: none;
  }
`;

const TitleCustomer = styled.p<{ isActive: boolean }>`
  color: ${(props) =>
    props.isActive
      ? props.theme.primaryColorsSystem[6]
      : props.theme.primaryColorsSystem[7]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
  padding: ${(props) => props.theme.spacingSystem[0]};
  margin: ${(props) => props.theme.spacingSystem[1]};
`;

const DescriptionCustomerWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacingSystem[0]};
  margin: 0;
`;

const DescriptionCustomer = styled.p`
  margin: ${(props) => props.theme.spacingSystem[1]};

  color: ${(props) => props.theme.primaryColorsSystem[6]};
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
  padding: ${(props) => props.theme.spacingSystem[0]};
`;

type CustomerListProps = {
  customers: Customer[];
  pager: PagerInfo;
  customerIdFromUrl: string | null | undefined;
  handleFilterChange: (filter: Partial<CustomersListInputParameters>) => void;
  handleClickOrEnterOnCustomer: (id: string) => void;
};

const CustomerList = ({
  customers,
  pager,
  customerIdFromUrl,
  handleFilterChange,
  handleClickOrEnterOnCustomer,
}: CustomerListProps) => {
  /* Paging */
  const handleClickNextPage = () => {
    var page = pager.CurrentPage;

    if (page >= pager.TotalPages) {
    } else {
      page++;
      handleFilterChange({ PageNumber: page });
    }
  };

  const handleClickPrevPage = () => {
    var page = pager.CurrentPage;

    if (page <= 0) {
    } else {
      page--;
      handleFilterChange({ PageNumber: page });
    }
  };

  const handleClick = (index: number, id: string) => {
    setIndex(index);
    handleClickOrEnterOnCustomer(id);
  };

  const handleEnter = (customer: Customer) => {
    handleClickOrEnterOnCustomer(customer.id);
  };

  const [selectedIndex, handleKeyDown, setIndex] = useArrowNavigation(
    customers,
    handleEnter
  );

  return (
    <CustomerListContainer>
      <ListWrapper>
        {customers.map((customer, index) => (
          <CustomerListElement
            key={customer.id}
            tabIndex={1}
            onKeyDown={handleKeyDown}
            onClick={() => handleClick(index, customer.id)}
            isIndex={index === selectedIndex}
            isSelected={customer.id === customerIdFromUrl}
          >
            <TitleCustomer isActive={customer.isActive}>
              {customer.name}
            </TitleCustomer>
            <DescriptionCustomerWrapper>
              <DescriptionCustomer>
                {customer.companyId &&
                  splitStringByThreeLetters(customer.companyId)}
              </DescriptionCustomer>
              <DescriptionCustomer>|</DescriptionCustomer>

              {customer.customerId && (
                <>
                  <DescriptionCustomer>
                    {customer.customerId}
                  </DescriptionCustomer>
                  <DescriptionCustomer>|</DescriptionCustomer>
                </>
              )}

              <DescriptionCustomer>
                {customer.companyTypeName}
              </DescriptionCustomer>
            </DescriptionCustomerWrapper>
          </CustomerListElement>
        ))}
      </ListWrapper>
      {customers.length !== 0 ? (
        <Paging
          currentPage={pager.CurrentPage}
          totalPages={pager.TotalPages}
          handleClickOnNextPage={handleClickNextPage}
          handleClickOnPrevPage={handleClickPrevPage}
        />
      ) : (
        "No data"
      )}
    </CustomerListContainer>
  );
};

export default CustomerList;
