import { useParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import {
  Customer,
  PagerInfo,
  User,
  UsersListInputParameters,
} from "../../types";
import UsersTable from "./table/UsersTable";
import FilterOptions from "../filter/FilterOptions";
import { useModal, ModalTypes } from "../../app/modalProvider/ModalProvider";
import { useContext, useState } from "react";
import { CustomerContext } from "../../app/context/Context";
import { api } from "../../api/ApiSlice";
import FilterCustomerList from "../filter/FiltersCustomerList";

enum USER_STATUS {
  activate = "ACTIVATE",
  deactivate = "DEACTIVATE",
}

enum SORT_STATE {
  LASTCHANGE = "lastChange",
  NAME = "name",
  EMAIL = "email",
  LASTLOGON = "lastLogOn",
  ACCOUNT = "account",
}

interface UserViewProps {
  users: User[];
  pager: PagerInfo;

  filterParams: UsersListInputParameters;
  handleFilterChange: <T>(filter: Partial<T>) => void;

  setSelectedFilter: (value: string | ((prevValue: string) => string)) => void;
  selectedFilter: string;

  setSearchFilter: (value: string | ((prevValue: string) => string)) => void;
  searchFilter: string;

  handleSelection: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  handleAllSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpdateSelection: (usersChecked: string[]) => void;

  selectedUserIds: string[];
}

const UsersView = ({
  users,
  pager,
  filterParams,
  handleFilterChange,
  setSelectedFilter,
  selectedFilter,
  setSearchFilter,
  searchFilter,
  handleSelection,
  handleAllSelection,
  handleUpdateSelection,
  selectedUserIds,
}: UserViewProps) => {
  const [columnSorted, setColumnSorted] = useState<string>("");
  const [updateUserStatus, { isLoading, isError }] =
    api.useUpdateUserStatusMutation();

  const { customerId } = useParams();
  const customer = useContext(CustomerContext) as Customer;

  const { openModal, closeModal } = useModal();

  const handleClickUser = (userId: string) => {
    openModal(ModalTypes.USER_EDIT_MODAL, {
      userId: userId,
      closeModal,
      customer: customer,
    });
  };

  /* Handle updating state on users when their status changes (active/inactive) */
  const handleClickChangeActiveStateOnUser = (
    e: React.MouseEvent,
    userId: string
  ) => {
    e.stopPropagation();
    const userToUpdate = users.find((u) => u.id === userId);

    // Some error needs to be added here
    if (!customerId) {
      return;
    }
    if (userToUpdate?.isActive) {
      updateUserStatus({
        id: userId,
        value: USER_STATUS.deactivate,
        cacheParams: {
          customerId: customerId,
          filterParams: filterParams,
        },
      });
    } else {
      updateUserStatus({
        id: userId,
        value: USER_STATUS.activate,
        cacheParams: {
          customerId: customerId,
          filterParams: filterParams,
        },
      });
    }
  };

  /* Handle actions to take when clicking transfer */
  const handleClickTransferUsers = () => {
    var usersToTransfer = {};
    usersToTransfer = users.filter((u) => selectedUserIds.includes(u.id));

    openModal(ModalTypes.TRANSFER_USERS_MODAL, {
      users: usersToTransfer,
      closeModal,
      currentCustomerId: customer.id,
      currentCustomerName: customer.name,
      handleUpdateSelection,
    });
  };

  /* Handle sort on column when a column is clicked */
  const handleColumnClickSort = (columnId: string) => {
    switch (columnId) {
      case SORT_STATE.LASTCHANGE:
        setColumnSorted(SORT_STATE.LASTCHANGE);

        if (filterParams.isAscending) {
          handleFilterChange({
            orderBy: SORT_STATE.LASTCHANGE,
            isAscending: false,
          });
        } else {
          handleFilterChange({
            orderBy: SORT_STATE.LASTCHANGE,
            isAscending: true,
          });
        }
        break;
      case SORT_STATE.NAME:
        setColumnSorted(SORT_STATE.NAME);
        if (filterParams.isAscending) {
          handleFilterChange({
            orderBy: SORT_STATE.NAME,
            isAscending: false,
          });
        } else {
          handleFilterChange({
            orderBy: SORT_STATE.NAME,
            isAscending: true,
          });
        }
        break;
      // case SORT_STATE.ACCOUNT:
      //   setColumnSorted(SORT_STATE.ACCOUNT);
      //   if (filterParams.isAscending) {
      //     handleFilterChange({
      //       orderBy: SORT_STATE.ACCOUNT,
      //       isAscending: false,
      //     });
      //   } else {
      //     handleFilterChange({
      //       orderBy: SORT_STATE.ACCOUNT,
      //       isAscending: true,
      //     });
      //   }
      //   break;
      case SORT_STATE.EMAIL:
        setColumnSorted(SORT_STATE.EMAIL);
        if (filterParams.isAscending) {
          handleFilterChange({
            orderBy: SORT_STATE.EMAIL,
            isAscending: false,
          });
        } else {
          handleFilterChange({
            orderBy: SORT_STATE.EMAIL,
            isAscending: true,
          });
        }
        break;

      case SORT_STATE.LASTLOGON:
        setColumnSorted(SORT_STATE.LASTLOGON);
        if (filterParams.isAscending) {
          handleFilterChange({
            orderBy: SORT_STATE.LASTLOGON,
            isAscending: false,
          });
        } else {
          handleFilterChange({
            orderBy: SORT_STATE.LASTLOGON,
            isAscending: true,
          });
        }
        break;
    }
  };

  return (
    <WrapperUsers>
      <WrapperUserListMenu>
        {selectedUserIds.length > 0 ? (
          <>
            <TextWrapper>
              <Description>
                Antall brukere valgt:{" "}
                {selectedUserIds.length > 0 ? selectedUserIds.length : "0"}
              </Description>
            </TextWrapper>
            <ButtonWrapper>
              <Button onClick={handleClickTransferUsers} isTransfer={true}>
                Flytt
              </Button>
            </ButtonWrapper>
          </>
        ) : (
          <>
            <Description>
              Viser {users.length} av totalt {customer.numUsers} brukere
            </Description>
          </>
        )}

        <FilterOptions
          handleFilterChange={handleFilterChange}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
        />
      </WrapperUserListMenu>

      <WrapperUsersList>
        <UsersTable
          key={customerId}
          users={users}
          handleSelection={handleSelection}
          selectedUserIds={selectedUserIds}
          handleAllSelection={handleAllSelection}
          pager={pager}
          handleFilterChange={handleFilterChange}
          selected={selectedUserIds}
          handleClickUser={handleClickUser}
          handleChangeActiveStatus={handleClickChangeActiveStateOnUser}
          handleColumnClick={handleColumnClickSort}
          isAscending={filterParams.isAscending}
          columnSorted={filterParams.orderBy ? filterParams.orderBy : ""}
        />
      </WrapperUsersList>
    </WrapperUsers>
  );
};

export default UsersView;

const WrapperUsers = styled.div`
  margin-top: ${(props) => props.theme.spacingSystem[7]};
  margin-right: ${(props) => props.theme.spacingSystem[3]};
  margin-bottom: ${(props) => props.theme.spacingSystem[7]};
`;

const Description = styled.p`
  margin: ${(props) => props.theme.spacingSystem[1]};
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  color: ${(props) => props.theme.greyColorsSystem[1]};
`;

const WrapperUsersList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  min-width: 800px;
`;

const WrapperUserListMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${(props) => props.theme.spacingSystem[2]};
  margin-bottom: ${(props) => props.theme.spacingSystem[4]};
  max-width: 2000px;
`;
const TextWrapper = styled.div`
  flex: 1;
`;
const ButtonWrapper = styled.div``;

const Button = styled.button<{ isTransfer: boolean }>`
  height: 2rem;
  width: 6.5rem;

  /* padding-top: ${(props) => props.theme.spacingSystem[1]};
  padding-bottom: ${(props) => props.theme.spacingSystem[1]}; */
  border: ${(props) => props.theme.borderSizeSystem[2]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.primaryColorsSystem[5]};
  color: ${(props) => props.theme.greyColorsSystem[4]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};

  margin: ${(props) => props.theme.spacingSystem[3]};

  &:hover {
    background: ${(props) => props.theme.primaryColorsSystem[5]};
  }

  ${(props) =>
    props.isTransfer &&
    css`
      &:after {
        content: url("svg/arrow-right-white.svg");
        position: relative;
        top: 0.1rem;
        left: ${(props) => props.theme.spacingSystem[2]};
        right: 0;
      }
    `};
`;
