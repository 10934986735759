import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { api } from "../../api/ApiSlice";
import Loader from "../misc/Loader";
import CustomerContentSection from "../customers/CustomerContentSection";
import { CustomerContext } from "../../app/context/Context";

const MainContentViewWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 4;
  min-width: 800px;
  /* padding-left: 430px; */
  margin-left: 1rem;

  @media only screen and (max-width: 1026px) {
    padding-left: 0;
  }
`;

const MainContentView = () => {
  const { customerId } = useParams();

  /* Get user ID if user clicked from global search -> pass down to CustomerContentSection -> userView for filtering of list */
  const location = useLocation();
  const { user } = location.state || ({} as any);
  const userFromSearch = user;

  const valueForKey = crypto.randomUUID();

  // API
  const customerData = api.useGetCustomerQuery(customerId);

  return (
    <CustomerContext.Provider value={customerData.data}>
      <MainContentViewWrapper>
        {customerData.isSuccess && !customerData.isFetching ? (
          <>
            {/*Handle view for accounts and users */}
            <CustomerContentSection
              key={userFromSearch ? userFromSearch.id + valueForKey : ""}
              userFromSearch={userFromSearch}
            />
          </>
        ) : (
          <Loader />
        )}
      </MainContentViewWrapper>
    </CustomerContext.Provider>
  );
};

export default MainContentView;
