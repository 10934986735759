import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { api } from "../../../api/ApiSlice";
import { useDebounce } from "../../hooks/UseDebounce";
import useOnClickOutside from "../../hooks/UseOnClickOutside";
import Loader from "../../misc/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import SearchResult from "./SearchResult";
import { UserSearchResult } from "../../../types";

const SearchBoxContainer = styled.div`
  position: relative;
`;

const SearchInputWrapper = styled.div`
  background-color: ${(props) => props.theme.secondaryColors[2]};
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadius[1]};

  display: flex;
  align-items: center;

  max-width: 500px;
  min-width: 100px;

  margin-right: ${(props) => props.theme.spacingSystem[2]};
`;

const SearchInput = styled.input`
  flex: 2;
  height: 45px;

  border: none;
  background-color: ${(props) => props.theme.secondaryColors[2]};
  margin-left: ${(props) => props.theme.spacingSystem[2]};

  &:focus {
    outline: none;
  }
`;

const SearchButtonWrapper = styled.span`
  padding-left: ${(props) => props.theme.spacingSystem[3]};
  padding-right: ${(props) => props.theme.spacingSystem[3]};

  border-left: ${(props) => props.theme.borderSizeSystem[1]};
  &:hover {
    cursor: pointer;
  }
`;

const LoaderWrapper = styled.span`
  padding-left: ${(props) => props.theme.spacingSystem[3]};
  padding-right: ${(props) => props.theme.spacingSystem[3]};

  border-left: ${(props) => props.theme.borderSizeSystem[1]};
  &:hover {
    cursor: pointer;
  }
`;

const Search = () => {
  const [searchInputString, setSearchInputString] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  // Manual trigger of fetch using lazy cache based on input changes
  const [trigger, searchResults] = api.useLazyGetSearchResultsQuery();

  const debouncedSearch = useDebounce(searchInputString);

  // maybe this should not be in a useEffect?
  useEffect(() => {
    const handleSearch = () => {
      trigger(debouncedSearch, true);
      setIsOpen(true);
    };

    if (debouncedSearch.length) {
      handleSearch();
    }
  }, [debouncedSearch, trigger]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputString(e.target.value);
  };

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
    if (!componentRef.current || !e.relatedTarget) {
      return;
    }
    if (!componentRef.current?.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  // If tabbed outside of result component, the input params are not cleaned up, and results will display back when
  // Input field is back in focus
  const handleInputOnFocus: React.FocusEventHandler<HTMLDivElement> = (e) => {
    // If searchInputString is true, searchresults data should still be available.
    // Display data again, (in case user tabbed out of result view)
    if (searchInputString) {
      setIsOpen(true);
    }
  };

  // sende inn både kunde og bruker id
  const handleOnEnterPress = (id: string, user?: UserSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");
      navigate(`/customers/${id}`, {
        state: { user },
      });
    }
  };

  const handleOnClick = (id: string, user?: UserSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");
      navigate(`/customers/${id}`, {
        state: { user },
      });
    }
  };

  function cleanUpCloseSearch() {
    setIsOpen(false);
    setSearchInputString("");
  }

  // Click outside search component closes the result list from search:
  // Performs cleanup
  useOnClickOutside(componentRef, () => cleanUpCloseSearch());

  return (
    <SearchBoxContainer ref={componentRef} onBlur={handleBlur} tabIndex={0}>
      <SearchInputWrapper>
        <SearchInput
          value={searchInputString}
          onChange={handleChangeSearchInput}
          onFocus={handleInputOnFocus}
        ></SearchInput>
        {searchResults.isLoading || searchResults.isFetching ? (
          <LoaderWrapper>
            <Loader heigth={15} width={15} />
          </LoaderWrapper>
        ) : (
          <SearchButtonWrapper>
            <SearchIcon style={{ fontSize: "x-large" }} />
          </SearchButtonWrapper>
        )}
      </SearchInputWrapper>

      {isOpen && searchResults.isSuccess && !searchResults.isFetching && (
        <SearchResult
          results={searchResults.data}
          handleOnEnterPress={handleOnEnterPress}
          handleOnClick={handleOnClick}
        />
      )}
    </SearchBoxContainer>
  );
};

export default Search;
