import {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  type Dispatch,
} from "react";
import AccountAddModal from "../../components/modals/AccountAddModal";
import AccountEditModal from "../../components/modals/AccountEditModal";
import CustomerAddModal from "../../components/modals/CustomerAddModal";
import CustomerEditModal from "../../components/modals/CustomerEditModal";
import UserAddModal from "../../components/modals/UserAddModal";
import UserEditModal from "../../components/modals/UserEditModal";
import TransferUsersModal from "../../components/modals/TransferUsersModal";
import ImportUsersModal from "../../components/modals/ImportUsersModal";
import ProductEditModal from "../../components/modals/systemSettingsModals/ProductEditModal";
import AccountPlanEditModal from "../../components/modals/systemSettingsModals/AccountPlanEditModal";
import ProductAddModal from "../../components/modals/systemSettingsModals/ProductAddModal";
import AccountPlanAddModal from "../../components/modals/systemSettingsModals/AccountPlanAddModal";

export enum ModalTypes {
  ACCOUNT_ADD_MODAL,
  ACCOUNT_EDIT_MODAL,
  USER_ADD_MODAL,
  USER_EDIT_MODAL,
  CUSTOMER_ADD_MODAL,
  CUSTOMER_EDIT_MODAL,
  TRANSFER_USERS_MODAL,
  IMPORT_USERS_MODAL,
  ADD_ACCOUNTPLAN_MODAL,
  EDIT_ACCOUNTPLAN_MODAL,
  ADD_PRODUCT_MODAL,
  EDIT_PRODUCT_MODAL,
}

interface Modal {
  type: ModalTypes;
  props?: any;
}

// The different action types to modals (OPEN / CLOSE)
type Action =
  | { type: "OPEN_MODAL"; payload: Modal }
  | { type: "CLOSE_MODAL"; payload: ModalTypes };

// Creating the reducer function responsible for opening of closing a modal
const modalReducer = (state: Modal[], action: Action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return [...state, action.payload];
    case "CLOSE_MODAL":
      return state.filter((modal) => modal.type !== action.payload);
  }
};
// Creating the context
const ModalContext = createContext<Dispatch<Action> | undefined>(undefined);

// The modal hook
export const useModal = () => {
  const dispatch = useContext(ModalContext);

  if (dispatch === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  const openModal = (type: ModalTypes, props?: any) => {
    dispatch({ type: "OPEN_MODAL", payload: { type, props } });
  };

  const closeModal = (type: ModalTypes, props?: any) => {
    dispatch({ type: "CLOSE_MODAL", payload: type });
  };

  return { openModal, closeModal };
};

const handleGetModal = (modal: Modal) => {
  switch (modal.type) {
    case ModalTypes.ACCOUNT_ADD_MODAL:
      return <AccountAddModal key={modal.type} {...modal.props} />;
    case ModalTypes.ACCOUNT_EDIT_MODAL:
      return <AccountEditModal key={modal.type} {...modal.props} />;
    case ModalTypes.CUSTOMER_ADD_MODAL:
      return <CustomerAddModal key={modal.type} {...modal.props} />;
    case ModalTypes.CUSTOMER_EDIT_MODAL:
      return <CustomerEditModal key={modal.type} {...modal.props} />;
    case ModalTypes.USER_ADD_MODAL:
      return <UserAddModal key={modal.type} {...modal.props} />;
    case ModalTypes.USER_EDIT_MODAL:
      return <UserEditModal key={modal.type} {...modal.props} />;
    case ModalTypes.TRANSFER_USERS_MODAL:
      return <TransferUsersModal key={modal.type} {...modal.props} />;
    case ModalTypes.IMPORT_USERS_MODAL:
      return <ImportUsersModal key={modal.type} {...modal.props} />;
    case ModalTypes.EDIT_PRODUCT_MODAL:
      return <ProductEditModal key={modal.type} {...modal.props} />;
    case ModalTypes.EDIT_ACCOUNTPLAN_MODAL:
      return <AccountPlanEditModal key={modal.type} {...modal.props} />;
    case ModalTypes.ADD_PRODUCT_MODAL:
      return <ProductAddModal key={modal.type} {...modal.props} />;
    case ModalTypes.ADD_ACCOUNTPLAN_MODAL:
      return <AccountPlanAddModal key={modal.type} {...modal.props} />;
  }
};

type Props = {
  children: ReactNode;
};
// The provider
const ModalProvider = ({ children }: Props) => {
  const [modals, dispatch] = useReducer(modalReducer, []);

  return (
    <ModalContext.Provider value={dispatch}>
      {children} {modals.map((modal) => handleGetModal(modal))}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
