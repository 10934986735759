import styled from "styled-components/macro";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DialogWrapper = styled.div``;

const Button = styled.button`
  height: 2rem;
  width: 6.5rem;
  border: ${(props) => props.theme.borderSizeSystem[2]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.primaryColorsSystem[4]};
  color: ${(props) => props.theme.greyColorsSystem[4]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  margin: ${(props) => props.theme.spacingSystem[3]};

  &:hover {
    background: ${(props) => props.theme.primaryColorsSystem[5]};
  }
`;

const CancelButton = styled.button`
  height: 2rem;
  width: 6.5rem;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  background: ${(props) => props.theme.greyColorsSystem[4]};
  color: ${(props) => props.theme.primaryColorsSystem[5]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[2]};

  margin: ${(props) => props.theme.spacingSystem[3]};
  margin-right: ${(props) => props.theme.spacingSystem[1]};

  &:hover {
    background: ${(props) => props.theme.greyColorsSystem[3]};
  }
`;
type AlertProps = {
  showAlert: boolean;
  setShowAlert: (value: boolean) => void;
  handleExitFormUnsavedChanges: () => void;
};
const ModalChangesAlertDialog = ({
  showAlert,
  setShowAlert,
  handleExitFormUnsavedChanges,
}: AlertProps) => {
  const handleCloseAlertDialog = () => {
    setShowAlert(false);
  };

  return (
    <DialogWrapper>
      <Dialog open={showAlert}>
        <DialogTitle>Obs!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du har ulagrede endringer. Er du sikker på at du vil gå ut av
            skjema?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleCloseAlertDialog}>Avbryt</CancelButton>
          <Button onClick={handleExitFormUnsavedChanges}>Ok</Button>
        </DialogActions>
      </Dialog>
    </DialogWrapper>
  );
};
export default ModalChangesAlertDialog;
