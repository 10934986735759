import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/ApiSlice";
import { NewCustomer } from "../../../types";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";
import Loader from "../../misc/Loader";
import Toggle from "../../misc/toggles/Toggle";
import {
  AddustomerForm,
  ButtonWrapper,
  CheckboxLi,
  ErrorMessage,
  InfoText,
  Input,
  Label,
  Li,
  ListWrapper,
  SaveButton,
  UlInfo,
  Ul,
  CloseButton,
} from "../Form.styles";

enum CustomerProperties {
  NAME = "name",
  COMPANY_ID = "companyId",
  CUSTOMER_ID = "customerId",
  COMPANY_TYPE_NAME = "companyTypeName",
  COMPANY_TYPE_ID = "companyTypeId",
  INFO = "info",
  ISACTIVE = "isActive",
  EMAIL_DOMAINS = "emailDomains",
}
type Props = {
  setChanged: (value: boolean) => void;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
};

const CustomerAddNewForm = ({
  handleCloseModal,
  setChanged,
  handleCloseModalOnSuccess,
}: Props) => {
  // APIS
  const {
    data: companyTypes,
    isSuccess,
    isLoading,
  } = api.useGetAllCompanyTypesQuery();
  const [addCustomer, { isError }] = api.useAddCustomerMutation();

  const [errors, setErrors] = useState({}) as any;
  const [newCustomer, setNewCustomer] = useState<NewCustomer>({
    [CustomerProperties.NAME]: "",
    [CustomerProperties.COMPANY_ID]: "",
    [CustomerProperties.CUSTOMER_ID]: "",
    [CustomerProperties.COMPANY_TYPE_ID]: 0,
    [CustomerProperties.INFO]: "",
    [CustomerProperties.EMAIL_DOMAINS]: "",
    isActive: true,
    isDeleted: false,
    enableSSO: false,
  });

  const navigate = useNavigate();

  // OnSuccess creating new customer redirect to the customer
  const onSuccess = (id: string) => {
    navigate(`/customers/${id}`);

    handleCloseModalOnSuccess();
  };

  const validationErrors = {} as any;

  const validateForm = (customer: NewCustomer) => {
    if (!customer.name) {
      validationErrors.name = "Mangler navn!";
    } else if (customer.name.length < 3) {
      validationErrors.name = "Navn er for kort!";
    }

    if (!customer.companyId) {
      validationErrors.companyId = "Mangler organisasjonsnummer";
    } else if (!/^[0-9]+$/.test(customer.companyId)) {
      validationErrors.companyId = "Organisasjonsnummer kan kun inneholde tall";
    } else if (customer.companyId.length < 9 || customer.companyId.length > 9) {
      validationErrors.companyId = "Organisasjonsnummer er ikke riktig";
    }

    if (!customer.companyTypeId) {
      validationErrors.companyTypeId = "Du må velge en kundegruppe";
    }
    setErrors(validationErrors);
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(newCustomer);
    if (Object.keys(validationErrors).length === 0) {
      addCustomer({ newCustomer, onSuccess: onSuccess });
    }
  };

  const handleInputChange = (value: string | boolean, type: string) => {
    setNewCustomer({ ...newCustomer, [type]: value });
    setChanged(true);
  };

  const handleToggleSSO = () => {
    if (newCustomer.enableSSO) {
      setNewCustomer({ ...newCustomer, enableSSO: false });
    } else {
      setNewCustomer({ ...newCustomer, enableSSO: true });
    }
  };

  const handleSelectCompanyType = (id: string) => {
    setNewCustomer({
      ...newCustomer,
      companyTypeId: parseInt(id),
    });

    setChanged(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <AddustomerForm onSubmit={handleSubmit}>
          <ListWrapper>
            <Ul>
              <Li>
                <Label>*Navn</Label>
                <Input
                  value={newCustomer.name}
                  onChange={(e) =>
                    handleInputChange(e.target.value, CustomerProperties.NAME)
                  }
                ></Input>
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Li>
              <Li>
                <Label>*Organisasjonsnummer</Label>
                <Input
                  value={newCustomer.companyId}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.COMPANY_ID
                    )
                  }
                ></Input>
                {errors.companyId && (
                  <ErrorMessage>{errors.companyId}</ErrorMessage>
                )}
              </Li>
              <Li>
                <Label>Kunde Id (Power Office):</Label>
                <Input
                  value={newCustomer.customerId ? newCustomer.customerId : ""}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.CUSTOMER_ID
                    )
                  }
                ></Input>
              </Li>

              <Li>
                <Label>Kommentar</Label>
                <InfoText
                  value={newCustomer.info ? newCustomer.info : ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, CustomerProperties.INFO)
                  }
                ></InfoText>
              </Li>
            </Ul>
            <UlInfo>
              <Li>
                <Label>*Kundegruppe</Label>
                <DropDownSingleSelect
                  options={companyTypes}
                  placeholderText={"- Velg en kundegruppe -"}
                  handleSelected={handleSelectCompanyType}
                />
                {errors.companyTypeId && (
                  <ErrorMessage>{errors.companyTypeId}</ErrorMessage>
                )}
              </Li>
              <CheckboxLi>
                <Label>Aktiver SSO Pålogging?</Label>
                <Toggle
                  id={"1"}
                  onToggle={handleToggleSSO}
                  toggled={newCustomer.enableSSO}
                  text={newCustomer.enableSSO ? "Deaktiver" : "Aktiver"}
                  color={"#3E88C1"}
                ></Toggle>
              </CheckboxLi>
              <Li>
                <Label>E-post domene</Label>
                <InfoText
                  value={
                    newCustomer.emailDomains ? newCustomer.emailDomains : ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      CustomerProperties.EMAIL_DOMAINS
                    )
                  }
                ></InfoText>
              </Li>
            </UlInfo>
          </ListWrapper>
          <ButtonWrapper>
            <SaveButton type="submit">Lagre</SaveButton>
            <CloseButton type="button" onClick={handleCloseModal}>
              Lukk
            </CloseButton>
          </ButtonWrapper>
        </AddustomerForm>
      )}
    </>
  );
};
export default CustomerAddNewForm;
