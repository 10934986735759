import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import UserAddNewForm from "../forms/user/UserAddForm";
import { Customer } from "../../types";
import { useState } from "react";
import ModalChangesAlertDialog from "../dialogs/ModalChangesAlert";
import { ModalTypes } from "../../app/modalProvider/ModalProvider";
import {
  CloseButton,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
  modalDefaultStyle,
} from "./Modal.Styles";

// Modal.setAppElement("#root");

type UserAddProps = {
  customer: Customer;
  closeModal: (type: ModalTypes) => void;
};
const UserAddModal = ({ customer, closeModal }: UserAddProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  let accounts: any = [];
  customer.accounts.forEach((a) => {
    if (a.numUsers === a.numLicenses && a.useNumLicensesLimit) {
      let acc = {
        name: a.name,
        id: a.id,
        isnumLicensesReached: true,
        isActive: a.isActive,
      };
      accounts.push(acc);
    } else {
      let acc = {
        name: a.name,
        id: a.id,
        isnumLicensesReached: false,
        isActive: a.isActive,
      };
      accounts.push(acc);
    }
  });

  const handleCloseModal = () => {
    if (changed) {
      setShowAlert(true);
    } else {
      closeModal(ModalTypes.USER_ADD_MODAL);
    }
  };
  const handleCloseModalOnSuccess = () => {
    setChanged(false);
    closeModal(ModalTypes.USER_ADD_MODAL);
  };
  const handleExitFormUnsavedChanges = () => {
    closeModal(ModalTypes.USER_ADD_MODAL);
  };

  return (
    <>
      <Modal
        isOpen
        style={modalDefaultStyle}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <ModalContentWrapper>
          <HeaderWrapper>
            <Title>Legg til ny bruker</Title>
            <CloseButton onClick={handleCloseModal}></CloseButton>
          </HeaderWrapper>
          <UserAddNewForm
            customer={customer}
            setChanged={setChanged}
            handleCloseModal={handleCloseModal}
            handleCloseModalOnSuccess={handleCloseModalOnSuccess}
            accounts={accounts}
          />
        </ModalContentWrapper>
      </Modal>
      {showAlert && (
        <ModalChangesAlertDialog
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleExitFormUnsavedChanges={handleExitFormUnsavedChanges}
        />
      )}
    </>
  );
};
export default UserAddModal;
