import styled, { css } from "styled-components/macro";
import { columnConfig } from "../../types";

type Props = {
  columnConfig: columnConfig[];
  tableContent: any;
  handleSortColumn: (id: string) => void;
  columnSorted: string;
  isAscending: boolean | undefined;
  handleEdit: (id: string) => void;
};
const TableComponent = ({
  columnConfig,
  tableContent,
  handleSortColumn,
  columnSorted,
  isAscending,
  handleEdit,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <Thead>
          <Row isHeader={true}>
            {columnConfig.map((column) => (
              <Column
                width="250px"
                key={column.id}
                id={column.id}
                colIdSorted={columnSorted}
                isAscending={isAscending}
                onClick={() => handleSortColumn(column.id)}
              >
                {" "}
                {column.name}
              </Column>
            ))}
          </Row>
        </Thead>
        <Tbody>
          {tableContent.map((data: any) => (
            <Row
              onClick={() => handleEdit(data.id)}
              key={data.id}
              isHeader={false}
            >
              <TableDataCell isClickable={true} width={"120px"}>
                {data.name}
              </TableDataCell>
              {data.productTypeName && (
                <TableDataCell isClickable={true} width={"150px"}>
                  {data.productTypeName}
                </TableDataCell>
              )}
              <TableDataCell isClickable={true} width={"550px"}>
                {data.description}
              </TableDataCell>
              <TableDataCell isClickable={true} width={"200px"}>
                {data.languageName}
              </TableDataCell>
              <TableDataCell isClickable={true} width={"200px"}>
                {data.lastChange
                  ? new Date(data.lastChange).toLocaleDateString()
                  : ""}
              </TableDataCell>
              <TableDataCell isClickable={true} width={"200px"}>
                {data.lastChangeBy}
              </TableDataCell>
              <TableDataCell isClickable={true} width={"100px"}>
                {data.isActive ? "Ja" : "Nei"}
              </TableDataCell>
            </Row>
          ))}
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

export default TableComponent;

export const TableWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing[1]};

  border: 0.5px solid ${(props) => props.theme.greyScale[1]};
  border-radius: ${(props) => props.theme.spacing[1]};
  overflow: hidden;
  margin-bottom: 5rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  vertical-align: top;
  margin-bottom: ${(props) => props.theme.spacing[1]};
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const Column = styled.th<{
  width: string;
  colIdSorted: string;
  isAscending: boolean | undefined;
}>`
  width: width;
  padding-left: 1rem;
  font-size: ${(props) => props.theme.fontSizeSystem[1]};
  font-weight: ${(props) => props.theme.fontWeightSystem[3]};
  height: 30px;
  color: ${(props) => props.theme.greyColorsSystem[6]};

  ${(props) =>
    props.id === "option" ||
    props.id === "description" ||
    props.id === "isActive"
      ? css`
          &:hover {
            cursor: auto;
          }
        `
      : css`
          &:hover {
            cursor: pointer;
          }
        `}

  ${(props) =>
    props.id === "option" ||
    props.id === "description" ||
    props.id === "isActive"
      ? "none"
      : css`
          &:after {
            display: inline-block;
            content: url("svg/arrows_swap_vert.svg");
            position: absolute;
            margin-left: 0.2rem;
            transform: scale(0.8);
          }
        `}

    &:after {
    display: inline-block;
    content: url("svg/arrows_swap_vert.svg");
    position: absolute;
    margin-left: 0.2rem;
    transform: scale(0.8);
  }

  ${(props) =>
    props.colIdSorted === props.id &&
    props.isAscending &&
    css`
      &:after {
        display: inline-block;
        content: url("svg/arrow_upward.svg");
        position: absolute;
        margin-left: 0.2rem;
      }
    `}

  ${(props) =>
    props.colIdSorted === props.id &&
    !props.isAscending &&
    css`
      &:after {
        display: inline-block;
        content: url("svg/arrow_downward.svg");
        position: absolute;
        margin-left: 0.2rem;
      }
    `}
`;

export const TableDataCell = styled.td<{
  isClickable?: boolean;
  width: string;
}>`
  width: width;
  /* border-bottom: ${(props) => props.theme.borderSizeSystem[0]}; */
  border-top: ${(props) => props.theme.borderSizeSystem[0]};
  text-align: left;
  vertical-align: middle;
  padding-left: 1rem;
  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  font-weight: ${(props) => props.theme.fontWeightSystem[0]};
`;

export const Row = styled.tr<{
  isHeader: boolean;
  isSelected?: boolean;
}>`
  height: 2.5rem;

  background-color: ${(props) => (props.isHeader ? "#F0F0F0" : "none")};

  &:hover {
    background-color: ${(props) => (props.isHeader ? "none" : "#F0F0F0")};
    cursor: pointer;
  }

  :last-child {
    border-bottom: none;
  }
`;
