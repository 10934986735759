export type Theme = {
    
    primaryColors: string[],
    secondaryColors: string[],
    greyScale: string[];
    fontColors: string[];
    fontSize: string[];
    fontFamily: string[];
    borderRadius: string[]; 
    spacing: string[],
    iconSize: string[],

    //new
    fontSizeSystem: string[],
    fontWeightSystem: string[]
    spacingSystem: string[],
    shadowSystem: string[],
    primaryColorsSystem: string[],
    greyColorsSystem: string[],
    borderRadiusSystem: string[],
    borderSizeSystem: string[],
    colorsRGBA: string[],
    
};

// lightest => darkest on color schemes
export const theme: Theme ={
    primaryColors: [
        "#81C3D7",
        "#3E88C1",
        "#3A7CA5",
        "#2F6690",
        "#2b5e84", // Main 
        "#16425B", 
    ],
    secondaryColors: [
        "#f4a4a4", //secondary red
        "#FF725C", //main red
        "#ffffff ", // white
        "#D9DCD6", //darker white
    ],
    greyScale: [
        "#F0F0F0",
        "#E6E6E6",
        "#D6D6D6",
        "#CCCCCC",
        "#D9DCD6", //more hint of green/yellow 
        "#808080",
    ],
    fontColors: [
        "#ffffff ", // white
        "#D9DCD6",
        "#0A0A0A", 
        "#000000",
    ],
    fontSize: [
        "0.75rem", // 12px
        "0.875rem", //14px
        "1rem", // 16px
    ],
    fontFamily: [],
    borderRadius: [
        "0.3rem",
        "0.5rem", 
        "1rem",
        "50%",
        "100%",
    ],
    spacing: [
        "0.3rem",
        "0.5rem",
        "1rem",
        "1.5rem",
        "2rem",
    ],
    iconSize: [],

    // new
    fontSizeSystem: [
        "0.75rem",
        "0.8125rem",
        "0.875rem",
        "1rem",
        "1.125rem",
        "1.25rem",
        "1.5rem", //5
        "1.875rem",
        "2.25rem",
        "3rem",
        "3.75rem",
        "4.5rem",
    ],
    fontWeightSystem: ["400", "500", "600", "700"],

    spacingSystem: [
        "0rem",
        "0.25rem",
        "0.5rem",
        "0.75rem",
        "1rem",
        "1.5rem", // 5
        "2rem",
        "3rem",
        "4rem",
        "6rem",
        "8rem", // 10
        "12rem",
        "16rem",
        "24rem",
        "32rem",
        "40rem", // 15
        "48rem",
    ],
    shadowSystem: [
        "0 1px 2px 0 hsla(0, 0%, 0%, 0.08)",
        "0 1px 3px hsla(0, 0%, 0%, 0.2)",
        "0 4px 6px hsla(0, 0%, 0%, 0.2)",
        "0 5px 15px hsla(0, 0%, 0%, 0.2)",
        "0 10px 24px hsla(0, 0%, 0%, 0.2)",
        "0 15px 35px hsla(0, 0%, 0%, 0.2)", // 5
    ],
    borderRadiusSystem: [
        "0.5rem",
        "0.75rem",
        "1rem",
    ],
    borderSizeSystem: [
        "1px solid #E6E6E6 ",
        "1px solid #D6D6D6 ",
        "1px solid #3A7CA5 ",
        "1px solid #2B5E84 ",
        
    ],
    primaryColorsSystem: [
       "#16425B",
       "#2B5E84",
       "#3A7CA5",
       "#81C3D7", //3
       "#3E88C1",
       "#3778A9", //5
       "#202020", // 6 black
       "#C22F2F", //Red 
    ],
    greyColorsSystem: [
        "#333333",
        "#808080",
        "#D6D6D6",
        "#E6E6E6",
        "#F0F0F0", //white ish
        "#FFFFFF", // 5 white
    ],
    colorsRGBA: [
        "rgba(122, 122,122, 0.09)", //super light grey
        "rgba(122, 122,122, 0.1)", // light Grey
        "rgba(122, 122,122, 0.2)", // Grey
        "rgba(251, 35, 46, 0.9)", // red
    ]
}