import { useEffect, useRef, useState } from "react";
import { CustomerSearchResult } from "../../../types";
import { useDebounce } from "../../hooks/UseDebounce";
import useOnClickOutside from "../../hooks/UseOnClickOutside";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components/macro";
import SearchResults from "./textFieldToSearchResults";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  trigger: (q: string, value: boolean) => void;
  searchResults: any;
  setSelectedCustomer: (value: CustomerSearchResult) => void;
  defaultValueInput?: string;
  handleClickEditCancel: (e: React.MouseEvent) => void;
  editIsClicked: boolean;
  value: string;
};
const TextFieldToSearch = ({
  trigger,
  searchResults,
  setSelectedCustomer,
  defaultValueInput,
  handleClickEditCancel,

  editIsClicked,
  value,
}: Props) => {
  const [searchInputString, setSearchInputString] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const debouncedSearch = useDebounce(searchInputString);

  useEffect(() => {
    const handleSearch = () => {
      trigger(debouncedSearch, true);
      setIsOpen(true);
    };

    if (debouncedSearch.length) {
      handleSearch();
    }
  }, [debouncedSearch, trigger]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputString(e.target.value);
  };

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
    if (!componentRef.current || !e.relatedTarget) {
      return;
    }
    if (!componentRef.current?.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleOnEnterPress = (customer: CustomerSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");

      setSelectedCustomer(customer);
    }
  };

  const handleOnClick = (customer: CustomerSearchResult) => {
    if (isOpen) {
      setIsOpen(false);
      setSearchInputString("");
      setSelectedCustomer(customer);
    }
  };

  // If tabbed outside of result component, the input params are not cleaned up, and results will display back when
  // Input field is back in focus
  const handleInputOnFocus: React.FocusEventHandler<HTMLDivElement> = (e) => {
    // If searchInputString is true, searchresults data should still be available.
    // Display data again, (in case user tabbed out of result view)
    if (searchInputString) {
      setIsOpen(true);
    }
  };

  function cleanUpCloseSearch() {
    setIsOpen(false);
    setSearchInputString("");
  }

  // Click outside search component closes the result list from search:
  // Performs cleanup
  useOnClickOutside(componentRef, () => cleanUpCloseSearch());

  return (
    <>
      {editIsClicked ? (
        <SearchBoxContainer ref={componentRef} onBlur={handleBlur} tabIndex={0}>
          <SearchInputWrapper>
            <SearchButton onClick={handleClickSearch}>
              <SearchIcon style={IconStyles} />
            </SearchButton>
            <SearchInput
              value={searchInputString}
              onChange={handleChangeSearchInput}
              onFocus={handleInputOnFocus}
              placeholder={defaultValueInput}
            ></SearchInput>
            <CancelButton onClick={handleClickEditCancel}>
              <CloseIcon style={IconStyles} />
            </CancelButton>
          </SearchInputWrapper>

          {isOpen && searchResults.isSuccess && !searchResults.isFetching && (
            <SearchResults
              results={searchResults.data}
              handleOnEnterPress={handleOnEnterPress}
              handleOnClick={handleOnClick}
            />
          )}
        </SearchBoxContainer>
      ) : (
        <WrapperEditableTextField>
          <EditableTextField>
            <P>{value}</P>
          </EditableTextField>
          <EditButton onClick={handleClickEditCancel}>
            <EditIcon style={IconStyles} />
          </EditButton>
        </WrapperEditableTextField>
      )}
    </>
  );
};
const IconStyles = {
  fontSize: "large",
  color: "grey",
};

export default TextFieldToSearch;

export const SearchBoxContainer = styled.div`
  position: relative;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  height: 3rem;
  border-radius: 0.5rem;
  border: 0.5px solid ${(props) => props.theme.greyScale[3]};
`;

export const SearchInput = styled.input`
  height: 2.5rem;
  flex: 1;
  border: none;
  border-radius: 0.5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1rem;
  font-size: ${(props) => props.theme.fontSizeSystem[2]};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SearchButtonWrapper = styled.span`
  padding: 0.1rem;
  margin-right: ${(props) => props.theme.spacing[1]};

  &:hover {
    background-color: ${(props) => props.theme.greyScale[1]}; // #d9dcd6;
    border-radius: ${(props) => props.theme.borderRadius[4]};
    cursor: pointer;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  border-right: 0.5px solid ${(props) => props.theme.greyScale[1]};
  background: none;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
`;

export const WrapperEditableTextField = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  height: 3rem;
  border: ${(props) => props.theme.borderSizeSystem[0]};
  border-radius: ${(props) => props.theme.borderRadiusSystem[0]};

  padding: ${(props) => props.theme.spacingSystem[2]};

  font-size: ${(props) => props.theme.fontSizeSystem[2]};
  color: ${(props) => props.theme.primaryColorsSystem[6]};
  font-weight: ${(props) => props.theme.fontWeightSystem[1]};
`;

export const EditableTextField = styled.div`
  flex: 4;
`;

export const EditButton = styled.button`
  background: 0;
  border: 0;
  border-left: 0.5px solid #d6d6d6;
`;
export const P = styled.p`
  padding: ${(props) => props.theme.spacingSystem[1]};
  margin: 0;
`;
