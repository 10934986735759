import { Account, EditAccount } from "../../../types";
import DropDownSingleSelect from "../../misc/dropdown/DropDownSingleSelect";

import {
  EditAccountForm,
  ListWrapper,
  Li,
  Input,
  Label,
  Ul,
  InfoText,
  ButtonWrapper,
  SaveButton,
  CloseButton,
  DeactivateButton,
  NoEditInputField,
  DateInput,
  CheckboxLi,
  FooterWrapper,
  LogDetailsWrapper,
  LogDetails,
  UlInfo,
  LogLabel,
  P,
} from "../Form.styles";
import { useEffect, useState } from "react";
import Toggle from "../../misc/toggles/Toggle";
import { api } from "../../../api/ApiSlice";
import DropDownMultiSelect from "../../misc/dropdown/DropDownMultiSelect";

interface AccountFormProps {
  customerId: string;
  account: Account;
  handleCloseModal: () => void;
  handleCloseModalOnSuccess: () => void;
  handleChangeToForm: (value: boolean) => void;
}

enum AccountProperties {
  Id = "id",
  Name = "name",
  IsActive = "isActive",
  AccountPlanId = "accountPlanId",
  CustomerId = "customerId",
  LanguageId = "languageId",
  Info = "info",
  LicenseStart = "licenseStart",
  LicenseEnd = "licenseEnd",
  numLicenses = "numLicenses",
  UseNumLicensesLimit = "useNumLicensesLimit",
  Timestamp = "timestamp",
  ProductChanges = "productChanges",
  AdditionalProductIds = "additionalProductIds",
}

enum ACCOUNT_STATUS {
  activate = "ACTIVATE",
  deactivate = "DEACTIVATE",
}
const AccountEditForm = ({
  customerId,
  account,
  handleCloseModal,
  handleCloseModalOnSuccess,
  handleChangeToForm,
}: AccountFormProps) => {
  const [updateAccountStatus] = api.useUpdateAccountStatusMutation();
  const [updateAccount] = api.useEditAccountMutation();

  const [accountPlanChanged, setAccountPlanChanged] = useState<boolean>(false);

  const [errors, setErrors] = useState({}) as any;
  // Get the initial productData for provided account
  const productsDataInitial = api.useGetProductsForAccountEditQuery({
    accountPlanId: account.accountPlanId,
    accountId: account.id,
    customerId: customerId,
  });

  const initialIdsForProduct =
    productsDataInitial.data?.selectedAdditionalProducts.map((prod) => prod.id);

  const [editAccount, setEditAccount] = useState<EditAccount>({
    [AccountProperties.Id]: account.id,
    [AccountProperties.Name]: account.name,
    [AccountProperties.IsActive]: account.isActive,
    [AccountProperties.CustomerId]: account.customerId,
    [AccountProperties.LanguageId]: account.languageId,
    [AccountProperties.Info]: account.info,
    [AccountProperties.LicenseStart]: account.licenseStart,
    [AccountProperties.LicenseEnd]: account.licenseEnd,
    [AccountProperties.numLicenses]: account.numLicenses,
    [AccountProperties.UseNumLicensesLimit]: account.useNumLicensesLimit,
    [AccountProperties.Timestamp]: account.timestamp,
    [AccountProperties.AccountPlanId]: account.accountPlanId,
    [AccountProperties.ProductChanges]: false,
    [AccountProperties.AdditionalProductIds]: initialIdsForProduct
      ? initialIdsForProduct
      : [],
  });

  //
  const [trigger, resultProducts, lastPromiseInfo] =
    api.useLazyGetProductsNotInAccountPlanQuery();

  useEffect(() => {
    if (accountPlanChanged === true && editAccount.accountPlanId) {
      trigger({
        customerId: editAccount.customerId,
        accountPlanId: editAccount.accountPlanId,
      });
    }
  }, [
    accountPlanChanged,
    editAccount.accountPlanId,
    editAccount.customerId,
    trigger,
  ]);

  const handleInputChange = (value: string | boolean, type: string) => {
    setEditAccount({ ...editAccount, [type]: value });
    handleChangeToForm(true);
  };

  console.log(resultProducts.data);
  const handleProductSelection = (id: string[]) => {
    if (
      initialIdsForProduct?.every((i) => id.includes(i)) &&
      initialIdsForProduct.length === id.length
    ) {
      setEditAccount({
        ...editAccount,
        [AccountProperties.AdditionalProductIds]: id,
        [AccountProperties.ProductChanges]: false,
      });
    } else {
      setEditAccount({
        ...editAccount,
        [AccountProperties.AdditionalProductIds]: id,
        [AccountProperties.ProductChanges]: true,
      });
    }

    // MEN hvordan skal jeg sette productChanges tilbake til false, dersom man går tilbake til opprinnelig?
    // gjøre en sjekk mellom productsdataInitial,additionalProducts og ID array ?
  };

  const handleAccountPlanSelected = (accountPlanId: string, name?: string) => {
    if (accountPlanId === account.accountPlanId) {
      setAccountPlanChanged(false);
    } else {
      if (name) {
        setEditAccount({
          ...editAccount,
          [AccountProperties.AccountPlanId]: accountPlanId,
          [AccountProperties.Name]: name,
        });
        handleChangeToForm(true);
        setAccountPlanChanged(true);
      } else {
        setEditAccount({
          ...editAccount,
          [AccountProperties.AccountPlanId]: accountPlanId,
        });
        handleChangeToForm(true);
        setAccountPlanChanged(true);
      }
    }
  };

  const handleLanguageSelected = (id: string) => {
    setEditAccount({
      ...editAccount,
      [AccountProperties.LanguageId]: id,
    });
    handleChangeToForm(true);
  };

  const handleToggleLimitLicenses = () => {
    if (editAccount.useNumLicensesLimit) {
      setEditAccount({
        ...editAccount,
        [AccountProperties.UseNumLicensesLimit]: false,
      });
      handleChangeToForm(true);
    } else {
      setEditAccount({
        ...editAccount,
        [AccountProperties.UseNumLicensesLimit]: true,
      });
      handleChangeToForm(true);
    }
  };

  const handleClickChangeActiveStatus = (e: React.MouseEvent) => {
    e.preventDefault();

    if (account.isActive) {
      // Update the local state
      setEditAccount({
        ...editAccount,
        [AccountProperties.IsActive]: false,
      });

      updateAccountStatus({
        id: editAccount.id,
        value: ACCOUNT_STATUS.deactivate,
        customerId: customerId,
      });
    } else {
      // Update the local state
      setEditAccount({
        ...editAccount,
        [AccountProperties.IsActive]: true,
      });

      updateAccountStatus({
        id: editAccount.id,
        value: ACCOUNT_STATUS.activate,
        customerId: customerId,
      });
    }
  };

  const onSuccess = () => {
    handleCloseModalOnSuccess();
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm(editAccount);

    if (Object.keys(validationErrors).length === 0) {
      updateAccount({ editAccount, onSuccess });
    }
  };

  const validationErrors = {} as any;

  const validateForm = (account: EditAccount) => {
    if (!account.name) {
      validationErrors.name = "Mangler navn!";
    }
    if (!account.accountPlanId) {
      validationErrors.accountPlanId = "Du må velge en kontoplan";
    }
    if (!account.languageId) {
      validationErrors.languageId = "Du må velge språk";
    }

    setErrors(validationErrors);
  };

  return (
    <EditAccountForm onSubmit={handleSubmit}>
      <ListWrapper>
        <Ul>
          <Li>
            <Label>Kontonavn</Label>
            <NoEditInputField>
              <P>{editAccount.name}</P>
            </NoEditInputField>
          </Li>
          <Li>
            <Label>Produktpakke</Label>
            <DropDownSingleSelect
              options={account.accountPlans}
              defaultOptions={account.accountPlanId}
              handleSelected={handleAccountPlanSelected}
            />
          </Li>
          {productsDataInitial.isSuccess && !accountPlanChanged && (
            <Li>
              <Label>Tilleggsprodukter</Label>
              <DropDownMultiSelect
                options={productsDataInitial.data.productsNotInAccountPlan}
                defaultOptions={
                  productsDataInitial.data.selectedAdditionalProducts
                }
                handleSelected={handleProductSelection}
                placeholder={"- Legg til ekstra produkter -"}
              />
            </Li>
          )}
          {resultProducts.isSuccess && accountPlanChanged && (
            <Li>
              <Label>Tilleggsprodukter</Label>
              <DropDownMultiSelect
                options={resultProducts.data}
                handleSelected={handleProductSelection}
                placeholder={"- Legg til ekstra produkter -"}
              />
            </Li>
          )}

          <Li>
            <Label>Kommentar</Label>
            <InfoText
              value={editAccount.info}
              onChange={(e) =>
                handleInputChange(e.target.value, AccountProperties.Info)
              }
            ></InfoText>
          </Li>
        </Ul>
        <UlInfo>
          <Li>
            <Label>Språk</Label>
            <DropDownSingleSelect
              options={account.languages}
              defaultOptions={account.languageId}
              handleSelected={handleLanguageSelected}
            ></DropDownSingleSelect>
          </Li>

          <Li>
            <Label>Lisens start</Label>
            <DateInput
              type="date"
              value={editAccount.licenseStart?.split("T")[0]}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  AccountProperties.LicenseStart
                )
              }
            ></DateInput>
          </Li>
          <Li>
            <Label>Lisens slutt</Label>
            <DateInput
              type="date"
              value={editAccount.licenseEnd?.split("T")[0]}
              onChange={(e) =>
                handleInputChange(e.target.value, AccountProperties.LicenseEnd)
              }
            ></DateInput>
          </Li>
          <Li>
            <Label>Antall lisenser</Label>
            <Input
              value={editAccount.numLicenses ? editAccount.numLicenses : ""}
              onChange={(e) =>
                handleInputChange(e.target.value, AccountProperties.numLicenses)
              }
            ></Input>
          </Li>
          <CheckboxLi>
            <Label>Begrens antall lisenser?</Label>
            <Toggle
              id={"1"}
              onToggle={handleToggleLimitLicenses}
              toggled={editAccount.useNumLicensesLimit}
              text={editAccount.useNumLicensesLimit ? "Deaktiver" : "Aktiver"}
              color={"#3E88C1"}
            ></Toggle>
          </CheckboxLi>
        </UlInfo>
      </ListWrapper>

      <FooterWrapper>
        <LogDetailsWrapper>
          <LogLabel>Sist Endret av</LogLabel>
          <LogDetails> {account.lastChangeBy}</LogDetails>
          <LogLabel>Sist Endret</LogLabel>
          <LogDetails>{account.lastChange}</LogDetails>
        </LogDetailsWrapper>
        <ButtonWrapper>
          <DeactivateButton
            isActive={account.isActive}
            onClick={(e) => handleClickChangeActiveStatus(e)}
          >
            {account.isActive ? "Deaktiver" : "Aktiver"}
          </DeactivateButton>

          <CloseButton type="button" onClick={handleCloseModal}>
            Lukk
          </CloseButton>
          <SaveButton type="submit">Lagre</SaveButton>
        </ButtonWrapper>
      </FooterWrapper>
    </EditAccountForm>
  );
};

export default AccountEditForm;
